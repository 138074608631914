import {
    Avatar,
    Box,
    Chip,
    Divider,
    Grid,
    Skeleton,
    Stack,
    Typography,
    useTheme,
  } from '@mui/material';
  import {
    MatchCardContainer,
  } from '../../vendor/mui/styled-components/OpenMatchesListing';
  import {useTranslate} from '@tolgee/react';
  import IconLevel from '../../assets/icons/IconLevel.js';
  import {getLocalizedNum} from '../../utils/localization';
  import useLocale from '../../hooks/localization/useLocale';
  import {
    PlayerListContainer,
  } from '../../vendor/mui/styled-components/OpenMatches';
  import {useEffect, useState} from 'react';
  import {
    createArray,
    genderIcons,
    getGendersLabel,
    getLoggedInPlayerRating,
    getMatchTypeTranslation,
    preFilledPlayersArray,
  } from '../../utils/openMatches';
  import {AvatarIcon} from './PlayerCard';
  import useIsMobile from '../../hooks/ui/useIsMobile';
  import {useNavigate} from 'react-router-dom';
  import moment from 'moment-timezone';
  import useUser from '../../hooks/access/useUser';
  import {Circle, EventAvailable, EventAvailableOutlined, SportsTennis} from '@mui/icons-material';
  import useDefaults from '../../hooks/defaults/useDefaults.js';
import { formatTime } from '../../utils/ui.js';

  const OpenMatchLandingPageCard = (props) => {
    const {
      match,
      clubId,
      lastRef,
      customTypographyVariant,
      isLandingPageSection = false,
      cardBodyStyles = {},
      alwaysMobileView,
    } = props;
  


    const isMobile = useIsMobile();
    const {currencySymbol} = useDefaults();
    const {t} = useTranslate();
    const {locale} = useLocale();
    const theme = useTheme();
    const navigateTo = useNavigate();
    const USER = useUser();
  
    const matchId = match?.shortUuid;
  
    const level = `${match?.minRating}-${match?.maxRating}`;
    const pricePerSlot = match?.pricePerSlot ?? 0;
    const participants = match?.participants ?? [];
    const courtSize = match?.courtSize;
    const teamSize = courtSize == 'Single' ? 1 : 2;
    const matchGender = match?.gender;
    const matchType = match?.type;
    const sport = match?.sport;
  
    const [players, setPlayers] = useState([]);
    const [playersLoading, setPlayersLoading] = useState(false);
  
    const price = `${currencySymbol} ${getLocalizedNum(locale,
        +pricePerSlot)}`;
  
    const setValue = (key, value) => setPlayers(value);
  
    const createArraySuccessFn = (players) => {
      preFilledPlayersArray({
        players,
        sport,
        participants,
        loggedInUser: USER?.userObj,
        setValue,
      });
      setPlayersLoading(false);
    };
  
    useEffect(() => {
      if (players?.length == 0) {
        setPlayersLoading(true);
        createArray({
          setValue,
          length: teamSize * 2,
          successFn: createArraySuccessFn,
        });
      }
    }, [participants]);
  
    const ValueContainer  = ({value , isFirst})  => {
        return (
            <Stack direction={'row'} alignItems={'center'} columnGap={1} mt={0.8}>
               { !isFirst && <Circle  color={'disabled'} sx={{fontSize:'6px',ml:'2px'}}/> }
                <Typography
                variant={'body2'}
                color={'rgba(0, 0, 0, 0.55)'}
                
                textTransform={'capitalize'}
                >
                    {value}
                </Typography>
            </Stack>
        )
    }
    return (
        <>
        <Stack
            sx={{
                cursor:'pointer',
                height : isMobile?'auto' : '115px',
                marginBottom:'1rem',
                background:theme.palette.grey[50],
                borderRadius:'12px',
                border:`1px solid ${theme.palette.divider}`,
                
            }}
            ref={lastRef}
            isMobile={isMobile}
            onClick={() =>navigateTo(`/club/${clubId}/openMatches/detail?id=${matchId}`)
                
            }
        >
        <Stack direction={isMobile ? 'column' :'row'} justifyContent={'space-between'} pt={2} pl={isMobile? 1 :2} pr={isMobile ?1:2} pb={2}>
            <Box mt={0.8} sx={{width:isMobile?'100%':'380px'}}>
                <Box sx={{display:'flex',gap:1 , alignItems:'center'}}>
                 <EventAvailableOutlined />   
                {
                      isLandingPageSection &&
                      <Typography variant={'inherit'} fontSize={'inheri'}>
                        {`${moment(match?.date).format('ddd, MMM DD')}`}
                      </Typography>
                }
                <Circle  color={'text.primary'} sx={{fontSize:'6px'}}/>
                  <Typography variant={'inherit'} fontSize={'inherit'}>
                    {`${formatTime(match.startTime)} - ${formatTime(match.endTime)}`}
                  </Typography>

                </Box>
                <Box sx={{display:'flex', gap:isMobile? 0.5 :1 , mt:isMobile ? 0.5 :2.2 , pl:'3px'}}>
                    
                      <ValueContainer value={sport} isFirst={true}/>
                      <ValueContainer value={level}  />
                      <ValueContainer value={getGendersLabel(t, courtSize, matchGender)} />
                      <ValueContainer value={getMatchTypeTranslation(t,matchType)} />

                </Box>

            </Box>
            <Box>
           <Divider orientation={isMobile ? 'horizontal': "vertical"}  sx={{mt:isMobile? 2:-1.1 ,mb:isMobile? 2: '', height: isMobile? 0 : '101px'}}/>
           </Box>
            <Box sx={{display:'flex' , flexDirection:isMobile ? 'row-reverse' : 'column', alignItems:'center' , justifyContent:isMobile ?'space-between' : '' , width:isMobile? '100%' :'170px'}}>
                <Box>
                {!match?.userPartOfMatch && 
                        <Chip
                            size={'medium'}
                            color={'success'}
                            sx={{
                              borderRadius: 1,
                              fontSize: '0.875rem',
                              fontWeight: 'bold',
                              minHeight: '32px',
                            }}
                            variant={'contained'}
                            label={price}
                        />
                 }       
                         
                </Box>
                <Box>

                        
              <Stack
  
                  sx={{
                    paddingTop:isMobile ?'0px':'18px',
                    mt: !match?.userPartOfMatch ?  '' : 1,
                    
                  }}
              >
                {
                    players?.length &&
                    <MobileViewPlayers
                        players={players}
                        isMobile={isMobile}
                        loading={playersLoading}
                        teamSize={'small'}
                        USER={USER?.userObj}
                    />
                }
              </Stack>


                </Box>
            
            </Box>
        </Stack>
        
       
        
  
        </Stack>
        
            {/* {
                isMobile &&
                <>
                hello
                </>
            } */}
        </>
    );  
  };
  
  function MobileViewPlayers({players, isMobile, loading, teamSize, USER}) {
    const {t} = useTranslate();
    const isSlotDisable = players?.find(x => x?.email == USER?.email);
  
    return (
        <Stack direction={'row'}
               justifyContent={players?.length > 2 ? 'space-between' : 'center'}
               spacing={players?.length > 2 ? 0 : 2}
               alignItems={'flex-start'}>
          <MobileViewTeamRender t={t} list={players} team={1} loading={loading}
                                teamSize={teamSize} isMobile={isMobile}
                                isSlotDisable={isSlotDisable}/>
          {/* <Box sx={{height:'10px'}}/> */}
          <Box>
          <Divider orientation='vertical' sx={{height:'32px' , mr:'10px' , ml:'10px'}}/>  
          </Box>
          <MobileViewTeamRender t={t} list={players} team={2} loading={loading}
                                teamSize={teamSize} isMobile={isMobile}
                                isSlotDisable={isSlotDisable}/>
  
        </Stack>
    );
  }
  
  const MobileViewTeamRender = ({
                                  t,
                                  list,
                                  team,
                                  loading,
                                  teamSize,
                                  isMobile,
                                  isSlotDisable,
                                }) => {
  
    const players = list?.filter((x) => x.team == team);
    const theme = useTheme()                                
    const btnText = isMobile ?
        t('slot.available') :
        t('openMatches.page.player.slotAvailable');
  
    return (
        <Stack direction={'row'}
               spacing={1.5} alignItems={'flex-start'}
               sx={{
                '& .addedPlayer .MuiAvatar-root ' : {
                 height:'32px',
                 width:'32px',   
                 fontSize:'14px',
                
                
                },
                '& .profileImage': {
                height:'30px',
                width:'30px', 
                  
                

                },                
                '& .MuiButtonBase-root': {
                height:'30px',
                width:'30px',   
                borderWidth: `2px`,
                
                'svg':{
                    fontSize:'medium'
                }

                },
                '& .nameLevel' : {
                display:'none',
                },
                '& .addPlayerBtnText' : {
                    display:'none',
                }
               }}
               justifyContent={'space-between'}
        >
          {players?.map((player, index) => {
  
            return (
                <>
                  {loading ? (
                      <Skeleton width={'100%'} height={'100%'} sx={{mr: 'auto'}}/>
                  ) : (
                      <AvatarIcon
                          t={t}
                          isCreate={false}
                          player={player}
                          canAddPartner={true}
                          btnText={btnText}
                          isDisabled={isSlotDisable}
                          teamSize={teamSize}
                          isMobile={isMobile}
                      />
                  )}
                </>
            );
          })}
        </Stack>
    );
  };
  
  export default OpenMatchLandingPageCard;
  