import {styled, ToggleButton, ToggleButtonGroup} from '@mui/material';

export const ToggleButtonGroupRounded = styled(ToggleButtonGroup)(
    ({theme, selectedColor}) => ({
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '100px !important',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
    }));

export const ToggleButtonRounded = styled(ToggleButton)(
    ({color,height,theme}) => {
      return {
        textTransform: 'capitalize',
        fontWeight: 500,
        borderRadius: '100px !important',
        border: 'none',
        height: height,
        display: 'flex',
        alignItems: 'center',
        columnGap: '8px',
        '&.Mui-selected, &.Mui-selected:hover': {
          color: 'white',
          backgroundColor: theme.palette[color].main,
        },
      }
    },
);
