import React from 'react';
import {LocationOn} from '@mui/icons-material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {getLocalizedNum} from '../../utils/localization';
import useLocale from '../../hooks/localization/useLocale';
// import TrainingTimeSection from "./TrainingTimeSection";
import {useNavigate} from 'react-router-dom';
import IconLocation from '../../assets/icons/IconLocation';
import dayjs from 'dayjs';
import IconClock from '../../assets/icons/IconClock';
import useIsMobile from '../../hooks/ui/useIsMobile';
import IconCalendarCheck from '../../assets/icons/IconCalendarCheck';
import useDefaults from '../../hooks/defaults/useDefaults';
import { formatTime } from '../../utils/ui';

const EventListingCard = ({
                            experience,
                            event,
                            type = null,
                            isSingleItem = true,
                            isBackground=false
                          }) => {

  const theme = useTheme();
  const {t} = useTranslate();
  const {locale} = useLocale();
  const navigateTo = useNavigate();
  const {currencySymbol} = useDefaults()
  const title = event?.name;
  const subTitle = event?.clubName;
  const price = event?.price;
  const slotsLeft = event?.maxParticipants - event?.participantCount;
  const isAvailable = type ?
      true :
      event?.maxParticipants > event?.participantCount;

  const isMobile = useIsMobile();

  function handleClickEventItem() {
    const queryParams = new URLSearchParams({
      eventId: event?.id,
      type: 'active',
      name: event.name,
    }).toString();

    navigateTo(`/club/${event?.clubId}/events/event-detail?${queryParams}`);

  }

  return (
      <Card
      variant="outlined"
      sx={{borderRadius: 2, cursor: 'pointer'}}
      onClick={handleClickEventItem}
      >
        <Stack
            direction="row"
            justifyContent="space-between"
            className={isBackground ? "bg-gradiant-color": ''}
            sx={{
               background: theme.palette.grey[200],
              
              
              padding: '20px 20px 12px 20px',
            }}
        >
          {/* Left Section */}
          <Box>
            <Typography
                variant="h6-bold"
                fontSize={{
                  xs: '18px',
                  md: '20px',
                }}
            >
              {title}
            </Typography>

            {
              experience === 'COMPANY' ?
                  <Stack direction="row" spacing={1} alignItems={"center"} mt={1}>
                    {/* <IconUser /> */}
                    <LocationOn color={isBackground ? "white" : 'disabled'}/>
                    <Typography variant="body2"
                                textTransform={'capitalize'}>{event?.clubName}</Typography>
                  </Stack> :
                  <Stack direction="row" spacing={0.5} alignItems="center"
                         mt={1}>
                    {/* <IconUser /> */}
                    {/* <SportsTennisRounded color="disabled"/> */}
                    <LocationOn color={isBackground ? "white" : 'disabled'}/>
                    <Typography variant="body2" sx={{color:isBackground ?'white' : 'disabled'}}
                                textTransform={'capitalize'}>{subTitle}</Typography>
                  </Stack>
            }
          </Box>

          {/* Right Section */}
          <Stack spacing={1} alignItems={isMobile?'flex-end' :"center"}>
            <Chip
                size="small"
                variant="contained"
                color={isAvailable ? 'success' : 'error'}
                sx={{
                  borderRadius: 1,
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  minHeight: '32px',
                }}
                label={isAvailable ?
                    `${currencySymbol} ${getLocalizedNum(locale,
                        price)}` :
                    t('eventDetail.soldButton')}
            />
            {
                isAvailable &&
                <Typography variant="body1" whiteSpace={'nowrap'}>
                  {`${slotsLeft} ${t('event.slotLeftOnly')}`}
                </Typography>
            }
          </Stack>
        </Stack>
        <CardContent>
          <Stack
              direction={'row'}
              my={3}
              columnGap={3}
              rowGap={2}
              flexWrap={'wrap'}
              sx={{justifyContent:'space-between'}}
          >
            {/*Date*/}
            <Stack direction="row" spacing={1} alignItems="flex-start" >
              <IconCalendarCheck/>
              <Stack direction="row" spacing={1}>
                <Typography fontSize={isMobile?'14px':'16px'}>
                <span>
                  {dayjs(event?.startDate).format('ddd, DD MMM YY')}
                </span>
                  <span>{` - `}</span>
                  <span>
                   {dayjs(event?.endDate).format('ddd, DD MMM YY')}
                </span>
                </Typography>
              </Stack>
            </Stack>

            {/*Time*/}
            <Stack
                direction="row"
                spacing={1}
                alignItems="flex-start"
                minWidth={'174px'}
            >
              <IconClock/>
              <Typography fontSize={isMobile?'14px':'16px'}>
                <span>
                  {`${formatTime(event?.startTime)}`}
                </span>
                <span>{` - `}</span>
                <span>
                   {`${formatTime(event?.endTime)}`}
                </span>
              </Typography>

            </Stack>
          </Stack>

        </CardContent>
      </Card>
  );
};

export default EventListingCard;
