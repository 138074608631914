import { Help } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useState } from "react";
import OpenMatchUserGuideDialog from "./OpenMatchUserGuideDialog";


function OpenMatchUserGuide({btnColor='primary'}) {

  const {t} = useTranslate();

  const [showRatingGuide, setShowRatingGuide] = useState(false);

  return (
      <>
        <Button
            size={'small'}
            startIcon={<Help/>}
            sx={{
              px: 1.5,
              position: 'relative',
              top: 2,
              "& .MuiButton-startIcon": {
                marginRight: "4px",
              },
            }}
            color={btnColor}
            onClick={()=>{
              setShowRatingGuide(true)
            }}
        >
          {t('openMatch.ratingGuide.button')}
        </Button>
        <OpenMatchUserGuideDialog
            open={showRatingGuide}
            setOpen={setShowRatingGuide}
        />
      </>

  );
}

export default OpenMatchUserGuide;
