import {Controller, useFormContext} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {validate} from 'email-validator';
import {TextField} from '@mui/material';

function Email({disabled}) {
  const {control, formState: {errors}} = useFormContext();
  const {t} = useTranslate();

  return (
      <Controller
          name={'email'}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('register.fields.email.error'),
            },
            validate: (value) => {
              return validate(value) ||
                  t('register.fields.email.error.invalid');
            },
          }}
          render={({field}) => {
            return <TextField
                disabled={disabled}
                fullWidth
                required
                inputProps={{maxLength: 50, min: 0}}
                label={t('register.fields.email.label')}
                type="text"
                placeholder={t('register.fields.email.placeholder')}
                size="medium"
                error={!!errors['email']}
                helperText={!!errors['email'] && errors['email'].message}
                {...field}
            />;
          }}
      />
  );
}

export default Email;
