import {Box, Stack, Typography, useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import useIsMobile from '../../../hooks/ui/useIsMobile';

function AddBookingCardTitle({
                               title,
                               step,
                               variant = 'h5-bold',
                               titleMeta,
                               isEnable = true,
                               route,
                               padding,
                               mb = 2,
                             }) {

  const navigateTo = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
      <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={mb}
          padding={padding}
          sx={{
            backgroundColor: '#fff',
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            // ...(!isMobile && {
            //   position: 'sticky',
            //   top: 0,
            //   zIndex: 9999,
            // }),
          }}
      >
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => {
              if (route) {
                navigateTo(route);
              }
            }}
            sx={{
              color: !isEnable && theme.palette.action.disabled,
            }}
        >
          <StepNumber
              step={step}
              disabled={!isEnable}
          />
          <Typography
              variant={variant}
              sx={{
                cursor: 'pointer',
              }}
          >
            {title}
          </Typography>
        </Stack>
        {titleMeta}
      </Stack>
  );
}

function StepNumber({step, disabled}) {

  const theme = useTheme();

  return (
      <Box
          size="small"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            background: disabled ? theme.palette.action.disabled :
                '#333',
            borderRadius: '100%',
            // padding: '4px 0',
            width: '26px',
            height: '26px',
            fontSize: '16px',
            fontWeight: '500',
          }}>
        {step}
      </Box>
  );
}

export default AddBookingCardTitle;
