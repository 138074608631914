import { Alert, alpha, Box, Grid, List, Stack, styled } from "@mui/material";

export const PageActivityContainer = styled(Grid)(({ theme }) => {
  return {};
});

export const ProfileDisplayContainer = styled(Stack)(({ theme,isMobile }) => {
  return {
    rowGap: "2rem",
    position: "relative",
    borderRadius: "12px",
    background: 'rgba(255,255,255,0.75)',
    backdropFilter: 'blur(45px)',
    WebkitBackdropFilter: 'blur(45px)',
    padding: "24px",
    minHeight: "50vh",

    ".profileImageView": {
      position: "relative",
      top: "-10%",
      zIndex: "100",

      // marginTop: isMobile? "0px":"-80px",

      ".profileImage": {
        padding: 0,

        ".initials": {
          color: "black",
          background: "#dce4ff",
          background: "-webkit-linear-gradient(0deg, #dce4ff 0%, #ffeae9 100%)",
          background: "linear-gradient(0deg, #dce4ff 0%, #ffeae9 100%)",
          height: "100%",
          width: "100%",
        },
      },
    }
  };
});

export const WalletInfoContainer = styled(Box)(({ theme }) => {
  return {

    cursor:'pointer',
    height: "184px",
    padding: "19px 24px",
    color:'#fff',
    borderRadius: '19.76px',
    background: 'rgba(152, 145, 145, 1)',
    backdropFilter: 'blur(24.698116302490234px)',
    WebkitBackdropFilter: 'blur(24.698116302490234px)',
    backgroundImage: 'url(/assets/texture.png)',

    ".MuiAlert-message": {
        display: "flex",
        gap: "5px",
        width: "100%"
    },
    '.walletAmount':{
        textDecoration:'none',
        color:'inherit',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',

    },
  };
});

export const MiscListContainer=styled(List)(({theme})=>{

  return{

    '.MuiList-root':{
      borderBottom:`1px solid ${theme.palette.divider}`
    },

    '.ratingList':{
      flexDirection:'row',
      flexWrap:'wrap',
      columnGap:'.2rem',
      rowGap:'.2rem',

      '.ratingChip':{
        minWidth:'89px',
        flexDirection:'row',
        justifyContent:'space-between',
        columnGap:'.2rem',
        height:'22px',
        borderRadius:'4px',
        padding:'0 8px',
        color:'#fff',
        background: 'rgba(3, 169, 244, 1)',
        // background: 'linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)',
      }
    }

  }
})

export const EventsViewContainer=styled(Box)(({theme})=>{

  return{

    '.header':{
      'a':{
        textDecoration:'none',
        color:theme.palette.primary.main
      }
    }
  }

})

export const BookingsViewContainer=styled(Box)(({theme})=>{

  return{

    '.header':{
      'a':{
        textDecoration:'none',
        color:theme.palette.primary.main
      }
    }
  }

})

export const BookingsCardContainer=styled(Stack)(({theme,isMobile,variant})=>{

  return{

    margin:'1rem 0 0 0',
    flexDirection:'row',
    borderRadius: '12px',
    border: `1px solid ${variant=='error'?theme.palette.error.main:theme.palette.divider}`,
    overflow: 'hidden',
    cursor: 'pointer',
    position:'relative',

    '.leftSection':{
      position:'relative',
      backgroundColor: variant=='error'? alpha(theme.palette.error.main,.1) :theme.palette.grey[100],
      width: '180px',
      ...(isMobile && {
        padding: '24px 10px',
        alignItems: 'center',
        width: '130px',
      }),

      '.verticalDivider':{
        width: '.5px',
        height: '16px',
        backgroundColor: 'black',
      },

    },

    '.clubName':{
      marginBottom:'4px'
    },

    '.playersView':{
      flexDirection:'row',
      columnGap:'1.5rem',
      rowGap:'1rem',
      flexWrap:'wrap',

      '.addedPlayer':{
        flexDirection:'row',
        columnGap:'.25rem',

        '.profileImage':{
          padding: 0,

          ".initials": {
            color: "black",
            background: "#dce4ff",
            height: "100%",
            width: "100%",
            fontSize:'13px'
          },

        }
      },

      '.addedPlayer.female':{
        '.profileImage':{
          '.initials':{
            background: 'linear-gradient(197.38deg, #FFD4EE 18.67%, #D0DBFF 88.08%)',
          }
        }
      },
      '.addedPlayer.male':{
        '.profileImage':{
          '.initials':{
            background: 'linear-gradient(197.38deg, #C7EBFF 18.67%, #D0DBFF 88.08%)',
          }
        }
      }

    },
    '.openMatchParticipants':{
        flexDirection:isMobile?'column':'row',
      },

    '.teamContainer':{

      // flexWrap:'wrap',
      rowGap:'1rem',

      '.addedPlayer':{
        '.MuiIconButton-root':{
            height:"24px",
            width:"24px",
            border:`1px solid ${theme.palette.divider}`
        }
      }
    }

  }
})

export const BookingsCardContainerV2=styled(Stack)(({theme,isMobile})=>{

  return{

    margin:'1rem 0 0 0',
    borderRadius: '12px',
    overflow: 'hidden',
    cursor: 'pointer',
    minHeight:'129px',
    background:theme.palette.grey[50],
    padding:'12px 16px',
    position:'relative',

    '.playersView':{
      flexDirection:'row',
      columnGap:'1rem',
      rowGap:'1rem',
      flexWrap:'wrap',

      '.addedPlayer':{
        flexDirection:'row',
        columnGap:'.25rem',

        '.profileImage':{
          padding: 0,

          ".initials": {
            color: "black",
            background: "#dce4ff",
            height: "100%",
            width: "100%",
            fontSize:'13px'
          },

        }
      },

      '.addedPlayer.female':{
        '.profileImage':{
          '.initials':{
            background: 'linear-gradient(197.38deg, #FFD4EE 18.67%, #D0DBFF 88.08%)',
          }
        }
      },
      '.addedPlayer.male':{
        '.profileImage':{
          '.initials':{
            background: 'linear-gradient(197.38deg, #C7EBFF 18.67%, #D0DBFF 88.08%)',
          }
        }
      }

    },
    '.openMatchParticipants':{
      flexGrow:isMobile?1:0,
      justifyContent:isMobile?'center':'space-between',
    },
  }
})

export const OpenMatchLabelContainer=styled(Stack)(({theme, color})=>{

  return{

        padding:'4px',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        position:'absolute',
        top:0,
        background:color,
        width:"100%",
        color:'#fff'
  }

})
