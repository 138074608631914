import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import useExperienceContext
  from '../../hooks/clubExperience/useExperienceContext';
import {useParams, useSearchParams} from 'react-router-dom';
import moment from 'moment-timezone';
import {customersSearchByDate_POST} from '../../vendor/redux/actions/search';
import {useDispatch} from 'react-redux';
import {useMediaQuery} from '@mui/material';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import useSocket from '../../hooks/sockets/useSocket';
import {APP_DEFAULT_TIMEZONE} from '../../constants/appDefaults';
import {capitalizeFirstLetter} from '../../utils/string';

const SlotSearchV2Context = createContext();

export function ProviderSlotSearchV2({
                                       defaultDate,
                                       defaultMinDate,
                                       defaultSport,
                                       defaultSelectedDuration,
                                       view, // "" || "openMatch"
                                       experience,
                                       children,
                                     }) {

  const dispatch = useDispatch();
  const {socket} = useSocket();
  const isAuthenticated = useIsAuthenticated();
  const {clubId} = useParams();
  const [searchParams] = useSearchParams();

  const preferredSport = searchParams?.get('sport');

  const isTablet = useMediaQuery('(max-width: 1270px)');
  const isMobile = false;

  const isCompanyView = experience === 'COMPANY';

  const {
    clubDetails,
    clubPolicies,
    clubTimings,
    clubSports,
  } = useExperienceContext();

  const clubTimezone = clubDetails?.addresses[0].timezone ??
      APP_DEFAULT_TIMEZONE;

  const initialSport = (() => {

    //specific sport
    if (defaultSport) {
      return defaultSport;
    }

    //search params sport preference
    if (preferredSport) {
      let capitalized = capitalizeFirstLetter(preferredSport);
      const exists = clubSports?.find((cs) => cs.sport === capitalized);
      if (exists) {
        return capitalized;
      }
    }

    // local storage sport preference
    const localPreferred = localStorage.getItem('preferredSport');
    if (localPreferred) {
      const exists = clubSports?.find((cs) => cs.sport === localPreferred);
      if (exists) {
        return localPreferred;
      }
    }

    //default
    return clubSports?.[0]?.sport;
  })();

  const initialDate = defaultDate || moment.tz();

  const [selectedDate, setSelectedDate] = useState(initialDate);

  const [selectedTime, setSelectedTime] = useState(initializeSelectedTime);

  const [sports, setSports] = useState(clubSports);
  const [selectedSport, setSelectedSport] = useState(initialSport);

  const [durations, setDurations] = useState(initializeDurations);
  const [selectedDuration, setSelectedDuration] = useState(
      defaultSelectedDuration ?? initializeSelectedDuration);

  const [appliedFilters, setAppliedFilters] = useState([]);

  const [slotDurationGroups, setSlotDurationGroups] = useState([]);
  const [isLoadingSlots, setIsLoadingSlots] = useState(true);

  const [selectedSlot, setSelectedSlot] = useState({});
  const [selectedCourt, setSelectedCourt] = useState({});

  const [isOpenCourtSelect, setIsOpenCourtSelect] = useState(false);

  const [willNavigateToCreateBooking, setWillNavigateToCreateBooking] = useState(
      false);

  const [allowCourtUpdate, setAllowCourtUpdate] = useState(true);

  /*Slots for selected duration*/
  const slots = useMemo(() => {

    if (!isCompanyView) {

      const selectedDurationGroup = slotDurationGroups?.find((g) => {
        return +g.duration === +selectedDuration;
      });

      if (selectedDurationGroup) {
        return selectedDurationGroup.slots;
      } else {
        return [];
      }

    }

    if (isCompanyView) {

      const clubs = slotDurationGroups?.map((club) => {

        return {
          ...club,
          slots: club.slots.filter(
              (s) => +s.duration === +selectedDuration)?.[0],
        };
      });

      return clubs;
    }

  }, [selectedDuration, slotDurationGroups]);

  /*State Initializers*/

  function initializeSelectedTime() {
    return getStartTime(selectedDate, clubTimezone, clubTimings);
  }

  function initializeDurations() {
    return getAvailableDurations(clubPolicies, initialSport, initialDate,
        clubTimezone, selectedTime);
  }

  function initializeSelectedDuration() {
    const availableDurations = getAvailableDurations(clubPolicies, initialSport,
        initialDate,
        clubTimezone, selectedTime);
    return availableDurations?.[0];
  }

  /*Click Event Handlers*/
  function handleSelectSport(newSport) {

    setSelectedSport(newSport);
    localStorage.setItem('preferredSport', newSport);

    //Update Durations
    const newDurations = getAvailableDurations(clubPolicies, newSport,
        selectedDate, clubTimezone, selectedTime);

    setDurations(newDurations);

    //Update Selected Duration
    const newSelectedDuration = newDurations?.[0] ?? defaultSelectedDuration;
    setSelectedDuration(newSelectedDuration);

    //Reset Applied Filters
    setAppliedFilters([]);

  }

  function handleSelectDuration(newDuration) {
    setSelectedDuration(newDuration);
  }

  function handleSelectDate(newDate) {
    setSelectedDate(newDate);
    //Set Selected Time
    const newSelectedTime = getStartTime(newDate, clubTimezone, clubTimings);
    setSelectedTime(newSelectedTime);
  }

  function handleApplyFilters(newFilters) {
    setAppliedFilters(newFilters);
  }

  /*Fetchers*/
  function fetchSlots(
      selectedDate, selectedTime, selectedDuration, selectedSport,
      appliedFilters) {

    setIsLoadingSlots(true);

    const {courtTypes, courtSizes, courtTurfs, courtFeatures} = getFilters(
        appliedFilters);

    const body = {
      date: moment.tz(selectedDate, clubTimezone).format('YYYY-MM-DD'),
      startTime: selectedTime.format('HH:mm'),
      duration: selectedDuration === 'all' ? '' : selectedDuration,
      sport: selectedSport.toLowerCase(),
      courtType: courtTypes || '',
      courtSize: courtSizes || '',
      courtTurf: courtTurfs || '',
      courtFeature: courtFeatures || '',
      searchTerm: '',
      limit: '',
      offset: '',
      type: view === 'openMatch' ? 'open_match' : '',
    };

    dispatch(customersSearchByDate_POST(body, clubId, cbSuccess, cbFail));

    function cbSuccess(res) {

      const clubs = res?.data?.data;

      if (isCompanyView) {

        if (clubs.length > 0) {

          let array = [];

          for (let i = 0; i < clubs?.length; i++) {

            const durationGroups = normalizeData(clubs[i], selectedDate,
                clubTimezone);

            if (durationGroups?.length > 0) {

              const firstDurationGroup = durationGroups?.at(0);

              const clubInfo = {
                clubName: firstDurationGroup?.clubName,
                clubId: firstDurationGroup?.clubId,
                imageUrl: firstDurationGroup?.imageUrl,
                street: firstDurationGroup?.street,
                postalCode: firstDurationGroup?.postalCode,
                city: firstDurationGroup?.city,
              };

              array.push({...clubInfo, slots: durationGroups});
            }
          }

          setSlotDurationGroups(array);

        } else {
          setSlotDurationGroups([]);
        }

      } else {

        if (clubs?.length > 0) {

          const clubData = clubs[0];

          const durationGroups = normalizeData(clubData, selectedDate,
              clubTimezone);

          setSlotDurationGroups(durationGroups);

        } else {
          setSlotDurationGroups([]);
        }
      }

      setIsLoadingSlots(false);
    }

    function cbFail(e) {
      setIsLoadingSlots(false);
      setSlotDurationGroups([]);
    }

    function getFilters(selectedFilters) {

      const parsed = selectedFilters.map((filter) => JSON.parse(filter));

      let courtTypes = parsed.filter((filter) => filter?.group === 'type')
          .map((el) => {
            return el.value;
          })
          .toString();

      let courtSizes = parsed.filter((filter) => filter?.group === 'size')
          .map((el) => {
            return el.value;
          })
          .toString();

      let courtTurfs = parsed.filter((filter) => filter?.group === 'surface')
          .map((el) => {
            return el.value;
          })
          .toString();

      let courtFeatures = parsed.filter(
          (filter) => filter?.group === 'features').map((el) => {
        return el.value;
      }).toString();

      return {courtTypes, courtSizes, courtTurfs, courtFeatures};
    }

  }

  /*Utils*/
  function getStartTime(selectedDate, clubTimezone, clubTimings) {

    const selectedDay = selectedDate.locale('en').format('dddd');

    const selectedDayTimings = clubTimings?.find((timing) => {
      return timing.day === selectedDay;
    });

    const nowTZ = moment.tz(moment(), clubTimezone);

    const clubMinTime = moment.tz(
        `${nowTZ.format('YYYY-MM-DD')} ${selectedDayTimings?.startTime}`,
        'YYYY-MM-DD HH:mm', clubTimezone);

    const clubMaxTime = moment.tz(
        `${nowTZ.format('YYYY-MM-DD')} ${selectedDayTimings?.endTime}`,
        'YYYY-MM-DD HH:mm', clubTimezone);

    const timeNowTZ = moment.tz(moment(), clubTimezone)
        .set('second', 0)
        .set('millisecond', 0);

    const isSelectedDateToday = moment.tz(selectedDate, clubTimezone)
        .isSame(timeNowTZ, 'day');

    if (isSelectedDateToday) {

      const timeNowSameOrBeforeClubStartTime = timeNowTZ?.toDate() <=
          clubMinTime.toDate();

      const isTimeNowSameOrAfterClubEndTime = timeNowTZ?.toDate() >=
          clubMaxTime.toDate();

      if (isTimeNowSameOrAfterClubEndTime) {
        return clubMaxTime;
      }

      if (timeNowSameOrBeforeClubStartTime) {
        return clubMinTime;
      }

      if (!timeNowSameOrBeforeClubStartTime) {
        //time now after club start time
        let nearestInterval;

        if (timeNowTZ.minute() < 30) {

          nearestInterval = timeNowTZ.clone()
              .set('minute', 0)
              .add(30, 'minute');

        } else {

          nearestInterval = timeNowTZ.clone()
              .set('minute', 30)
              .add(30, 'minute');

          if (nearestInterval.format('HH:mm') === '00:00') {
            nearestInterval = moment()
                .set('hour', 23)
                .set('minute', 59)
                .set('second', 0)
                .set('millisecond', 0);
          }

        }
        return nearestInterval;

      }
    }

    if (!isSelectedDateToday) {
      //set min time to club start time
      return clubMinTime;
    }

  }

  function getAvailableDurations(
      policies, selectedSport, selectedDate, clubTimezone, selectedTime) {

    // In case of fixed duration rules, returned fixed duration;

    const policiesFixedDurations = policies?.fixDurations;

    if (policiesFixedDurations) {

      const sportRestriction = policiesFixedDurations?.find(
          (fd) => fd.sport === selectedSport);

      if (sportRestriction) {

        const durationGapCheck = policies.durationGapCheck;
        const restrictionType = sportRestriction?.durationType;

        const isDayApplicableToRestriction = getIsDayApplicableToRestriction(
            selectedDate, durationGapCheck, restrictionType);

        if (isDayApplicableToRestriction) {

          const restrictionStartTime = sportRestriction?.startTime;

          const isTimeApplicableToRestriction = getIsTimeApplicableToRestriction(
              selectedTime, restrictionStartTime, clubTimezone);

          if (isTimeApplicableToRestriction) {
            return [+sportRestriction?.duration];
          }

        }
      }
    }

    // In case of no restrictions, return durations for sport

    const policiesDurations = policies?.club?.clubTimeslotDurations;

    const durationsGroupedBySport = groupDurationsBySport(policiesDurations);

    return durationsGroupedBySport[selectedSport];

    /*Utils*/

    function getIsDayApplicableToRestriction(
        selectedDate, bookingRestrictionsEnabled, fixDurationType) {

      if (!bookingRestrictionsEnabled) {
        return false;
      }

      if (fixDurationType === 'all') {
        return true;
      }

      if (fixDurationType === 'weekends') {
        return !!getIsDateWeekEnd(selectedDate);
      }

      if (fixDurationType === 'weekdays') {
        return !getIsDateWeekEnd(selectedDate);
      }

    }

    function getIsTimeApplicableToRestriction(
        selectedTime, fixDurationStartTime, clubTimezone) {

      const fixDurationTimeTZ = moment.tz(fixDurationStartTime, 'HH:mm',
          clubTimezone);

      return selectedTime.toDate() >= fixDurationTimeTZ.toDate();
    }

    function getIsDateWeekEnd(date) {
      const day = date.locale('en').format('dddd');
      return day === 'Saturday' || day === 'Sunday';

    }

    function groupDurationsBySport(policiesDurations) {

      const groupedDurations = {};

      policiesDurations?.forEach(entry => {
        entry.sportIds.forEach(sport => {
          if (!groupedDurations[sport.name]) {
            groupedDurations[sport.name] = [];
          }
          groupedDurations[sport.name].push(entry.duration);
        });
      });

      return groupedDurations;
    }

  }

  function normalizeData(clubGroup, selectedDate, clubTimezone) {

    const clubId = clubGroup.id;
    const imageUrl = clubGroup?.imageUrl;
    const clubName = clubGroup?.name;
    const clubCountry = clubGroup.addresses[0].country;
    const clubCity = clubGroup.addresses[0].city;
    const clubStreet = clubGroup.addresses[0].streetAddress;
    const clubHouseNumber = clubGroup.addresses[0].houseNumber;
    const clubPostalCode = clubGroup.addresses[0].postalCode;

    const clubDurationGroups = clubGroup.availability;

    const normalizedDurationGroups = [];

    const timeNowTZ = moment.tz(moment(), clubTimezone)
        .set('second', 0)
        .set('millisecond', 0);

    const isSelectedDateToday = moment.tz(selectedDate, clubTimezone)
        .isSame(timeNowTZ, 'day');

    clubDurationGroups.forEach((group) => {

      const durationGroup = {};

      durationGroup.duration = group.duration;
      durationGroup.slotCount = group.slotCount;
      durationGroup.clubName = clubName;
      durationGroup.imageUrl = imageUrl;
      durationGroup.clubId = clubId;
      durationGroup.street = clubStreet;
      durationGroup.postalCode = clubPostalCode;
      durationGroup.city = clubCity;

      const slots = [];

      group.slots.forEach((s) => {

        const {date, startTime, endTime} = s;

        const slot = {};

        let courts = [];

        slot.slot = startTime;

        s.courts.forEach((court) => {

          const {
            courtFeature,
            courtSize,
            courtTurf,
            courtType,
            name: courtName,
            id: courtId,
            sport: courtSport,
            price,
            orginalPrice: originalPrice,
            discountApplied,
          } = court;

          courts.push({
            clubId,
            clubName,
            clubCountry,
            clubCity,
            clubStreet,
            clubHouseNumber,
            clubPostalCode,
            courtId,
            courtName,
            courtSport,
            courtSize,
            courtTurf,
            courtType,
            courtFeature,
            date,
            startTime,
            endTime,
            price,
            originalPrice,
            discountApplied,
          });
        });

        // If the selected date is today we want to filter out court options before current time;
        if (isSelectedDateToday) {
          const filteredCourts = courts.filter((court) => {
            const courtTime = moment.tz(court.startTime, 'HH:mm', clubTimezone);
            return courtTime.toDate() >= timeNowTZ.toDate();
          });
          slot.slots = filteredCourts;
        } else {
          slot.slots = courts;
        }

        //sort court by name alphabetically
        const sorted = slot.slots.sort(courtNameSortComparator);
        slot.slots = sorted;

        if (slot.slots.length > 0) {
          slots.push(slot);
        }
      });

      durationGroup.slots = slots;

      if (durationGroup.slots.length > 0) {
        normalizedDurationGroups.push(durationGroup);
      }

    });

    return normalizedDurationGroups;

    function courtNameSortComparator(a, b) {
      const courtNameA = a.courtName.toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const courtNameB = b.courtName.toUpperCase();
      if (courtNameA < courtNameB) {
        return -1;
      }
      if (courtNameA > courtNameB) {
        return 1;
      }
      return 0;
    }

  }

  // Save initial sport in local storage
  useEffect(() => {
    localStorage.setItem('preferredSport', initialSport);
  }, []);

  /*Fetch Slots */
  useEffect(() => {
    if (selectedDate && selectedTime && selectedDuration && selectedSport &&
        appliedFilters) {

      const timeout = setTimeout(() => {
        fetchSlots(selectedDate, selectedTime, '', selectedSport,
            appliedFilters);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isAuthenticated, selectedDate, selectedSport, appliedFilters]);

  // Socket Fetch Slots
  useEffect(() => {
    socket.on(`ScheduleClub${clubId}`, (data) => {
      console.log(`Socket on ScheduleClub fired: data:${data}`);
      fetchSlots(selectedDate, selectedTime, '',
          selectedSport, appliedFilters);
    });
    return () => {
      console.log('socket off ', `ScheduleClub${clubId}`);
      socket.off(`ScheduleClub${clubId}`);
    };
  }, [
    clubId, selectedDate, selectedTime, selectedSport, appliedFilters]);

  return (
      <SlotSearchV2Context.Provider value={{
        isMobile,
        isTablet,
        view,
        sports,
        selectedSport,
        handleSelectSport,
        durations,
        selectedDuration,
        handleSelectDuration,
        selectedDate,
        handleSelectDate,
        defaultMinDate,
        appliedFilters,
        handleApplyFilters,
        slots,
        isLoadingSlots,
        slotDurationGroups,
        selectedSlot,
        setSelectedSlot,
        selectedCourt,
        setSelectedCourt,
        isOpenCourtSelect,
        setIsOpenCourtSelect,
        willNavigateToCreateBooking,
        setWillNavigateToCreateBooking,
        allowCourtUpdate,
        setAllowCourtUpdate,
        clubSports,
        clubDetails,
        clubPolicies,
        experience,
      }}>
        {children}
      </SlotSearchV2Context.Provider>
  );
}

export function useSlotSearchV2Context() {
  const {
    isMobile,
    isTablet,
    view,
    sports,
    selectedSport,
    handleSelectSport,
    durations,
    selectedDuration,
    handleSelectDuration,
    selectedDate,
    defaultMinDate,
    handleSelectDate,
    appliedFilters,
    handleApplyFilters,
    slots,
    isLoadingSlots,
    slotDurationGroups,
    selectedSlot,
    setSelectedSlot,
    selectedCourt,
    setSelectedCourt,
    isOpenCourtSelect,
    setIsOpenCourtSelect,
    willNavigateToCreateBooking,
    setWillNavigateToCreateBooking,
    allowCourtUpdate,
    setAllowCourtUpdate,
    clubSports,
    clubDetails,
    clubPolicies,
    experience,

  } = useContext(SlotSearchV2Context);

  return {
    isMobile,
    isTablet,
    view,
    sports,
    selectedSport,
    handleSelectSport,
    durations,
    selectedDuration,
    handleSelectDuration,
    selectedDate,
    defaultMinDate,
    handleSelectDate,
    appliedFilters,
    handleApplyFilters,
    slots,
    isLoadingSlots,
    slotDurationGroups,
    selectedSlot,
    setSelectedSlot,
    selectedCourt,
    setSelectedCourt,
    isOpenCourtSelect,
    setIsOpenCourtSelect,
    willNavigateToCreateBooking,
    setWillNavigateToCreateBooking,
    allowCourtUpdate,
    setAllowCourtUpdate,
    clubSports,
    clubDetails,
    clubPolicies,
    experience,
  };
}
