import {Box, Stack, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

function PageCardTitle({
                         className,
                         title,
                         variant = 'h5-bold',
                         titleMeta,
                         guide,
                         route,
                         padding,
                         mb = 2,
                       }) {

  const navigateTo = useNavigate();

  return (
      <Stack
          className={className}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={mb}
          padding={padding}
          columnGap={1}
          flexWrap={'wrap'}
      >
        <Box sx={{display:'flex' , alignItems:'center' , ml:1}}>

        <Typography
            variant={variant}
            onClick={() => {
              if (route) {
                navigateTo(route);
              }
            }}
            sx={{
              cursor: 'pointer',
            }}
        >
          {title}
        </Typography>
        <Typography>

        {guide}
        </Typography>
        </Box>
        <Box>
        {titleMeta}
        </Box>

      </Stack>
  );
}

export default PageCardTitle;
