import axios from 'axios';
import {store} from '../configure-store';

export const refreshStates = () => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_REFRESH_ALL_STATES'});
};

export const setRange = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_MATCH_RANGE', payload: value});
};

export const setTeamSize = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_TEAM_SIZE', payload: value});
};

export const setCurrentUser = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_CURRENT_USER', payload: value});
};

export const setSuggestedPlayers = (value) => {
  store.dispatch(
      {type: 'OPEN_MATCH_CREATE_SET_SUGGESTED_PLAYERS', payload: value});
};

export const setExtras = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_EXTRAS', payload: value});
};

export const setSubmitData = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_SUBMIT_DATA', payload: value});
};

export const setAppData = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_DATA', payload: value});
};

export const setListingData = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_LISTING_DATA', payload: value});
};

export const setListingFilters = (key, value, filters) => {
  setListingData({
    loading: true,
    data: {},
    hasMore: true,
    filters: {
      ...filters,
      page: 0,
      [key]: value,
    },
  });
};

export const setSingleMatchDetails = (value) => {
  store.dispatch({type: 'OPEN_MATCH_CREATE_SET_SINGLE_DETAIL', payload: value});
};

export const setCollapseBilling = (value) => {
  store.dispatch(
      {type: 'OPEN_MATCH_CREATE_SET_COLLAPSE_BILLING', payload: value});
};

export const setInvalidRating = (value) => {
  store.dispatch(
      {type: 'OPEN_MATCH_CREATE_SET_USER_INVALID_RATING', payload: value});
};

export const setViewGenderErrAlert = (value) => {
  store.dispatch(
      {type: 'OPEN_MATCH_SET_VIEW_OTHER_GENDER_ERR_ALERT', payload: value});
};

export const createOpenMatch_POST = async (body, cbSuccess, cbFailure) => {
  try {
    const response = await axios.post(`/customers/open-match/create`, body);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const addOpenMatchScore_POST = async (
    openMatchId, body, cbSuccess, cbFailure) => {
  try {
    const response = await axios.post(
        `/customers/open-match/score/${openMatchId}`, body);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const validateOpenMatchScore_POST = async (
    openMatchId, body, cbSuccess, cbFailure) => {
  try {
    const response = await axios.post(
        `/customers/open-match/score/validate/${openMatchId}`, body);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const registerToOpenMatch_POST = async (body, cbSuccess, cbFailure) => {
  try {
    const response = await axios.post(
        `/customers/open-match/registration/${body?.id}`, body);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const getOpenMatchDetails_GET = async (id, cbSuccess, cbFailure) => {
  try {
    const response = await axios.get(`/customers/open-match/single/${id}`);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const getOpenMatchList_GET = async (body, cbSuccess, cbFailure) => {

  const {
    sport,
    page,
    limit,
    clubId,
    date,
    gender,
  }
      = body;

  const params = `sport=${sport}&page=${page}&limit=${limit}&clubId=${clubId}&gender=${gender}&date=${date}`;

  try {
    const response = await axios.get(`/customers/open-match/listing?${params}`);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const sendInviteLink_POST = async (id, body, cbSuccess, cbFailure) => {
  try {
    const response = await axios.post(`/customers/open-match/share-link/${id}`,
        body);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const cancelOpenMatchSlot_POST = async (id, cbSuccess, cbFailure) => {
  try {
    const response = await axios.post(`/customers/open-match/cancel/${id}`);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const getPlayerRating_GET = async (id, cbSuccess, cbFailure) => {
  try {
    const response = await axios.get(`v2/customers/ratings/self?clubId=${id}`);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const getClubsList = async (id, cbSuccess, cbFailure) => {
  try {
    const response = await axios.get(`/customers/fetch-company-clubs/${id}`);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};

export const getOpenMatchProfileData = async (
    openMatchId, userId, cbSuccess, cbFailure) => {
  try {
    const response = await axios.get(
        `/customers/open-match/${openMatchId}/profile/${userId}`);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};
export const getOpenMatchComments = async( openMatchId , limit , cbSuccess, cbFailure ) => {
  try {
    const response = await axios.get(`/customers/open-match/${openMatchId}/comment/listing?limit=${limit}`);
    cbSuccess(response);

  } catch (e) {
    cbFailure(e);
  }
};


export const postOpenMatchComments = async(body , openMatchId , cbSuccess, cbFailure ) => {
  try {
    const response = await axios.post(`/customers/open-match/${openMatchId}/comment` , body);
    cbSuccess(response);

  } catch (e) {
    console.log('error' ,e)
    cbFailure(e);
  }
};

