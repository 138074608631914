import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {getLocalizedNum} from '../../utils/localization';
import useLocale from '../../hooks/localization/useLocale';
// import TrainingTimeSection from "./TrainingTimeSection";
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import IconClock from '../../assets/icons/IconClock';
import useIsMobile from '../../hooks/ui/useIsMobile';
import IconCalendarCheck from '../../assets/icons/IconCalendarCheck';
import useDefaults from '../../hooks/defaults/useDefaults';
import {formatTime} from '../../utils/ui';
import {formatCategoryName, formatName} from '../../utils/global';
import TitleChip from '../Activity/BookingsDisplay/TitleChip';
import InlineTextList from '../_Global/InlineTextList';

const TournamentListingCard = ({
                                 experience,
                                 tournament,
                                 type = null,
                                 isSingleItem = true,
                                 isBackground = false,
                                 showChip = false,
                                 showPrice = true,
                               }) => {

  const theme = useTheme();
  const {t} = useTranslate();
  const {locale} = useLocale();
  const navigateTo = useNavigate();
  const {currencySymbol} = useDefaults();
  const title = tournament?.name;
  const subTitle = tournament?.clubName;
  const price = tournament?.price;
  const slotsLeft = tournament?.remainingSlots;
  const isAvailable = tournament?.remainingSlots > 0;
  const categoryNames = tournament?.categoryNames;
  const isRegisteredUser = tournament?.registrationStatus === 'active';

  const isMobile = useIsMobile();

  function handleClickTournamentItem() {
    const queryParams = new URLSearchParams({
      tournamentId: tournament?.id,
      name: tournament.name,
    }).toString();

    navigateTo(
        `/club/${tournament?.clubId}/tournaments/tournament-detail?${queryParams}`);

  }

  const PriceChip = () => {

    return (
        <Chip
            size="small"
            variant="contained"
            color={isAvailable ? 'success' : 'error'}
            sx={{
              borderRadius: 1,
              fontSize: '0.875rem',
              fontWeight: 'bold',
              minHeight: '32px',
            }}
            label={isAvailable ?
                `${currencySymbol} ${getLocalizedNum(locale,
                    price)}` :
                t('eventDetail.soldButton')}
        />
    );
  };

  return (
      <Card
          variant="outlined"
          onClick={handleClickTournamentItem}
          sx={{
            borderRadius: 2,
            cursor: 'pointer',
            border: 'none',
            position: 'relative',
          }}
      >

        <Stack
            className={isBackground ? 'bg-gradiant-color' : ''}
            sx={{
              background: theme.palette.grey[200],
              padding: '12px 16px',
            }}
        >

          {
              showChip &&
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}>
                <TitleChip type={'tournament'}/>
              </Box>
          }


          <Stack
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              sx={{minHeight:"32px"}}
          >
            {
                (showPrice && !isRegisteredUser) &&
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  {
                      (isAvailable && !isMobile) &&
                      <Typography variant="body1" whiteSpace={'nowrap'}>
                        {`${slotsLeft} ${t('tournament.slotLeftOnly')}`}
                      </Typography>
                  }
                  <PriceChip/>
                </Stack>
            }
            {
                (isRegisteredUser) &&
                <Chip
                    label={t('global.types.registration.registered')}
                    color={'success'}
                    variant={'filled'}
                    sx={{
                      borderRadius: 1,
                      fontSize: '0.875rem',
                      fontWeight: 'bold',
                      minHeight: '32px',
                    }}
                />
            }

          </Stack>

          <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap={'wrap'}
              rowGap={2}
          >
            {/* Left Section */}
            <Stack>
              <Typography
                  variant="h6-bold"
                  fontSize={{
                    xs: '18px',
                    md: '20px',
                  }}
              >
                {title}
              </Typography>

              {
                experience === 'COMPANY' ?
                    <Typography
                        variant="body2"
                        textTransform={'capitalize'}
                    >
                      {tournament?.clubName}
                    </Typography>
                    :
                    <InlineTextList
                        fontSize={'14px'}
                        fontWeight={500}
                        color={isBackground ? 'white' : 'text.secondary'}
                        items={[
                          subTitle,
                          formatName(tournament?.format),
                          `${parseFloat(
                              tournament?.minRating).toFixed(2)} - ${parseFloat(
                              tournament?.maxRating).toFixed(2)}`,
                        ]}
                    />

              }
              {
                  (isAvailable && isMobile) &&
                  <Typography variant="body1" whiteSpace={'nowrap'}>
                    {`${slotsLeft} ${t('tournament.slotLeftOnly')}`}
                  </Typography>
              }
            </Stack>
          </Stack>
        </Stack>
        <CardContent sx={{
          py: '32px !important',
        }}>
          <Stack
              direction={'row'}
              columnGap={3}
              rowGap={2}
              flexWrap={'wrap'}
              sx={{justifyContent: 'space-between'}}
              mb={2}
          >
            {/*Date*/}
            <Stack direction="row" spacing={1} alignItems="center">
              <IconCalendarCheck/>
              <Stack direction="row" spacing={1}>
                <Typography fontSize={isMobile ? '14px' : '16px'}>
                <span>
                  {dayjs(tournament?.startDate).format('ddd, DD MMM YY')}
                </span>
                  <span>{` - `}</span>
                  <span>
                   {dayjs(tournament?.endDate).format('ddd, DD MMM YY')}
                </span>
                </Typography>
              </Stack>
            </Stack>

            {/*Time*/}
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mr={8}
                minWidth={'174px'}
            >
              <IconClock/>
              <Typography fontSize={isMobile ? '14px' : '16px'}>
                <span>
                  {`${formatTime(tournament?.startTime)}`}
                </span>
                <span>{` - `}</span>
                <span>
                   {`${formatTime(tournament?.endTime)}`}
                </span>
              </Typography>

            </Stack>
          </Stack>

          <Stack rowGap={1} columnGap={1} direction={'row'} flexWrap={'wrap'}>
            {
              categoryNames?.map(category => (
                  <Chip
                      sx={{
                        color: 'rgba(0, 0, 0, 0.56)',
                      }}
                      label={formatCategoryName(category)}
                  />
              ))
            }
          </Stack>
        </CardContent>

      </Card>
  );
};

export default TournamentListingCard;
