function IconTournamentFilled({className, fill}) {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1" width="47" height="47" rx="23.5" fill="white"/>
        <rect x="0.5" y="1" width="47" height="47" rx="23.5" stroke="#DBDDEB"/>
        <g clip-path="url(#clip0_18347_213947)">
        <path opacity="0.3" d="M24 27.166C21.8 27.166 20 25.366 20 23.166V15.166H28V23.166C28 25.366 26.2 27.166 24 27.166Z" fill="#757575"/>
        <path d="M33.3333 15.1667H30.6667V12.5H17.3333V15.1667H14.6667C13.2 15.1667 12 16.3667 12 17.8333V19.1667C12 22.5667 14.56 25.34 17.8533 25.7533C18.6933 27.7533 20.4933 29.26 22.6667 29.7V33.8333H17.3333V36.5H30.6667V33.8333H25.3333V29.7C27.5067 29.26 29.3067 27.7533 30.1467 25.7533C33.44 25.34 36 22.5667 36 19.1667V17.8333C36 16.3667 34.8 15.1667 33.3333 15.1667ZM14.6667 19.1667V17.8333H17.3333V22.9267C15.7867 22.3667 14.6667 20.9 14.6667 19.1667ZM24 27.1667C21.8 27.1667 20 25.3667 20 23.1667V15.1667H28V23.1667C28 25.3667 26.2 27.1667 24 27.1667ZM33.3333 19.1667C33.3333 20.9 32.2133 22.3667 30.6667 22.9267V17.8333H33.3333V19.1667Z" fill="#757575"/>
        </g>
        <defs>
        <clipPath id="clip0_18347_213947">
        <rect width="32" height="32" fill="white" transform="translate(8 8.5)"/>
        </clipPath>
        </defs>
        </svg>
        
    );
  }
  
  export default IconTournamentFilled;
  