import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormHelperText,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {Controller, useForm, useFormContext} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Clear, PersonAddAlt} from '@mui/icons-material';
import {
  customersBookingsCustomers_GET,
  customersSearchInterConnected_GET,
} from '../../../vendor/redux/actions/bookings';
import {validate, validate as validateEmail} from 'email-validator';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import ProfileImageViewer from '../../_Global/Profile/ProfileImageViewer';
import {useTranslate} from '@tolgee/react';
import {USER_LOGIN} from '../../../vendor/redux/constants';
import useLocale from '../../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import useOnboardingContext
  from '../../../hooks/onboarding/useOnboardingContext';
import useUser from '../../../hooks/access/useUser';
import {bookingEvents} from '../../../utils/analyticsEvents';
import AddBookingStyledTextField
  from '../../../vendor/mui/styled-components/AddBookingStyledTextField';
import ChipPlayerPaymentStatus
  from '../../_Global/Chips/ChipPlayerPaymentStatus';
import {useSlotSearchV2Context} from '../../SlotSearchV2/ProviderSlotSearchV2';

function PlayersSelect({
                         isLoading,
                         isEnabled,
                         locationState,
                         allowChangeBooker = true,
                         disableRemoveBooker = false,
                         priceTotal = 0,
                         socketPriceData,
                         isLoadingSocketPrice,
                         singlePlayerErrorMessage,
                         navigateTo = () => {
                         },
                         reloadWindow = () => {
                         },
                         isDiabled,
                         isEditMode,
                         id = null,
                         emailErrorKey = 'emailError',
                         discountApplied,
                         clubId,
                         isPayingWithWallet,
                         handleCloseBookingDialog = () => {
                         },
                       }) {

  const [inputValue, setInputValue] = useState('');
  const [isSignupNow, setIsSignupNow] = useState(false);
  const [isSearchingExistingPlayer, setIsSearchingExistingPlayer] = useState(
      false);
  const [loggingInUser, setLoggingInUser] = useState(null);
  const [disabledForLogin, setDisabledForLogin] = useState(false);
  const [playerSearchResults, setPlayerSearchResults] = useState([]);
  const [isSearchingPlayers, setIsSearchingPlayers] = useState(false);

  const {
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
    register,
    formState,
  } = useFormContext();

  const {isSubmitted, errors} = formState;
  const {t} = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const watched = watch();
  const playersArray = watched?.players;
  const court = watched?.court;
  const USER = useUser();
  const userEmail = USER?.userObj?.email;
  const {
    setIsOpenRegisterDialog,
    setRegisterSuccessFnParams,
    setPreFillEmail,
    setOnRegisterDialogCloseParams,
  } = useOnboardingContext();

  const {
    setWillNavigateToCreateBooking,
  } = useSlotSearchV2Context();

  register('players', {
    validate: (players) => {
      if (isEnabled) {
        if (watched?.paymentType === 'single') {
          if (!USER && validateEmail(inputValue)) {
            handleAddPlayer();
            return false;
          }
          if (players.length >= 1) {
            return true;
          } else {
            return singlePlayerErrorMessage;
          }
        }
        if (watched?.paymentType === 'split') {

          if (!USER && validateEmail(inputValue)) {
            handleAddPlayer();
            return false;
          }

          if (discountApplied) {
            if (court.size === 'Single') {
              if (players.length >= 2) {
                return true;
              } else {
                return `${t('global.component.pleaseAdd')} 1 ${t(
                    'global.components.playerSelect.error.discount.single.splitPayment')}`;
              }
            } else {
              if (players.length >= 4) {
                return true;
              } else {
                const playerToAdd = players.length === 1 ?
                    3 :
                    players.length === 2 ? 2 : 1;
                return `${t('global.component.pleaseAdd')} ${playerToAdd} ${t(
                    'global.components.playerSelect.error.discount.double.splitPayment')}`;
              }
            }
          } else {
            if (players.length >= 2) {
              return true;
            } else {
              return t('global.components.playerSelect.error.splitPayment');
            }
          }

        }

      } else {
        return true;
      }
    },
  });

  function onRegisterSuccess() {
    navigateTo();
  }

  useEffect(() => {
    if (isSubmitted) {
      trigger('players');
    }
  }, [watched.players]);

  function handleAddPlayer() {
    setIsSearchingExistingPlayer(true);
    dispatch(customersBookingsCustomers_GET(inputValue, cbSuccess, cbFail));

    function cbSuccess({data: {data}}) {
      console.log('customersBookingsCustomers_GET success', data);
      const foundExistingCustomer = data.length > 0;
      if (foundExistingCustomer) {
        //existing customer flow
        const existingCustomer = data[0];
        //check if first player
        if ((!watched.players || watched?.players?.length < 1)) {
          //make booker and log in
          setDisabledForLogin(true);
          setLoggingInUser({
            email: existingCustomer?.email,
            id: existingCustomer?.userId,
          });
          setError('loggingIn',
              {message: t('account.exist')});
          clearErrors('players');
        } else {
          //Booker already added, either as logged in or guest
          //at this point now all players will be guests
          const customerObject = {
            id: existingCustomer?.userId,
            email: existingCustomer?.email,
            isOrganizer: false,
            isExistingCustomer: true,
            isGuest: true,
          };
          addPlayerToState(customerObject);
          // setInputValue('');
        }
      }
      if (!foundExistingCustomer) {

        //disabling the guest booking temparory
        if (USER) {
          const guestUserObject = {
            id: null,
            email: inputValue,
            isExistingCustomer: false,
            isGuest: true,
          };
          addPlayerToState(guestUserObject);
          setInputValue('');
        } else {
          // setInputValue('');
          setIsSignupNow(true);
        }

      }
      trigger('guests');
      setIsSearchingExistingPlayer(false);
    }

    function cbFail(err) {
      console.log('customersBookingsCustomers_GET fail', err);
      setIsSearchingExistingPlayer(false);
    }

  }

  function handleSearchCustomerInterConnected() {
    setIsSearchingPlayers(true);
    dispatch(customersSearchInterConnected_GET(inputValue, clubId, cbSuccess,
        cbFail));

    function cbSuccess({data: {data}}) {
      console.log('customersBookingsCustomers_GET success', data);
      const foundExistingCustomer = data.length > 0;
      console.log('foundExistingCustomer', data?.map(arr => arr?.user));
      if (foundExistingCustomer) {
        setPlayerSearchResults(data?.map(arr => arr?.user));
      } else {
        setPlayerSearchResults([]);
      }
      // if (foundExistingCustomer) {
      //   //existing customer flow
      //   const existingCustomer = data[0];
      //   //check if first player
      //   if ((!watched.players || watched?.players?.length < 1)) {
      //     //make booker and log in
      //     setDisabledForLogin(true);
      //     setLoggingInUser({
      //       email: existingCustomer?.email,
      //       id: existingCustomer?.userId,
      //     });
      //     setError('loggingIn',
      //         {message: t('account.exist')});
      //   } else {
      //     //Booker already added, either as logged in or guest
      //     //at this point now all players will be guests
      //     const customerObject = {
      //       id: existingCustomer?.userId,
      //       email: existingCustomer?.email,
      //       isOrganizer: false,
      //       isExistingCustomer: true,
      //       isGuest: true,
      //     };
      //     addPlayerToState(customerObject);
      //     setInputValue('');
      //   }
      // }
      // if (!foundExistingCustomer) {

      //   //disabling the guest booking temparory
      //   if (USER) {
      //     const guestUserObject = {
      //       id: null,
      //       email: inputValue,
      //       isExistingCustomer: false,
      //       isGuest: true,
      //     };
      //     addPlayerToState(guestUserObject);
      //     setInputValue('');
      //   } else {
      //     setInputValue('');
      //     setIsSignupNow(true);
      //   }

      // }
      // trigger('guests');
      setIsSearchingPlayers(false);
    }

    function cbFail(err) {
      console.log('customersBookingsCustomers_GET fail', err);
      setIsSearchingPlayers(false);
    }
  }

  function addPlayerToState(user) {
    if (!user) return false;
    const prevState = watched.players || [];

    const updatedState = [
      ...prevState,
      {
        email: user?.email,
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        contact: user?.contact,
        dateOfBirth: user?.dob,
        gender: user?.gender,
        imageUrl: user?.imageUrl,
        isOrganizer: watched?.players?.length < 1,
        isExistingCustomer: !!user?.isExistingCustomer,
        isGuest: user?.isGuest,
      },
    ];

    bookingEvents['addPlayer']();

    setValue('players', updatedState);
    setPlayerSearchResults([]);
  }

  function removePlayerFromState(statePlayer) {
    const prevState = watched.players;
    const updatedState = prevState.filter((player) => {
      return isEditMode ?
          player?.user.email !== statePlayer?.user.email :
          player.email !== statePlayer.email;
    });
    //Make sure first item is always booker
    if (updatedState.length > 0) {
      const isFirstPlayerBooker = updatedState[0]?.isOrganizer;
      if (!isFirstPlayerBooker) {
        updatedState[0].isOrganizer = true;
      }
    }
    setValue('players', updatedState);
  }

  function makePlayerBooker(playerEmail) {
    const prevState = watched.players;
    const updatedState = prevState.map((player, index) => {
      player.isOrganizer = player.email === playerEmail;
      return player;
    });
    const sorted = updatedState.sort((a, b) => {
      return b.isOrganizer - a.isOrganizer;
    });
    setValue('players', sorted);
  }

  function doesPlayerExistInState(playerState, email) {
    return playerState.some(
        obj => obj.email?.toLowerCase() === email?.toLowerCase());
  }

  useEffect(() => {
    if (inputValue && USER) {
      handleSearchCustomerInterConnected();
    }
  }, [inputValue]);
  return (
      <Box>
        {
            isLoading &&
            <Skeleton width={'256px'}
                      height={'24px'}/>
        }
        {
            !isLoading &&
            <Stack sx={{
              width: '100%',
            }}>
              <AddPlayerInput
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleAddPlayer={handleAddPlayer}
                  isSearchingExistingPlayer={isSearchingExistingPlayer}
                  disabledForLogin={disabledForLogin}
                  doesPlayerExistInState={doesPlayerExistInState}
                  isDiabled={isDiabled}
                  emailErrorKey={emailErrorKey}
                  playerSearchResults={playerSearchResults}
                  handleOnSelectChange={(e, value) => addPlayerToState(value)}
                  trigger={trigger}
                  addPlayerToState={addPlayerToState}
                  USER={USER}
                  setPlayerSearchResults={setPlayerSearchResults}
                  isSearchingPlayers={isSearchingPlayers}
                  setIsSignupNow={setIsSignupNow}
              />
              {watched?.paymentType === 'split' && !errors.players &&
                  discountApplied &&
                  <TextForDiscountApplied
                      court={court}
                      playersArray={playersArray}
                  />
              }
              {
                  loggingInUser &&
                  <LoginPrompt
                      loggingInUser={loggingInUser}
                      setLoggingInUser={setLoggingInUser}
                      addPlayerToState={addPlayerToState}
                      setInputValue={setInputValue}
                      navigateTo={navigateTo}
                      reloadWindow={reloadWindow}
                      setDisabledForLogin={setDisabledForLogin}
                      locationState={locationState}
                  />
              }
              {
                  (watched?.players?.length > 0 || watched?.guests?.length >
                      0) &&
                  <AddedPlayersList
                      allowChangeBooker={allowChangeBooker}
                      disableRemoveBooker={disableRemoveBooker}
                      removePlayerFromState={removePlayerFromState}
                      makePlayerBooker={makePlayerBooker}
                      priceTotal={priceTotal}
                      locationState={locationState}
                      socketPriceData={socketPriceData}
                      isLoadingSocketPrice={isLoadingSocketPrice}
                      isPayingWithWallet={isPayingWithWallet}
                      userEmail={userEmail}
                  />
              }
              {
                  isSignupNow &&
                  <Stack direction={'row-reverse'} mt={2} spacing={2}>
                    <Button onClick={() => {

                      const club = locationState?.club;
                      const company = club?.company;
                      const clubId = club?.id;
                      const companyId = company?.id;

                      if (id) {
                        navigate(`/club/${id}`);
                      } else {

                        if (clubId) {
                          axios.defaults.headers.common['x-clubos-company'] = companyId;
                          axios.defaults.headers.common['x-clubos-club'] = clubId;
                          axios.defaults.headers.common['x-clubos-club-info'] = clubId;
                        }
                        navigate(`/signup`, {
                          state: {
                            previousURL: '/register',
                            obj: locationState,
                          },
                        });
                      }
                      setRegisterSuccessFnParams({
                        successFn: () => onRegisterSuccess(),
                      });
                      // setOnRegisterDialogCloseParams({
                      //   successFn:()=>navigateTo()
                      // })
                      setPreFillEmail(inputValue);
                      handleCloseBookingDialog();
                      setWillNavigateToCreateBooking(false);
                      setIsOpenRegisterDialog(true);
                    }} variant="contained">{t('guest.registerText')}</Button>
                    <Button
                        onClick={() => {
                          setIsSignupNow(false);
                          setPreFillEmail('');
                        }}
                        variant="outlined">{t(
                        'buttons.cancelButton')}</Button>
                  </Stack>
              }
            </Stack>
        }
      </Box>
  );
}

function TextForDiscountApplied({playersArray, court}) {
  const {t} = useTranslate();
  const playerToAdd = playersArray?.length === 1 ?
      3 :
      playersArray?.length === 2 ? 2 : 1;

  return (
      <FormHelperText>
        {court.size === 'Double' && playersArray.length < 4 &&
            `${t('global.component.pleaseAdd')} ${playerToAdd} ${t(
                'global.components.playerSelect.error.discount.double.splitPayment')}`}
        {court.size === 'Single' && playersArray.length < 2 &&
            `${t('global.component.pleaseAdd')} 1 ${t(
                'global.components.playerSelect.error.discount.single.splitPayment')}`}
      </FormHelperText>
  );
}

function LoginPrompt({
                       loggingInUser,
                       setLoggingInUser,
                       reloadWindow,
                       navigateTo,
                       setInputValue,
                       setDisabledForLogin,
                     }) {

  const {t} = useTranslate();
  const dispatch = useDispatch();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const {getValues, clearErrors: clearParentErrors} = useFormContext();

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: {errors},
  } = useForm();

  const {enqueueSnackbar} = useSnackbar();

  function handleCancelLoggingIn() {
    setValue('players', []);
    // setInputValue('');
    setLoggingInUser(null);
    clearParentErrors('loggingIn');
    setDisabledForLogin(false);
  }

  function handleLoginClick() {

    handleSubmit(onValid, onInvalid)();

    async function onValid(data) {

      setIsLoggingIn(true);

      try {
        const APIUrl = 'customers/login';
        const response = await axios.post(APIUrl,
            {email: loggingInUser.email, password: data.password});
        const userObject = response.data.data;
        localStorage.setItem('user', JSON.stringify(userObject));
        dispatch({type: USER_LOGIN, payload: userObject});

        setIsLoggingIn(false);
        clearParentErrors('loggingIn');

        enqueueSnackbar(t('authFlow.loginSuccess'), {variant: 'success'});
        navigateTo();
        reloadWindow();
      } catch (e) {
        console.log('customersLogin Fail', e);
        const error = e.response.data.data;
        setError('password',
            {type: 'invalidPassword', message: error});
        setIsLoggingIn(false);
      }
    }

    function onInvalid(e) {
    }
  }

  return (
      <Box
          component={'form'}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          sx={{maxWidth: '480px', mt: 0, mb: 2}}
      >
        <Controller
            name={'password'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('authFlow.fields.passwordError'),
              },

            }}
            render={({field}) => {
              return <TextField
                  disabled={isLoggingIn}
                  type={'password'}
                  autoComplete={'none'}
                  fullWidth
                  size={'small'}
                  sx={{mt: 2}}
                  placeholder={t('authFlow.fields.labelPassword')}
                  label={t('authFlow.fields.labelPassword')}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    clearErrors('password');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleLoginClick();
                    }
                  }}
                  InputProps={{
                    endAdornment: isLoggingIn && <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <CircularProgress size={'20px'}/>
                    </Box>,
                  }}
              />;
            }}
        />
        {
            errors['password'] &&
            <FormHelperText error>{t(
                errors['password'].message)}</FormHelperText>
        }
        <Stack direction={'row'}
               justifyContent={'flex-end'}
               spacing={1}
               mt={2}>
          <Button
              variant={'outlined'}
              color={'primary'}
              size={'small'}
              onClick={handleCancelLoggingIn}
              disabled={isLoggingIn}
          >
            {t('buttons.cancelButton')}
          </Button>
          <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={handleLoginClick}
              disabled={isLoggingIn}
          >
            {t('buttons.signButton')}
          </Button>
        </Stack>
      </Box>
  );
}

function AddPlayerInput({
                          inputValue,
                          setInputValue,
                          isSearchingExistingPlayer,
                          disabledForLogin,
                          doesPlayerExistInState,
                          handleAddPlayer,
                          playerSearchResults,
                          setPlayerSearchResults,
                          isDiabled,
                          emailErrorKey,
                          handleOnSelectChange,
                          trigger,
                          isSearchingPlayers,
                          USER,
                          setIsSignupNow,
                        }) {

  const theme = useTheme();
  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();

  const {clearErrors, setError, watch, formState} = useFormContext();

  const {errors} = formState;
  const isErrored = !!errors[emailErrorKey] || !!errors['players'];

  const watched = watch();
  const PLAYERS = watched?.players || [];

  const autocompleteRef = useRef(null);

  return (
      <>
        <Stack direction={'row'}>
          <Box width={'100%'}>
            <Autocomplete
                ref={autocompleteRef}
                size={'small'}
                className={(!!errors['players'] ||
                    !!errors['emailError']) ? 'error' : ''}
                freeSolo
                autoHighlight
                // disablePortal
                disabled={isDiabled || disabledForLogin}
                filterSelectedOptions
                openOnFocus={false}
                clearOnBlur={USER ? true : false}
                onBlur={(e) => {
                  if (USER) {

                    // commenting it, to prevent value from clear when
                    // clicking on add btn

                    // setInputValue('');
                    setPlayerSearchResults([]);
                  }

                  if (e.target.value?.length > 3) {

                    if (!validateEmail(inputValue)) {
                      setError(
                          'emailError',
                          {
                            message: t(
                                'global.components.playerSelect.error.email'),
                          });
                    } else if (doesPlayerExistInState(watched?.players || [],
                        inputValue)) {
                      enqueueSnackbar('Player already added',
                          {variant: 'error'});
                    } else {
                      handleAddPlayer();
                    }

                  }

                }}
                open={playerSearchResults.length > 0}
                loading={false}
                options={playerSearchResults || []}
                onChange={handleOnSelectChange}
                getOptionLabel={(option) => {
                  return '';
                }}
                filterOptions={(options, state) => {
                  if (options?.length > 0) {
                    let filtered = options.filter(
                        ({
                           firstName,
                           lastName,
                           email,
                           cellNumber,
                         }) => {

                          const exists = doesPlayerExistInState(
                              PLAYERS, email);

                          if (!exists) {
                            return (
                                firstName?.toLowerCase()?.includes(
                                    state.inputValue.toLowerCase()) ||
                                lastName?.toLowerCase()?.includes(
                                    state.inputValue.toLowerCase()) ||
                                email?.toLowerCase()?.includes(
                                    state.inputValue.toLowerCase()) ||
                                cellNumber?.includes(state.inputValue)
                            );
                          } else {
                            return false;
                          }
                        });

                    if (filtered.length < 1) {
                      setPlayerSearchResults([]);
                    }

                    return filtered;
                  }
                  return playerSearchResults || [];
                }}

                renderInput={(params) => {

                  return <AddBookingStyledTextField
                      {...params}
                      placeholder={t(
                          'global.components.playerSelect.placeholder')}
                      error={(!!errors['players'] ||
                          !!errors['emailError'])}
                      InputProps={{
                        ...params.InputProps,
                        onChange: (event) => {

                          clearErrors();
                          setIsSignupNow(false);
                          setInputValue(event.target.value);
                          if (event.target.value.length === 0) {
                            setPlayerSearchResults([]);
                          }
                        },
                        onKeyUp: (e) => {
                          //clear email validation error
                          if (e.target.value.length === 0) {
                            clearErrors('emailError');
                          }
                          if (validate(e.target.value)) {
                            clearErrors('emailError');
                          }
                        },

                        onKeyDown: (e) => {
                          function clear(e) {
                            if (USER) {
                              e.target.value = '';
                              setInputValue('');

                            }
                            if (formState.isSubmitted) {
                              trigger('players');
                            }
                            const el = autocompleteRef.current.getElementsByClassName(
                                'MuiAutocomplete-clearIndicator')[0];
                            if (el) {
                              el.click();
                            }
                          }

                          if (e.key === 'Escape') {
                            clear(e);
                          }
                          if (e.key === 'Enter') {
                            if (
                                !e.key
                                // disableEnterKey
                            ) {
                              // e.stopPropagation();
                            } else {
                              if (playerSearchResults.length < 1) {
                                e.stopPropagation();
                                //check for valid email
                                const validEmail = validate(
                                    e.target.value);
                                const exists = doesPlayerExistInState(
                                    PLAYERS, e.target.value);
                                if (!validEmail) {
                                  setError('emailError',
                                      {
                                        message: t(
                                            'global.components.playerSelect.error.email'),
                                      });
                                }
                                //check for existing email
                                else if (exists) {
                                  enqueueSnackbar(
                                      t('global.components.playerSelect.error.playerDuplicate'),
                                      {variant: 'error'});
                                } else {
                                  handleAddPlayer();
                                  //clear input
                                  if (USER) {
                                    clear(e);

                                  }
                                }
                              }
                            }
                          }
                        },

                        endAdornment: (<>
                          {isSearchingPlayers || isSearchingExistingPlayer
                              ?
                              <CircularProgress color="info"
                                                size={20}/>
                              :
                              null}
                          {params.InputProps.endAdornment}
                        </>),
                      }}
                  />;
                }}
                renderOption={(props, option, state) => {
                  return (
                      <Box
                          {...props}
                          className={'item'}
                          key={option.id}
                          sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            alignItems: 'center',
                            '&:hover': {backgroundColor: theme.palette.grey[100]},
                            '&.Mui-focused': {backgroundColor: theme.palette.grey[100]},
                          }}
                      >
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mr: 1,
                        }}>
                          <ProfileImageViewer
                              size={'xs'}
                              imgPath={option?.imageUrl}
                              placeholderStyle={'user-icon'}
                          />
                        </Box>
                        <Stack>
                          <Typography variant={'body1'}
                                      fontWeight={500}>{option?.firstName} {option?.lastName}</Typography>
                          <Typography variant={'caption'}
                                      color={'text.secondary'}>{option?.email}</Typography>
                        </Stack>
                      </Box>
                  );
                }}
            />
          </Box>

          <Box ml={1}>
            <IconButton
                disabled={isSearchingExistingPlayer || disabledForLogin || isDiabled}
                color={!isErrored ? 'primary' : 'error'}
                onClick={() => {
                  // if (type === 'event' && watched?.players &&
                  //     watched?.players.length > 0) return true;
                  if (!validateEmail(inputValue)) {
                    setError(
                        'emailError',
                        {
                          message: t(
                              'global.components.playerSelect.error.email'),
                        });
                  } else if (doesPlayerExistInState(watched?.players || [],
                      inputValue)) {
                    enqueueSnackbar('Player already added',
                        {variant: 'error'});
                  } else {
                    handleAddPlayer();
                  }
                }}
            >
              <PersonAddAlt/>
            </IconButton>
          </Box>
        </Stack>
        {
            (errors['players'] || errors[emailErrorKey] ||
                errors['loggingIn']) &&
            <Box mt={1}>
              <FormHelperText error>
                {
                    errors['loggingIn']?.message ||
                    errors[emailErrorKey]?.message ||
                    errors['players']?.message
                }
              </FormHelperText>
            </Box>
        }
      </>
  );
}

function AddedPlayersList({
                            isActionable = true,
                            allowChangeBooker,
                            makePlayerBooker,
                            disableRemoveBooker,
                            removePlayerFromState,
                            socketPriceData,
                            isPayingWithWallet,
                            userEmail,
                          }) {

  const {locale} = useLocale();
  dayjs.locale(locale);

  const {watch} = useFormContext();
  const theme = useTheme();
  const {t} = useTranslate();
  const watched = watch();
  const players = watched?.players || [];

  return (
      <Stack
          spacing={1}
          justifyContent={'center'}
          mt={isActionable ? 2 : 0}
      >
        {
          players.map((statePlayer) => {

            const IS_SINGLE_PAYMENT = watched?.paymentType === 'single';
            const IS_SPLIT_PAYMENT = watched?.paymentType === 'split';
            let isPayingByWallet = false;
            let isPartialPay = false;
            let playerSocketPrice;
            if (socketPriceData) {
              const socketUsers = socketPriceData?.users;
              const socketUser = socketUsers?.find(
                  user => user.email === statePlayer.email);
              playerSocketPrice = socketPriceData?.wallet?.isPartial &&
              userEmail === socketUser?.email ?
                  +socketUser?.price - +socketPriceData?.wallet?.amount :
                  socketUser?.price;
              isPayingByWallet = +socketUser?.originalPrice <=
                  +socketPriceData?.wallet?.amount;
            }

            return (
                <Stack
                    key={statePlayer.email}
                    direction={'row'}
                    flexWrap={'wrap'}
                    columnGap={1.5}
                    rowGap={1.5}
                    alignItems={'center'}
                    sx={{
                      transition: '.2s ease-in-out all',
                      padding: '8px 6px',
                      borderRadius: '4px',
                      '.hide': {
                        opacity: 0,
                        transition: '.2s ease-in-out all',
                      },
                      '&:hover': {
                        backgroundColor: theme.palette.grey['50'],
                        '.hide': {
                          opacity: 1,
                        },
                      },
                    }}
                >
                  <ProfileImageViewer
                      size={'xs'}
                      imgPath={statePlayer.isGuest ?
                          null :
                          statePlayer?.imageUrl}
                      placeholderStyle={'user-icon'}
                  />

                  <Stack sx={{
                    maxWidth: '200px',
                    mr: 'auto',
                  }}>
                    {
                        !statePlayer?.user && !statePlayer.isGuest &&
                        <Typography
                            variant={'body1'}
                            fontWeight={500}
                        >
                          {`${statePlayer.firstName} ${statePlayer.lastName}`}
                        </Typography>
                    }
                    {
                        statePlayer.isGuest &&
                        <Typography
                            variant={'body1'}
                            fontWeight={500}
                        >
                          Guest
                        </Typography>
                    }
                    {
                        statePlayer?.user &&
                        <Typography
                            variant={'body1'}
                            fontWeight={500}
                        >
                          Guest
                        </Typography>
                    }
                    <Stack
                        direction={'row'} spacing={.5}
                    >
                      {
                          statePlayer.isOrganizer &&
                          <Typography
                              variant={'caption'}
                              color={'text.primary'}
                              lineHeight={'15px'}
                          >
                            {t('bookingAdd.booker')}
                          </Typography>
                      }
                      {
                          !statePlayer.isOrganizer &&
                          <Typography
                              variant={'caption'}
                              color={'text.secondary'}
                              lineHeight={'15px'}
                          >
                            {t('bookingAdd.player')}
                          </Typography>
                      }
                      {

                        <Typography
                            variant={'caption'}
                            color={'text.secondary'}
                            lineHeight={'15px'}
                        >
                          -
                        </Typography>
                      }
                      <Typography
                          variant={'caption'}
                          color={'text.secondary'}
                          lineHeight={'15px'}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            minWidth: '161px',
                          }}
                      >
                        {statePlayer?.user?.email || statePlayer.email}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                      direction={'row'}
                      alignItems={'center'}
                      spacing={.25}
                  >
                    {
                        allowChangeBooker && !statePlayer.isOrganizer &&
                        !statePlayer.isExistingCustomer &&
                        <Chip
                            className={'hide'}
                            size={'small'}
                            label={'Make Booker'}
                            onClick={() => {
                              makePlayerBooker(statePlayer.email);
                            }}/>
                    }

                    {
                        !disableRemoveBooker &&
                        !statePlayer.isOrganizer &&
                        <RemovePlayerButton
                            statePlayer={statePlayer}
                            removePlayerFromState={removePlayerFromState}
                            t={t}
                        />
                    }
                    {
                        disableRemoveBooker &&
                        <RemovePlayerButton
                            statePlayer={statePlayer}
                            removePlayerFromState={removePlayerFromState}
                            t={t}
                        />
                    }
                    {
                        IS_SINGLE_PAYMENT && statePlayer.isOrganizer &&
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                          <ChipPlayerPaymentStatus
                              chipStyle={'outline'}
                              variant={'player-price'}
                              size={'small'}
                              playerPrice={playerSocketPrice}
                              paymentStatus={isPayingByWallet &&
                              isPayingWithWallet ? 'paid' : 'unpaid'}
                          />
                        </Box>
                    }
                    {
                        IS_SPLIT_PAYMENT &&
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                          <ChipPlayerPaymentStatus
                              chipStyle={'outline'}
                              variant={'player-price'}
                              size={'small'}
                              playerPrice={playerSocketPrice}
                              paymentStatus={(isPayingByWallet &&
                                  isPayingWithWallet &&
                                  statePlayer?.isOrganizer) ? 'paid' : 'unpaid'}
                          />
                        </Box>
                    }
                  </Stack>

                </Stack>);
          })}
      </Stack>
  );
}

function RemovePlayerButton({statePlayer, removePlayerFromState, t}) {

  const isOrganizer = statePlayer?.isOrganizer;

  const {setValue, watch} = useFormContext();
  const watched = watch();
  const players = watched?.players;

  let tooltipTitle;
  const defaultTitle = t('remove.player');
  const organizerTitle = t('playerSelect.removeOrganizer');

  if (isOrganizer) {
    if (players?.length === 1) {
      tooltipTitle = defaultTitle;
    } else {
      tooltipTitle = organizerTitle;
    }

  } else {
    tooltipTitle = defaultTitle;
  }

  return <Tooltip
      className={'hide'}
      title={tooltipTitle}
      placement={'top'}
      arrow>
    <IconButton
        color={'error'}
        onClick={() => {

          bookingEvents['removePlayer']();
          if (isOrganizer) {
            setValue('players', []);
          } else {
            removePlayerFromState(statePlayer);
          }

        }}
    >
      <Clear/>
    </IconButton>
  </Tooltip>;
}

export default PlayersSelect;
