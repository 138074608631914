import {createContext, useContext, useEffect, useState} from 'react';

const LayoutContext = createContext();

function ProviderLayout({children}) {

  const [hasFixedCTA, setHasFixedCTA] = useState(false);

  return (
      <LayoutContext.Provider value={{
        hasFixedCTA,
        setHasFixedCTA,
      }}>
        {children}
      </LayoutContext.Provider>
  );

}

export default ProviderLayout;

export function useLayoutContext() {
  const {
    hasFixedCTA,
    setHasFixedCTA,
  } = useContext(LayoutContext);

  return {
    hasFixedCTA,
    setHasFixedCTA,
  };

}

export function useFixedCTA(isTrue) {

  const {setHasFixedCTA} = useLayoutContext();

  useEffect(() => {
    if (isTrue) {
      setHasFixedCTA(true);
    } else {
      setHasFixedCTA(false);
    }
    return () => {
      setHasFixedCTA(false);
    };

  }, [isTrue]);

}
