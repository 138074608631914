import {Box, Stack, Typography} from '@mui/material';
import ProfileImageViewer
  from '../../../components/_Global/Profile/ProfileImageViewer';
import {useNavigate, useParams} from 'react-router-dom';
import useExperienceContext
  from '../../../hooks/clubExperience/useExperienceContext';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import ContainerFixed
  from '../../../components/_Global/Containers/ContainerFixed';
import SiteOnboardingButtons
  from '../../../components/_Global/Site/SiteOnboardingButtons';
import SiteUserProfileClub
  from '../../../components/_Global/Site/SiteUserProfileClub';
import {LayoutClubNavHorizontal} from '../Nav/LayoutClubNavHorizontal';
import useIsMobile from '../../../hooks/ui/useIsMobile';
import SiteMobileNav from '../../../components/_Global/Site/SiteMobileNav';
import useWebViewMobile from '../../../hooks/access/useWebViewMobile';
import MobileAppBanner from '../MobileAppBanner';
import useViewMobileBanner from '../../../hooks/onboarding/useViewMobileBanner';

function LayoutCLubHeaderV3({
                              defaultPath,
                              experience = 'CLUB',
                              navItems,
                              showNav,
                              logoNavigate = true,
                            }) {

  const {clubId} = useParams();
  const isAuthenticated = useIsAuthenticated();
  const isMobile = useIsMobile();
  const isWebViewMobile = useWebViewMobile();
  const homePath = defaultPath ?? `/club/${clubId}`;

  const {viewMobileBanner, setViewMobileBanner} = useViewMobileBanner();

  return (
      <>
        {
            isMobile && viewMobileBanner &&
            <MobileAppBanner setView={setViewMobileBanner}/>
        }
        <ContainerFixed>
          <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              sx={{
                height: '120px',
              }}
          >
            {/*Logo*/}
            <Logo
                homeUrl={logoNavigate ? homePath : null}
                experience={experience}
                disabled={isWebViewMobile}
                inverted={true}
            />

            {/*Nav*/}
            {
                showNav &&
                <Stack direction="row" spacing={3} alignItems="center">
                  {
                      !isMobile &&
                      <LayoutClubNavHorizontal navItems={navItems}
                                               inverted={true}/>
                  }
                  {
                      isMobile && !isWebViewMobile &&
                      <Box
                          sx={{
                            ml: 'auto',
                          }}
                      >
                        <SiteMobileNav color={'action'} navItems={navItems}/>
                      </Box>
                  }
                  {
                      !isMobile &&
                      <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                      >
                        {
                            isAuthenticated &&
                            <SiteUserProfileClub
                                size={'sm'}
                                experience={experience}
                            />
                        }
                        {
                            !isAuthenticated && !isWebViewMobile &&
                            <SiteOnboardingButtons
                                size={'large'}
                                showDivider={false}
                                variantRegister={'outlined'}
                                variantSignIn={'contained'}
                                disableRegister={true}
                                signInInverted={true}
                                colorSignin={'secondary'}
                                inverted={true}
                            />
                        }
                      </Box>
                  }
                </Stack>
            }
          </Stack>
        </ContainerFixed>
      </>

  );

}

function Logo({experience, homeUrl, disabled, inverted}) {

  const isCompanyExperience = experience === 'COMPANY';

  const navigateTo = useNavigate();
  const {clubId} = useParams();

  const {clubDetails, companyDetails} = useExperienceContext();
  const details = isCompanyExperience ? companyDetails : clubDetails;

  return (
      <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1.75}
          sx={{
            ...(homeUrl && {
              cursor: 'pointer',
            }),
          }}
          onClick={() => {
            if (disabled)
              return;
            navigateTo(homeUrl);
          }}
      >
        <ProfileImageViewer
            size={'md'}
            imgPath={details?.imageUrl}
            placeholderStyle={'shield-icon'}
        />
        <Typography
            variant={'h5-bold'}
            lineHeight={1.1}
            whiteSpace={'nowrap'}
            mb={.5}
            sx={{
              fontWeight: 700,
              fontSize: {
                md: '24px',
                xs: '18px',
              },
              ...(inverted && {
                color: '#fff',
              }),
            }}
        >
          {details?.name}
        </Typography>
      </Stack>
  );

}

export default LayoutCLubHeaderV3;
