import {Box, Chip, Grid, Stack, Typography, useMediaQuery} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import moment from 'moment-timezone';
import {Route, Routes, useNavigate} from 'react-router-dom';
import DialogBookingsReservationDetail
  from '../../dialogs/Booking/DialogBookingsReservationDetail';
import DialogBookingsRecurringSingleDetail
  from '../../dialogs/Booking/DialogBookingsRecurringSingleDetail';
import {formatTime} from '../../utils/ui';
import PageCard from '../_Global/Page/PageCard';
import TitleChip from '../Activity/BookingsDisplay/TitleChip';
import IconCalendarCheck from '../../assets/icons/IconCalendarCheck';
import useIsMobile from '../../hooks/ui/useIsMobile';

function UpcomingBookingsList({
                                bookings,
                                clubId,
                                totalBookings,
                                getUpcomingBooking,
                              }) {
  const {t} = useTranslate();
  const isDesktopLayout = useMediaQuery('(min-width:800px)');
  const isMobile = useIsMobile();
  const navigateTo = useNavigate();
  const onBookingClick = (booking) => {

    if (booking?.type === 'open_match') {
      navigateTo(`openMatches/detail?id=${booking?.open_match_id}`);
    } else if (booking?.type === 'reservation') {
      navigateTo(`bookings/detail/${booking?.booking_id}`);
    } else {
      navigateTo(`booking/recurring/detail`, {
        state: {
          bookingId: booking?.booking_id,
          recurrBookingId: booking?.recurr_booking_id,
        },
      });
    }

  };

  function getDateDifference(dateString) {
    const targetDate = moment.utc(dateString);
    const currentDate = moment().startOf('day');

    // Calculate the difference in days
    const differenceDays = targetDate.diff(currentDate, 'days');

    if (differenceDays === 0) {
      return 'Today';
    } else if (differenceDays === 1) {
      return `in 1 Day`;
    } else if (differenceDays > 1) {
      return `in ${differenceDays} Days`;
    } else {
      return 0;
    }
  }

  const dotStyle = {
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: 'rgba(168, 168, 168, 1)',
  };
  return (
      <Box>
        <Stack direction={'row'} justifyContent={'space-between'}
               alignItems={'center'} mb={2}>
          <Typography
              textTransform={'capitalize'}
              variant="h5-bold">{t(
              'customerProfile.upcomingBookingHeading')}</Typography>
          <Typography sx={{cursor: 'pointer'}}
                      onClick={() => navigateTo(`/club/${clubId}/bookings`)}
                      variant="body1" color={'primary'}>{t(
              'View All')} ({totalBookings})</Typography>
        </Stack>
        <Grid container spacing={2}>
          {bookings?.map(booking => {
            const differenct = getDateDifference(booking?.start_date_time_utc);
            return (
                <Grid
                    item
                    xs={12}
                    md={6}
                    width={isDesktopLayout ? 'initial' : '100%'}
                    onClick={() => onBookingClick(booking)}
                >
                  <PageCard sx={{p: 2, position: 'relative', overflow: 'hidden'}}>
                    <Stack direction={'column'} spacing={1}
                           sx={{cursor: 'pointer'}}>

                      <Stack direction={'row'} justifyContent={'flex-end'} minHeight={'29px'}>
                        <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                            }}
                        >
                          <TitleChip type={booking?.type}/>
                        </Box>


                        {isMobile &&
                            <Chip
                                label={booking?.status === 'paid' ?
                                    t('global.chips.label.paid') :
                                    t('global.types.transactions.status.unpaid')}
                                size="small"
                                variant="filled"
                                color={booking?.status === 'paid' ?
                                    'success' :
                                    'error'}
                            />
                        }
                      </Stack>

                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack mb={0.5} direction={'row'}
                               justifyContent={'space-between'}>
                          <Stack direction={isMobile ? 'column' : 'row'}
                                 alignItems={'flex-start'}
                                 spacing={1.5}>
                            <Box sx={{display: 'flex', gap: 1}}>
                              <IconCalendarCheck/>
                              <Typography variant="body1">{moment(
                                  booking?.start_date_time_utc)
                                  .format('ddd, DD MMM YY')} </Typography>
                            </Box>
                            <Stack direction={'row'} spacing={1}
                                   alignItems={'center'}>
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}>
                                <Typography
                                    sx={isMobile ? '' : dotStyle}></Typography>
                                {/* <Typography variant="body2"
                                        color={'rgba(0, 0, 0, 0.87)'}>{differenct}</Typography> */}
                                {/* <AccessTime sx={{width: '18px'}}/> */}
                                <Typography variant="body1-emphasis">
                                  {`${formatTime(
                                      booking?.start_time)} - ${formatTime(
                                      booking?.end_time)}`}
                                </Typography>
                              </Box>

                            </Stack>
                          </Stack>
                        </Stack>

                        <Stack>
                          {!isMobile &&
                              <Chip
                                  label={booking?.status === 'paid' ?
                                      t('global.chips.label.paid') :
                                      t('global.types.transactions.status.unpaid')}
                                  size="small"
                                  variant="filled"
                                  color={booking?.status === 'paid' ?
                                      'success' :
                                      'error'}
                              />
                          }
                        </Stack>
                      </Stack>

                      {/* <Stack direction={'row'} alignItems={'center'}
                             spacing={1.5}>
                        <IconSport sx={{width: '18px'}}/>
                        <Typography
                            variant="body1-emphasis">{booking?.name}</Typography>
                        <Stack direction={'row'} spacing={1}
                               alignItems={'center'}>
                          <Typography sx={dotStyle}></Typography>
                          <Typography variant="body2"
                                      color={'rgba(0, 0, 0, 0.87)'}>{booking?.sport}</Typography>
                        </Stack>
                      </Stack> */}
                    </Stack>
                  </PageCard>

                  {/* </Box>
                  </Box> */}

                </Grid>
            );
          })}

        </Grid>
        <Routes>
          <Route path={`booking/detail`}
                 element={<DialogBookingsReservationDetail
                     open={true}
                     root={`/club/${clubId}`}
                     onCancel={getUpcomingBooking}
                 />}/>
          <Route path={`booking/recurring/detail`}
                 element={<DialogBookingsRecurringSingleDetail
                     type={'player-bookings-table'}
                     root={`/club/${clubId}`}
                     isOpen={true}
                     onCancel={getUpcomingBooking}
                 />}/>
        </Routes>
      </Box>
  );
}

export default UpcomingBookingsList;
