import {
  alpha,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import ContainerFixed from '../Containers/ContainerFixed';
import ContainerFullWidth from '../Containers/ContainerFullWidth';
import LogoMeetAndPlay from '../../../assets/logos/LogoMeetAndPlay';
import SiteLanguageSwitcher from './SiteLanguageSwitcher';
import {useLayoutContext} from '../../../providers/ProviderLayout';

function SiteFooter({container = 'full-width' || 'fixed'}) {

  const {hasFixedCTA} = useLayoutContext();

  let Container;

  if (container === 'full-width') Container = ContainerFullWidth;
  if (container === 'fixed') Container = ContainerFixed;

  if (hasFixedCTA) {
    return;
  }

  return (
      <Box
          sx={{
            // borderTop: `1px solid rgba(0,0,0,0.1)`,
            padding: '24px 16px',
            width: '100%',
            mt: 'auto',
            position: 'relative',
            zIndex: 1,
          }}
      >
        <Container>
          <FooterContent/>
        </Container>
      </Box>
  );
}

function FooterContent() {

  const theme = useTheme();
  const {t} = useTranslate();
  const isMobile = useMediaQuery('(max-width:700px)');
  const poweredBy = process.env.REACT_APP_ENV_DOMAIN === 'MEETANDPLAYNL' ?
      'Meet & Play Club' :
      process.env.REACT_APP_NAME;

  return (
      <Stack direction={isMobile ? 'column' : 'row'}
             justifyContent={isMobile ? 'center' : 'space-between'}
             width={'100'} alignItems={isMobile ? 'center' : 'center'}>
        <Stack direction={'row'}>
          <a
          href="https://padelos.co"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            display: 'flex',
            color: 'inherit',
          }}
           >
          <Stack
              direction={'row'}
              alignItems={'center'}
              mb={isMobile ? 1.5 : 0}
              spacing={1}
              sx={{
                px: 2,
                py: 1,
                backgroundColor: alpha(theme.palette.grey[100], .5),
                borderRadius: '34px',
              }}
              >
            <Typography
                whiteSpace={'nowrap'}
                fontSize={'12px'}
            >
              {t('site.footer.poweredBy')}
            </Typography>
            {process.env.REACT_APP_ENV_DOMAIN === 'MEETANDPLAYNL' ?
                <LogoMeetAndPlay
                    width={'78px'}
                /> :
                // <LogoPalla />
                <>
                  <Typography variant="body1" fontWeight={700}
                              fontSize={'14px'}>
                    {process.env.REACT_APP_NAME}
                  </Typography>
              
                </>

            }

          </Stack>
            </a>

        </Stack>

        {/* <Typography sx={{width:'100%' , textAlign:isMobile ? 'center' :'end'}} variant={'body2'}>
          © {t('footer.text')} {poweredBy} {t('footer.textSub')}
         </Typography>  */}
        <Box sx={{background: '#fff', borderRadius: '34px'}}>
          <SiteLanguageSwitcher/>
        </Box>

      </Stack>
  );
}

export default SiteFooter;
