import React, { useEffect, useState } from 'react';
import { clubEvents_GET, clubTrainingss_GET } from '../../vendor/redux/actions/customers';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/access/useUser';
import useLocale from '../../hooks/localization/useLocale';
import { getLocalizedNum } from '../../utils/localization';
import { customerEvents } from '../../utils/analyticsEvents';
import PageCard from '../_Global/Page/PageCard';
import PageCardTitle from '../_Global/Page/PageCardTitle';
import Scrollbar from '../_Global/ScrollBar/ScrollBar';
import EventListingCard from '../Events/EventListingCard';
import useBreakpoint from '../../hooks/ui/useBreakpoint';
import useDefaults from '../../hooks/defaults/useDefaults';
import IconNoEvents from '../../assets/icons/IconNoEvents';
import useIsMobile from '../../hooks/ui/useIsMobile';

function ClubInfoNewEvents({ clubId, type, policies }) {

  const dispatch = useDispatch();
  const { t } = useTranslate();
  const navigateTo = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile()
  const { currencySymbol } = useDefaults();
  const [trainings, setTrainings] = useState()

  const { isMdDown } = useBreakpoint();

  const [events, setEvents] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const user = useUser();

  function fetchEvents() {

    setIsLoadingEvents(true);
    dispatch(clubEvents_GET('active', clubId, 2, 0, cbSuccess, cbFail, false));

    function cbSuccess(res) {
      console.log('clubEvents_GET Success', res);
      setEvents(res.data.data.rows);
      setIsLoadingEvents(false);
    }

    function cbFail(e) {
      console.log('clubEvents_GET Fail', e);
      setIsLoadingEvents(false);
    }
  }


  useEffect(() => {
    fetchEvents();
  }, [user]);

  return (
    <Box className={'item'}>
      <PageCard
        isBlurVariant={true}
      >
        <Stack sx={{ height: (trainings?.length > 0) ? '480px' : '100%' }}>
          <PageCardTitle
            title={t('events.list.title')}
            route={`/club/${clubId}/events`}
            titleMeta={

              !isLoadingEvents && events?.length !== 0 && (
                <Button
                  onClick={() => {
                    navigateTo(`/club/${clubId}/events`);
                  }}
                  variant={'text'}
                >
                  {t('global.buttons.viewAll')}
                </Button>
              )
            }
          />
          {
            !isLoadingEvents && events?.length === 0 &&

            <Box sx={{

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            
            }}
            >


              <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{ width: '100%', textAlign: 'center' }} rowGap={1}>
                <IconNoEvents color={'disabled'} fontSize='medium' />
                <Typography variant="subtitle2" color={theme.palette.grey[600]} >
                  {t('club.noEvent')}
                </Typography>
              </Stack>
            
            </Box>

          }
          {!isLoadingEvents && events?.length > 0 &&
            <Box sx={{
              height: '480px',
              ...(isMdDown && {
                height: 'auto',
                minHeight: '200px',
              })
            }}>

              <Scrollbar autoHide={false}>

                <Stack spacing={2}>
                  {
                    events?.map((event, index) => {
                      return <EventListingCard
                        key={index}
                        event={event}
                        isBackground={false}
                      />;

                    })
                  }
                </Stack>

              </Scrollbar>
            </Box>
          }
        </Stack>

      </PageCard>
    </Box>
  );

}



export default ClubInfoNewEvents;
