import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";

const TitleChip = ({
  type, // 'event' || 'open_match' || 'reservation' || "training" || "tournament"
}) => {
  const { t } = useTranslate();

  const gradients = {
    "event": "linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)",
    "competition": "linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)",
    "reservation": "rgba(57, 204, 217, 1)",
    "recurrence": "rgba(57, 204, 217, 1)",
    "training": "linear-gradient(101.83deg, #DDAB07 12.31%, #D5AE31 84.41%)",
    "open_match": "linear-gradient(101.83deg, #7E34D9 12.31%, #775ED9 84.41%)",
    "tournament":"linear-gradient(90deg, #3AABDB 0%, #219BCF 100%)"
  };

  const titles = {
    "event": t("myActivity.titleChip.event.title"),
    "competition": t("myActivity.titleChip.competition.title"),
    "reservation": t("myActivity.titleChip.booking.title"),
    "recurrence": t("myActivity.titleChip.booking.title"),
    "open_match": t("myActivity.titleChip.open-match.title"),
    "training": t("myActivity.titleChip.training.title"),
    "tournament": t("myActivity.titleChip.tournament.title"),
  };

  if(!type)
    return;

  return (
    <Stack
      sx={{
        height: "29px",
        background: gradients[type?.toLowerCase()],
        justifyContent: "center",
        padding: "6px 12px",
        width: "fit-content",
        borderBottomRightRadius:"13px",
      }}
    >
      <Typography
        fontWeight={700}
        fontSize={"12px"}
        fontStyle={"italic"}
        color={"#fff"}
        textTransform={"uppercase"}
        letterSpacing={"0.17px"}
      >
        {titles[type?.toLowerCase()]}
      </Typography>
    </Stack>
  );
};
export default TitleChip;
