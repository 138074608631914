import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import useIsMobile from '../../hooks/ui/useIsMobile';

function UserProfileRatingGuideDialog({open, setOpen}) {

  const {t} = useTranslate();

  return (
      <DialogBase
          maxWidth={'lg'}
          isOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          title={t('userProfile.ratingGuide.dialog.title')}
          description={t('userProfile.ratingGuide.dialog.description')}
          contentSlot={
            <Box sx={{py: 3}}>
              <RatingTable/>
            </Box>
          }
          actionsSlot={
            <Button
                sx={{mr: 'auto'}}
                variant="outlined"
                onClick={() => setOpen(false)}
            >
              {t('global.buttons.actions.close')}
            </Button>
          }
      />
  );
}

function RatingTable() {

  const isMobile = useIsMobile();
  const {t} = useTranslate();

  const columns = [
    {
      label: t('ratingGuide.column.rating') || 'Rating',
      width: '10%',
    },
    {
      label: t('ratingGuide.column.category') || 'Category',
      width: '20%',
    },
    {
      label: t('ratingGuide.column.description') ||'Description',
      width: '70%',
    },
  ];

  const rows = [
    {
      rating: '0-1',
      category: "Beginner",
      description: t('ratingGuide.category.beginner.description'),
    },
    {
      rating: '1-2',
      category: "Low Intermediate" ,
      description: t('ratingGuide.category.lowIntermediate.description') ,
    },
    {
      rating: '2-3',
      category: "High Intermediate",
      description: t('ratingGuide.category.highIntermediate.description'),
    },
    {
      rating: '3-4',
      category: "Low Advanced",
      description: t('ratingGuide.category.lowAdvance.description'),
    },
    {
      rating: '4-5',
      category: "High Advanced",
      description: t('ratingGuide.category.highAdvance.description'),
    },
    {
      rating: '5-6',
      category: "Expert",
      description: t('ratingGuide.category.expert.description'),
    },
    {
      rating: '6-7',
      category: "Pro",
      description: t('ratingGuide.category.pro.description') ,
    },
  ];

  if (!isMobile) {
    return <RatingTableDesktop columns={columns} rows={rows}/>;
  }

  if (isMobile) {
    return <RatingTableMobile rows={rows}/>;
  }

}

function RatingTableDesktop({columns, rows}) {
  return (
      <TableContainer sx={{}}>
        <Table>
          <TableHead>
            <TableRow>
              {
                columns.map(column => {
                  return (
                      <TableCell
                          sx={{
                            fontWeight: 700,
                            width: column.width,
                          }}
                      >
                        {column.label}
                      </TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row.rating}
                    </TableCell>
                    <TableCell sx={{fontWeight: 600}}>
                      {row.category}
                    </TableCell>
                    <TableCell>
                      {row.description}
                    </TableCell>
                  </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
  );
}

function RatingTableMobile({rows}) {

  const theme = useTheme();

  return (
      <Stack spacing={3}>
        {
          rows.map((row, index) => {
            return (
                <Box
                    sx={{
                      borderRadius: '16px',
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: '#fff',
                    }}
                >
                  <Stack
                      direction={'row'}
                      alignItems={'center'}
                      columnGap={2}
                      sx={{
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        background: 'linear-gradient(45deg, #4994ec, #a895f2)',
                        py: 2,
                        px: 2,
                        color:"#fff"
                      }}
                  >
                    <Typography fontWeight={600} fontSize={'22px'}>
                      {row.rating}
                    </Typography>
                    <Typography fontSize={'18px'}>
                      {row.category}
                    </Typography>
                  </Stack>
                  <Stack
                      sx={{
                        p: 2,
                        py:3,
                      }}
                  >
                    <Typography fontSize={'18px'}>
                      {row.description}
                    </Typography>
                  </Stack>

                </Box>
            );
          })
        }
      </Stack>
  );
}

export default UserProfileRatingGuideDialog;
