export function formatName(name) {
    return name
      .split("_")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
      .join(" ");
  }

  export function formatCategoryName(name) {
    let formatted = name.replace(/_/g, " ");

  formatted = formatted.replace(/\bmens\b/gi, "Men's")
  .replace(/\bwomens\b/gi, "Women's")
  .replace(/\bmix\b/gi, "All")
  .replace(/\all\b/gi, "All")
  .replace(/\bdouble\b/gi, "Doubles")
  .replace(/\bsingle\b/gi, "Singles");

  // formatted = formatted.replace(/\b\w/g, (char) => char.toUpperCase());

  return formatted;
  }