import {Box, useTheme} from '@mui/material';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import {Suspense, useEffect, useRef} from 'react';
import LayoutCompanyPage from './LayoutCompanyPage';
import useIsMobile from '../../hooks/ui/useIsMobile';
import useNavItems from '../LayoutClub/Nav/useNavItems';
import LayoutCompanyHeader
  from '../LayoutClub/LayoutCLubHeaderNew/LayoutCompanyHeader';
import useExperienceContext
  from '../../hooks/clubExperience/useExperienceContext';

function LayoutCompany() {

  const pageRef = useRef();
  const {pathname} = useLocation();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const navItems = useNavItems('COMPANY');
  const {companyId} = useParams();

  const {companyDetails} = useExperienceContext();

  const backgroundImage = companyDetails?.backgroundImage || '/assets/club-bg-default.jpg';


  useEffect(() => {

    if (pageRef.current) {
      pageRef.current.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }

  }, [pathname]);

  return (
      <Box
          ref={pageRef}
          sx={{
            width: '100vw',
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: theme.palette.background.neutral,
            position: 'relative',
            background: `url(${backgroundImage})`,
            backgroundSize:"cover",
            backgroundRepeat: "no-repeat",
          }}
      >
        <LayoutCompanyHeader
            navItems={navItems}
            defaultPath={`/company/${companyId}`}
            experience={'COMPANY'}
        />
        <Box component={'main'}>
          <Suspense fallback={<h1></h1>}>
            <LayoutCompanyPage>
              <Outlet/>
            </LayoutCompanyPage>
          </Suspense>
        </Box>
      </Box>
  );
}

export default LayoutCompany;
