import {useSlotSearchV2Context} from '../ProviderSlotSearchV2';
import {
  ToggleButtonGroupRounded,
  ToggleButtonRounded,
} from '../../_Global/ToggleButtonGroup/ToggleButtonGroupRounded';
import {TimerOutlined} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';
import {useMemo} from 'react';

function SlotSearchV2HeaderDurationSelect() {

  const {
    durations,
    selectedDuration,
    handleSelectDuration,
    isTablet,
    slotDurationGroups,
    isLoadingSlots,
    experience,
  } = useSlotSearchV2Context();

  let durationGroups = useMemo(() => {

    if (experience === 'COMPANY') {

      const clubs = slotDurationGroups;

      const durationMap = {};

      clubs?.forEach(club => {
        club.slots.forEach(slotGroup => {
          const {duration, slotCount} = slotGroup;
          if (!durationMap[duration]) {
            durationMap[duration] = 0;
          }
          durationMap[duration] += slotCount;
        });
      });

      return Object.entries(durationMap).map(([duration, slotCount]) => ({
        duration,
        slotCount,
      }));

    } else {
      return slotDurationGroups;
    }
  }, [slotDurationGroups]);

  const loadingSlotCount = isLoadingSlots || !durationGroups;

  function handleOnChange(e, value) {
    if (selectedDuration === value || !value) {
    } else {
      handleSelectDuration(value);
    }
  }

  return (
      <ToggleButtonGroupRounded
          fullWidth={isTablet}
          exclusive
          value={selectedDuration}
          onChange={handleOnChange}
      >
        {
          durations?.map((duration, index) => {

            const durationGroup = durationGroups?.find((g) => {
              return +g.duration === +duration;
            });

            const slotCount = durationGroup?.slotCount || 0;

            let label;
            let Icon;

            if (selectedDuration === duration) {
              label = `${duration} Min`;
              Icon = isTablet && TimerOutlined;
            } else {
              label = `${duration}`;
            }

            const isActive = selectedDuration === duration;

            return (
                <ToggleButtonRounded
                    key={index}
                    value={duration}
                    color={'primary'}
                    height={'42px'}
                    sx={{
                      ...(isActive && isTablet) && {
                        minWidth: '116px',
                      },
                    }}
                >
                  <Stack alignItems="center" sx={{px: 1}}>
                    <Stack direction="row" alignItems="center">
                      {/*{Icon && <Icon fontSize={'small'}/>}*/}
                      <Typography
                          fontSize={'14px'}
                          color={!isActive ? 'text.primary' : ''}
                          fontWeight={600}
                          lineHeight={1.3}
                      >
                        {label}
                      </Typography>
                    </Stack>
                    <Typography fontSize={'11px'} lineHeight={1.3}>
                      {
                        loadingSlotCount ? '--' :
                            `${slotCount} Slots`
                      }
                    </Typography>
                  </Stack>


                </ToggleButtonRounded>
            );
          })
        }

      </ToggleButtonGroupRounded>
  );
}

export default SlotSearchV2HeaderDurationSelect;
