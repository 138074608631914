import { useTranslate } from "@tolgee/react";
import DialogBase from "../../components/_Global/Dialogs/DialogBase";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme , Stack, Typography, Button, ListItemText, ListItem, Container, List } from "@mui/material";
import useIsMobile from "../../hooks/ui/useIsMobile";



function OpenMatchUserGuideDialog ({open, setOpen}) {

  const {t} = useTranslate();

  return (
      <DialogBase
          maxWidth={'lg'}
          isOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          title={t('openMatch.ratingGuide.dialog.title')}
          description={t('openMatch.ratingGuide.dialog.description')}
          contentSlot={
            <Box sx={{py: 3}}>
              <RatingTable/>
            </Box>
          }
          actionsSlot={
            <Button
                sx={{mr: 'auto'}}
                variant="outlined"
                onClick={() => setOpen(false)}
            >
              {t('global.buttons.actions.close')}
            </Button>
          }
      />
  );
}

function RatingTable() {

  const isMobile = useIsMobile();
  const {t} = useTranslate();

  const columns = [
    {
      label: t('openmatches.ratingGuide.column.rating') || 'Rating',
      width: '10%',
    },
    {
      label: t('openmatches.ratingGuide.column.category') || 'Category',
      width: '20%',
    },
    {
      label: t('openmatches.ratingGuide.column.description') ||'Description',
      width: '70%',
    },
  ];

  const rows = [
    {
      rating: '0-1',
      category: "Beginner",
      description: t('openmatches.ratingGuide.category.beginner.description'),
    },
    {
      rating: '1-2',
      category: "Low Intermediate" ,
      description: t('openmatches.ratingGuide.category.lowIntermediate.description') ,
    },
    {
      rating: '2-3',
      category: "High Intermediate",
      description: t('openmatches.ratingGuide.category.highIntermediate.description'),
    },
    {
      rating: '3-4',
      category: "Low Advanced",
      description: t('openmatches.ratingGuide.category.lowAdvance.description'),
    },
    {
      rating: '4-5',
      category: "High Advanced",
      description: t('openmatches.ratingGuide.category.highAdvance.description'),
    },
    {
      rating: '5-6',
      category: "Expert",
      description: t('openmatches.ratingGuide.category.expert.description'),
    },
    {
      rating: '6-7',
      category: "Pro",
      description: t('openmatches.ratingGuide.category.pro.description') ,
    },
  ];

  if (!isMobile) {
    return <RatingTableDesktop columns={columns} rows={rows}/>;
  }

  if (isMobile) {
    return <RatingTableMobile rows={rows}/>;
  }

}

function RatingTableDesktop({columns, rows}) {
    const {t} = useTranslate();
  return (
  <>
    <Typography variant="h6" fontWeight="bold">
       {t('openmatches.ratingGuide.ul1')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u1.subtext')}
    </Typography>
    <Typography component="ul" sx={{gap:2}}>
      <Stack rowGap={1} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li2')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li3')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li4')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li5')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li6')}</Typography>
      </Stack>  
    
    </Typography>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 3 }}>
     {t('openmatches.ratingGuide.ul2')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u2.subtext')}
    </Typography>
    <Typography component="ul">
    <Stack rowGap={1} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li2')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li3')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li4')}</Typography>
    
    </Stack>
    
    </Typography>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 3 }}>
     {t('openmatches.ratingGuide.ul3')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u3.subtext')}
    </Typography>
    <Typography component="ul">
    <Stack rowGap={1} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li2')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li3')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li4')}</Typography>
    
    </Stack>
    
    </Typography>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 3 }}>
     {t('openmatches.ratingGuide.ul4')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u4.subtext')}
    </Typography>
    <Typography component="ul">
    <Stack rowGap={1} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul4.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul4.li2')}</Typography>
      
    
    </Stack>
    
    </Typography>

  </>
  );
}

function RatingTableMobile({rows}) {

  const theme = useTheme();
  const {t} = useTranslate();

  return (
    <>
    <Typography variant="h6" fontWeight="bold">
       {t('openmatches.ratingGuide.ul1')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u1.subtext')}
    </Typography>
    <Typography component="ul" sx={{gap:1}}>
      <Stack rowGap={2} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li2')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li3')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li4')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li5')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul1.li6')}</Typography>
      </Stack>  
    
    </Typography>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 3 }}>
     {t('openmatches.ratingGuide.ul2')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u2.subtext')}
    </Typography>
    <Typography component="ul">
    <Stack rowGap={2} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li2')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li3')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul2.li4')}</Typography>
    
    </Stack>
    
    </Typography>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 3 }}>
     {t('openmatches.ratingGuide.ul3')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u3.subtext')}
    </Typography>
    <Typography component="ul">
    <Stack rowGap={2} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li2')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li3')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul3.li4')}</Typography>
    
    </Stack>
    
    </Typography>

    <Typography variant="h6" fontWeight="bold" sx={{ mt: 3 }}>
     {t('openmatches.ratingGuide.ul4')}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
    {t('openmatches.ratingGuide.u4.subtext')}
    </Typography>
    <Typography component="ul">
    <Stack rowGap={2} mt={2}>
      <Typography component="li">{t('openmatches.ratingGuide.ul4.li1')}</Typography>
      <Typography component="li">{t('openmatches.ratingGuide.ul4.li2')}</Typography>
      
    
    </Stack>
    
    </Typography>

  </>
  );
}

export default OpenMatchUserGuideDialog;
