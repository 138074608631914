import { Tooltip, Typography } from "@mui/material"
import { LevelChipContainer } from "../../vendor/mui/styled-components/OpenMatches"
import { useTranslate } from "@tolgee/react"

const PlayerRatingChip = ({rating, marginTop})=>{

    const {t} = useTranslate();

    return(
        <LevelChipContainer
        marginTop={marginTop}
        className="levelChip"
        >
            <Tooltip
            placement="top"
            arrow
            title={rating?'':t('openMatches.addPlayer.rating.invalid')}
            >
                <Typography>
                    {rating ?? 'N/A'}
                </Typography>
            </Tooltip>
        </LevelChipContainer>
    )
}
export default PlayerRatingChip