import {
  Divider,
  Link,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {Circle} from '@mui/icons-material';
import BookingsDateTimeDetails from './BookingsDateTimeDetails';
import BookingsCourtDetails from './BookingsCourtDetails';
import BookingsParticipantsDetails from './BookingsParticipantsDetails';
import {useTranslate} from '@tolgee/react';
import dayjs from 'dayjs';
import {
  getBookingTimeHourSuffix,
  getCourtBookingLength,
  getExtrasBillingEntries,
} from '../../../utils/bookings';
import BillingDetails from '../../_Global/Billing/BillingDetails';
import BookingsPaymentTypeDetails from './BookingsPaymentTypeDetails';
import WalletSelect from '../../_Global/Wallet/WalletSelect';
import useLocale from '../../../hooks/localization/useLocale';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import {RefundDetails} from '../../_Global/Billing/RefundDetails';
import {bookingEvents} from '../../../utils/analyticsEvents';
import {useEffect, useState} from 'react';
import {
  getBookingsWalletPaymentOnDetail,
} from '../../../vendor/redux/actions/bookings';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import useUser from '../../../hooks/access/useUser';
import WalletAlerts from '../WalletAlerts';
import usePolicies from '../../../hooks/policies/usePolicies';
import {roundToTwoDecimal} from '../../../utils/math';
import ChipBookingStatus from '../../_Global/Chips/ChipBookingStatus';
import DialogSectionNew from '../../_Global/Dialogs/DialogSectionNew';

function BookingsReservationDetails({
                                      booking,
                                      isLoading,
                                      canDoPayment,
                                      isPayingWithWallet,
                                      origin,
                                      participantPaidStatus,
                                      isAccessCodeShow = true,
                                      fnForWalletTopSuccess,
                                    }) {

  const {t} = useTranslate();
  const {locale} = useLocale();
  const theme = useTheme();
  // const {isSmDown} = useBreakpoint();

  dayjs.locale(locale);
  const dispatch = useDispatch();
  const user = useUser();
  const userId = user?.userObj?.id;

  const [billing, setBilling] = useState();
  const [players, setPlayers] = useState();

  const {token} = useParams();

  const billingData = billing ?? booking?.billing;

  const isAuthenticated = useIsAuthenticated();

  const paymentType = booking?.paymentType;
  // const isSplitPayment = paymentType === 'split';

  const {clubId} = useParams();
  const policies = usePolicies();

  const status = booking?.status;
  const participants = booking?.participants;
  const date = booking?.date;
  const startTime = booking?.startTime;
  const endTime = booking?.endTime;
  const bookingTimeZone = booking?.court?.club.addresses[0].timezone;
  // const clubName = booking?.court.club.name;
  // const clubStreet = booking?.court?.club?.addresses[0]?.streetAddress;
  // const houseNumber = booking?.court?.club?.addresses[0]?.houseNumber;
  // const clubCity = booking?.court?.club?.addresses[0]?.city;
  // const clubPostalCode = booking?.court?.club?.addresses[0]?.postalCode;
  const courtName = booking?.court.name;
  const courtType = booking?.court.courtType;
  const courtFeature = booking?.court.courtFeature;
  const courtSport = booking?.court.sport;

  const bookingTotalPrice = +billingData?.totalPrice;
  const platformFee = billingData?.platformFee;
  const platformPercentage = billingData?.platformPercentageFormatted;
  const discount = +billingData?.discount || 0;
  const courtPrice = +billingData?.court || 0;
  const extrasTotal = +billingData?.extrasTotal || 0;

  const walletAvailableAmount = +booking?.wallet?.walletAmount || 0;
  const canPayByWallet = walletAvailableAmount > 0;
  const hasPayedWithWallet = billingData?.paidViaWallet;
  const hasPayedWithCounter = billingData?.paidViaCounter;

  const isAmountRefunded = booking?.refund?.isAmountRefunded;
  const refundMethod = booking?.refund?.method;
  const refundAmount = booking?.refund?.amount;
  const otherRefund = booking?.refund?.otherRefund;
  const otherAmount = booking?.refund?.otherAmount;

  const bookingLength = getCourtBookingLength(startTime, endTime);
  const bookingLengthSuffix = getBookingTimeHourSuffix(bookingLength);

  const isMinutes = bookingLength < 1;
  const billingEntries = [
    {
      label: `${t(
          'global.components.billing.court')} ${isMinutes ?
          bookingLength * 60 :
          roundToTwoDecimal(bookingLength * 10) /
          10} ${isMinutes ? t('global.components.billing.min') : t(
          `global.components.billing.hour${bookingLengthSuffix}`)}`,
      amount: billingData?.court,
    },
    ...(getExtrasBillingEntries(billingData)),
  ];

  useEffect(() => {

    if (isPayingWithWallet) {

      // operation to change current billing with new billing
      // update current player (person whose booking detail opens) price

      const body = {
        token,
        bookingId: booking?.bookingId,
        userId: !token ? userId : undefined,
      };

      dispatch(getBookingsWalletPaymentOnDetail(body, cbSuccess, cbFail));

      function cbSuccess(res) {

        console.log('getwalletdetailsOnBooknig Success', res);
        const response = res?.data?.data;
        const player = response?.player;
        const currentParticipants = JSON.parse(JSON.stringify(participants));
        const index = currentParticipants?.findIndex(
            x => x.participantId == player?.participantId);
        const walletPaidAmount = +player?.price > +response?.walletCredit ?
            response?.walletCredit :
            0;
        if (index != -1) {
          currentParticipants[index] = {
            ...currentParticipants[index],
            price: player?.price,
            walletPaidAmount,
            remainingAmount: response?.player?.remainingAmount,
            paymentStatus: response.walletCredit >=
            parseFloat(currentParticipants[index]?.price) ? 'paid' : 'unpaid',
          };
        }
        setPlayers(currentParticipants);
        setBilling(
            {
              ...booking?.billing,
              platformFee: response?.platformFee,
              totalPrice: response?.totalPrice,
              paidViaWallet: true,
              walletPaidAmount: response?.walletCredit,
            });

      }

      function cbFail(e) {
        console.log('getwalletdetailsOnBooknig Fail', e);
      }

    } else if (isPayingWithWallet === false) {
      setBilling(booking?.billing);
      setPlayers(booking?.participants);
    }
  }, [isPayingWithWallet]);

  return (
      <Stack>

        {/*Alerts*/}
        {/*<Stack>*/}
        {/*  {*/}
        {/*      isAccessCodeShow && !isLoading && booking?.status === 'paid' &&*/}
        {/*      <MeshlinkLightAndCode*/}
        {/*          pinCode={booking?.meshLink?.data?.pinCode}*/}
        {/*          customMessage={booking?.meshLink?.customPinCodeMessage}*/}
        {/*      />*/}
        {/*  }*/}
        {/*  {*/}
        {/*      !isLoading &&*/}
        {/*      <Box>*/}
        {/*        <AlertsBookingStatus*/}
        {/*            bookingStatus={status}*/}
        {/*            paymentType={paymentType}*/}
        {/*            participants={participants}*/}
        {/*            bookingDate={date}*/}
        {/*            bookingTime={startTime}*/}
        {/*            bookingTimezone={bookingTimeZone}*/}
        {/*            origin={origin}*/}
        {/*            participantPaidStatus={participantPaidStatus}*/}
        {/*        />*/}
        {/*      </Box>*/}
        {/*  }*/}
        {/*</Stack>*/}

        {/*Header*/}
        <Stack
            justifyContent={'space-between'}
            direction={'row'}
            sx={{
              backgroundColor: theme.palette.grey[100],
              py: '20px',
              px: 3,
            }}
        >
          <Stack>
            <Stack
                mt={.5}
                mb={1.5}
                direction={'row'}
                spacing={1}
                divider={<Circle sx={{width: '4px'}} color={'action'}/>}
            >
              {
                  isLoading &&
                  <Skeleton width={'110px'}/>
              }
              {
                  !isLoading &&
                  <Typography>
                    {t('bookingAdd.detailHeading')}
                  </Typography>

              }
              {
                  !isLoading &&
                  <Typography color={'text.secondary'}>
                    {booking?.bookingId}
                  </Typography>
              }
            </Stack>
            <Stack>
              <BookingsCourtDetails
                  variant={'h5-bold'}
                  isLoading={isLoading}
                  courtName={courtName}
                  courtType={courtType}
                  courtFeature={courtFeature}
                  courtSport={courtSport}
              />
            </Stack>
          </Stack>
          {
              !isLoading &&
              <ChipBookingStatus
                  size={'medium'}
                  variant={'booking-status'}
                  bookingStatus={booking?.status}
                  bookingType={booking?.bookingType?.name}
                  bookingPrice={booking?.price}
              />
          }

        </Stack>

        {/*Body*/}
        <Stack
            sx={{px: 3}}
            spacing={2.5}
            py={3}
            divider={<Divider/>}
        >

          <BookingsDateTimeDetails
              isLoading={isLoading}
              date={date && `${dayjs(date, 'YYYY-MM-DD').format('ddd ll')}`}
              timeStart={startTime && dayjs(startTime, 'HH:mm').format('LT')}
              timeEnd={endTime && dayjs(endTime, 'HH:mm').format('LT')}
          />

          <DialogSectionNew
              title={t('bookingAdd.payment')}
              loading={isLoading}
          >
            <BookingsPaymentTypeDetails
                isLoading={isLoading}
                paymentType={t(`global.types.payments.${paymentType}`)}
            />
          </DialogSectionNew>

          {/*<DialogSectionNew*/}
          {/*    loading={isLoading}*/}
          {/*    title={'Club'}*/}
          {/*>*/}
          {/*  <BookingsClubDetails*/}
          {/*      isLoading={isLoading}*/}
          {/*      clubName={clubName}*/}
          {/*      clubStreet={clubStreet}*/}
          {/*      houseNumber={houseNumber}*/}
          {/*      clubCity={clubCity}*/}
          {/*      clubPostalCode={clubPostalCode}*/}
          {/*  />*/}
          {/*</DialogSectionNew>*/}

          {
              isAuthenticated && !isLoading && canDoPayment &&
              <DialogSectionNew
                  title={t('bookings.add.section.wallet')}
                  loading={isLoading}
                  titleMeta={policies?.wallet && <Link
                      style={{
                        cursor: 'pointer',
                        margin: '0 0 0 auto',
                        color: theme.palette.primary.main,
                      }}
                      color={theme.palette.secondary.main}
                      target="_blank"
                      onClick={() => window.open(
                          `${process.env.REACT_APP_URL}/club/${clubId}/wallet/topup?cId=${clubId}`,
                          '_blank')}
                  >
                    {t('wallet.topup')}
                  </Link>}
              >
                <WalletSelect
                    isLoading={isLoading}
                    walletData={{
                      available: !!walletAvailableAmount,
                      canPay: canPayByWallet,
                      amount: +walletAvailableAmount || 0,
                    }}
                    onChange={() => bookingEvents['useWalletFromDetails']()}
                />
                {
                    !isLoading &&
                    <WalletAlerts
                        canPay={canPayByWallet}
                        clubId={clubId ?? booking?.court?.club?.clubId}
                        onSuccess={() => fnForWalletTopSuccess()}
                        walletId={booking?.wallet?.walletId}
                    />}

              </DialogSectionNew>
          }

          <DialogSectionNew
              loading={isLoading}
              title={t('bookingAdd.players')}
          >
            <BookingsParticipantsDetails
                isLoading={isLoading}
                players={players ?? participants}
                isPayingWithWallet={isPayingWithWallet}
                platformFee={platformFee}
                paymentType={booking?.paymentType}
            />
          </DialogSectionNew>

          <DialogSectionNew
              title={t('bookingAdd.billings')}
              loading={isLoading}
          >
            <BillingDetails
                isLoading={isLoading}
                entries={billingEntries}
                discount={discount}
                platformPercentage={platformPercentage}
                platformFee={platformFee}
                isWalletPayment={hasPayedWithWallet}
                isCounterPayment={hasPayedWithCounter}
                walletDebitedAmount={billingData?.walletPaidAmount}
                counterPaidAmount={billingData?.counterPaidAmount}
                totalBillingAmount={bookingTotalPrice}
            />
          </DialogSectionNew>

          {
              isAmountRefunded && otherRefund &&
              <RefundDetails
                  isLoading={isLoading}
                  refundMethod={otherRefund}
                  refundAmount={otherAmount}
              />
          }
          {
              isAmountRefunded &&
              <RefundDetails
                  isLoading={isLoading}
                  refundMethod={refundMethod}
                  refundAmount={refundAmount}
              />
          }

        </Stack>
      </Stack>
  );
}

export default BookingsReservationDetails;

