import { useTranslate } from "@tolgee/react";
import { MiscListContainer } from "../../../vendor/mui/styled-components/Activity";
import { Stack, List, Typography, useTheme } from "@mui/material";

const MiscList = (props , {isOpenMatch = false}) => {
  const { player, loading } = props;
  const theme = useTheme()
  const { t } = useTranslate();

  const entries = [
    {
      label: t("myActivity.profile.miscList.rating.title"),
      render: () => <Ratings/>,
    },
    
    
     !isOpenMatch && {
   // {
      label: t("myActivity.profile.miscList.winLoses.title"),
      value: `${player?.totalWins ?? 0}/${player?.totalLoss ?? 0}`,
    },  
     
  ];

  const Ratings=()=>{

    const sports = player?.ratingList? Object?.keys(player?.ratingList) : [];

    return(
      <Stack
      className="ratingList"
      >
        {
          sports?.map(sport=>{

            return(
              <RatingChip
              key={sport}
              sport={sport}
              rating={player?.ratingList[sport] ?? 0.00}
              />
            )
      
          })
        }
      </Stack>
    )

  }

  const RatingChip=props=>{

    const {sport,rating} = props;

    return(
      <Stack
      className='ratingChip'
      >
        <Typography
        textTransform={'capitalize'}
        variant="body2"
        color={'#fff'}
        >
          {sport}
        </Typography>
        <Typography
        variant="subtitle2"
        fontWeight={600}
        >
          {rating?.toFixed(2)}
        </Typography>
      </Stack>
    )

  }

  return (
    <MiscListContainer isOpenMatch={isOpenMatch}>
      {entries?.map((entry) => {
        return (
          <List 
          key={entry?.value}
          component={Stack}
          direction={'row'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          
          >
            
            <Typography variant="subtitle" fontWeight={600} color={'rgba(0, 0, 0, 0.56)'} >{entry?.label}</Typography>
            {entry?.value ? (
              <Typography className="value">{entry?.value}</Typography>
            ) : entry?.render ? (
              entry?.render()
            ) : (
              ""
            )}
          </List>
        );
      })}
    </MiscListContainer>
  );
};
export default MiscList;
