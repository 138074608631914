import {Skeleton, Stack, Typography} from '@mui/material';
import ProfileImageViewer from '../../_Global/Profile/ProfileImageViewer';
import ChipPlayerPaymentStatus
  from '../../_Global/Chips/ChipPlayerPaymentStatus';
import {useTranslate} from '@tolgee/react';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import {roundToTwoDecimal} from '../../../utils/math';

function BookingsParticipantsDetails({
                                       players = [],
                                       isLoading = false,
                                       platformFee,
                                       isPayingWithWallet,
                                       paymentType,
                                       showProfileImage = true,
                                     }) {

  let IS_AUTHENTICATED = useIsAuthenticated();
  const isPaid = players?.some(
      plr => plr.owner && plr.paymentStatus === 'paid');
  if (isLoading) {
    return (
        <Stack spacing={'12px'}>
          {
            new Array(2).fill(0).map((el, index) => {
              return <Stack spacing={1} key={index}>
                <Skeleton width={'250px'} height={'24px'}/>
                <Skeleton width={'100px'} height={'24px'}/>
              </Stack>;
            })
          }
        </Stack>
    );
  }

  if (!isLoading) {

    return <Stack spacing={'12px'}>
      {
          players && players.map((player, index) => {

            return <PlayerDisplayRow
                key={index}
                player={player}
                hasUserInfoAccess={IS_AUTHENTICATED}
                isPayingWithWallet={isPayingWithWallet}
                platformFee={platformFee}
                paymentType={paymentType}
                isPaid={isPaid}
                showProfileImage={showProfileImage}
            />;
          })
      }
    </Stack>;
  }

}

function PlayerDisplayRow({
                            player,
                            hasUserInfoAccess,
                            platformFee,
                            isPayingWithWallet,
                            paymentType,
                            isPaid,
                            showProfileImage,
                          }) {
  const {t} = useTranslate();
  const paymentStatus = paymentType === 'single' && isPaid ?
      'paid' :
      parseFloat(player?.remainingAmount) > 0 ? 'unpaid' :
      isPayingWithWallet && player?.walletPaidAmount === 0 ?
          'paid' :
          player?.paymentStatus;
  const price =  parseFloat(player?.remainingAmount) > 0 ? 
  player?.remainingAmount
  : player?.paymentStatus === 'unpaid' &&
  +player?.walletPaidAmount > 0 ?
      roundToTwoDecimal(
          parseFloat(player?.price) - parseFloat(player?.walletPaidAmount)) :
         
      player?.price;
  if (player) {
    return (
        <Stack
            direction={'row'}
            rowGap={1}
            columnGap={1.5}
            alignItems={'center'}
            mt={1}
            sx={{width: '100%'}}
        >
          {showProfileImage &&
              <ProfileImageViewer
                  size={'xs'}
                  imgPath={hasUserInfoAccess ? player.user.imageUrl : null}
                  placeholderStyle={'user-icon'}
              />
          }
          <Stack>
            {
                hasUserInfoAccess &&
                <Typography>
                  {`${player?.user?.firstName || ''} ${player?.user?.lastName ||
                  ''}`}
                </Typography>
            }
            {
                !hasUserInfoAccess &&
                <Typography>Guest</Typography>
            }
            <Stack
                direction={'row'}
                columnGap={'4px'}
                rowGap={'1px'}
                alignItems={'center'}
                flexWrap={'wrap'}
            >
              {
                  !!player.owner &&
                  <Typography variant={'caption'}>
                    {t('bookingAdd.booker')}
                  </Typography>}
              <Typography
                  variant={'caption'}
                  color={'text.secondary'} whiteSpace={'wrap'}>
                {player.user.email}
              </Typography>
            </Stack>
          </Stack>

          {/*Chip*/}
          <Stack
              direction={'row'}
              alignSelf={'flex-start'}
              spacing={1}
              marginLeft={'auto'}
          >
            {
                (+player?.price >= 0) &&
                <ChipPlayerPaymentStatus
                    chipStyle={'filled'}
                    variant={'player-price'}
                    size={'small'}
                    playerPrice={price}
                    paymentStatus={paymentStatus}
                />
            }

          </Stack>
        </Stack>
    );
  }
}

export default BookingsParticipantsDetails;

