import {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';
import {
  customersBookingCheckout_POST,
  customersBookingsCancel_POST,
} from '../../vendor/redux/actions/bookings';
import {LoadingButton} from '@mui/lab';
import {useTranslate} from '@tolgee/react';
import DialogConfirmation
  from '../../components/_Global/Dialogs/DialogConfirmation';
import {FormProvider, useForm} from 'react-hook-form';
import useCanDoActions from '../../hooks/bookings/useCanDoActions';
import useBooking from '../../hooks/bookings/useBooking';
import BookingsReservationDetails
  from '../../components/Bookings/Details/BookingsReservationDetails';
import useUser from '../../hooks/access/useUser';
import {
  BookingsNotifyButton,
} from '../../components/Bookings/Inputs/BookingNotifyButton';
import {ERR_NETWORK_ERROR} from '../../constants/errors';
import {bookingsTabEvents} from '../../utils/analyticsEvents';
import InvoicePreview from '../../components/Invoice/InvoicePreview';
import BookingDetailInvoice
  from '../../components/Invoice/Bookings/BookingDetailInvoice';
import {downloadPDF} from '../../utils/pdfExport';
import useExperienceContext
  from '../../hooks/clubExperience/useExperienceContext';
import useIsMobile from '../../hooks/ui/useIsMobile';

function DialogBookingsReservationDetail({
                                           root,
                                           open,
                                           onCancel = () => {
                                           },
                                         }) {

  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const {clubId: id} = useParams();
  const isMobile = useIsMobile();

  const user = useUser();

  const {state: locationState} = useLocation();
  const bookingId = locationState?.bookingId;
  const formMethods = useForm();
  const {watch} = formMethods;
  const watched = watch();
  const isPayingWithWallet = watched?.wallet;

  const {clubDetails} = useExperienceContext();

  const [booking, fetchBooking] = useBooking(bookingId);

  const [isDialogOpen, setIsDialogOpen] = useState(open);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const [isSubmittingCancelBooking, setIsSubmittingCancelBooking] = useState(
      false);

  const [canDoCancel, canDoPayment] = useCanDoActions(
      booking?.court.club.id,
      booking?.status,
      booking?.date,
      booking?.startTime,
      booking?.court.club.addresses[0].timezone,
      booking?.participants,
      booking?.paymentType,
  );

  const isPaymentReminder = user?.userObj?.id ===
      booking?.participants[0]?.userId && booking?.status === 'unpaid' &&
      booking?.paymentType === 'split' &&
      booking?.participants[0]?.paymentStatus === 'paid';

  const isLoading = !booking

  const clubBackground = clubDetails?.backgroundImage ?
      clubDetails?.backgroundImage :
      '/assets/club-bg-default.jpg';

  function handleProceedToPayment() {

    setIsSubmittingPayment(true);

    const loggedInUserId = user.userObj.id;
    const loggedInUserParticipant = booking?.participants?.find((el) => {
      return el.user.userId === loggedInUserId;
    });
    let domain = process.env.REACT_APP_URL;

    const userToken = loggedInUserParticipant.token;
    let redirectUrl;

    if (id) {
      redirectUrl = `${domain}/club/${id}/payments/status?token=${userToken}&origin=paymentGateway`;
    } else {
      redirectUrl = `${domain}/payments/status?token=${userToken}&origin=paymentGateway`;
    }

    dispatch(
        customersBookingCheckout_POST(
            booking.bookingId,
            redirectUrl,
            isPayingWithWallet || false,
            'reservation',
            cbSuccess,
            cbFail,
        ));

    function cbSuccess(res) {
      console.log('customersBookingCheckout_POST success', res);
      const {data: {data}} = res;
      if (data.type === 'checkoutUrl') {
        window.location.href = data.checkout_url;
      }
      if (data.type === 'paymentConfirmed') {
        if (id) {
          navigateTo(
              `/club/${id}/payments/status?token=${userToken}&origin=walletPayment`);
        } else {
          navigateTo(
              `/payments/status?token=${userToken}&origin=walletPayment`);
        }

      }
    }

    function cbFail(e) {
      console.log('customersBookingCheckout_POST Fail', e);
    }
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleCancelBtnClick() {
    bookingsTabEvents['clickedOnCancelBooking']();
    setIsCancelDialogOpen(true);
  }

  function handleCancelBooking() {

    bookingsTabEvents['confirmBookingCancellation']();

    setIsSubmittingCancelBooking(true);
    dispatch(customersBookingsCancel_POST(bookingId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('bookingsCancel_POST Success', res);
      const successMsg = res?.data?.data ||
          `Booking ID:${bookingId} cancelled successfully`;
      setIsSubmittingCancelBooking(false);
      setIsCancelDialogOpen(false);
      fetchBooking(locationState?.bookingId);
      enqueueSnackbar(successMsg, {variant: 'success'});
      setTimeout(() => {
        onCancel();
      }, 250);
    }

    function cbFail(e) {
      console.log('bookingsCancel_POST Fail', e);
      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err), {variant: 'error'});
      setIsSubmittingCancelBooking(false);
    }
  }

  function onDialogExited() {
    if (root === '/customers/customer-profile' || root ===
        '/customers/customer-bookings') {
      navigateTo(root, {state: {id: locationState.id, ...locationState}});
    } else {
      navigateTo(root, {state: {...locationState}});
    }
  }

  return (<>
    <FormProvider {...formMethods}>
      <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          fullScreen={isMobile}
          maxWidth={null}
          TransitionProps={{
            onExited: onDialogExited,
          }}
          scroll={isMobile ? 'paper' : 'body'}
          PaperProps={{
            ...(isMobile && {
              sx:{
                borderRadius: "0 !important",
              }
            }),
            ...(!isMobile && {
              sx: {
                background: `url(${clubBackground})`,
                backgroundSize: 'cover',
                pt: 4,
                boxShadow: 'none',
                '.MuiDialogContent-root': {
                  overflow: 'auto',
                },
              },
            }),
          }}
          sx={{
            paddingBottom: 0,
          }}
      >
        <DialogContent
            dividers={false}
            sx={{
              ...(isMobile && {
                px: 0,
              }),
            }}
        >
          <Stack
              sx={{
                ...(!isMobile && {
                  backgroundColor: 'white',
                  borderRadius: '16px',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  overflow: 'hidden',
                }),
              }}
          >
            <BookingsReservationDetails
                booking={booking}
                isLoading={isLoading}
                canDoPayment={canDoPayment}
                isPayingWithWallet={isPayingWithWallet}
                fnForWalletTopSuccess={() => fetchBooking(
                    locationState?.bookingId)}
            />
            <InvoicePreview receiptId={booking?.bookingId}>
              <BookingDetailInvoice
                  booking={booking}
                  billingData={booking?.billing}
                  isLoading={isLoading}
                  isPayingWithWallet={isPayingWithWallet}
                  participants={booking?.participants}
              />
            </InvoicePreview>
          </Stack>

        </DialogContent>
        <DialogActions sx={{
          ...(!isMobile && {
            pt: 0,
            px: '26px',
          }),
        }}>
          <Stack
              direction="row"
              justifyContent={'space-between'}
              width={'100%'}
              sx={{
                ...(!isMobile && {
                  px: 2,
                  py: 2,
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '16px',
                  borderBottomRightRadius: '16px',
                }),
              }}
          >
            <Button
                variant="outlined"
                size={'large'}
                color="inherit"
                onClick={handleCloseDialog}
                sx={{mr: 'auto'}}
            >
              {t('bookingAdd.buttonClose')}
            </Button>
            <Stack
                direction={'row'}
                justifyContent={canDoCancel?'space-between':'flex-end'}
                gap={1}
            >
              {
                  canDoCancel === true &&
                  <Button
                      variant="outlined"
                      color={'error'}
                      size={'large'}
                      onClick={handleCancelBtnClick}
                  >
                    {t('bookingAdd.buttonCancel')}
                  </Button>
              }
              <Button
                  variant="contained"
                  color="primary"
                  onClick={() => downloadPDF({fileName: 'Booking Details'})}
              >
                {t('bookingAdd.buttonDownloadRecipt')}
              </Button>
            </Stack>
            {
                isPaymentReminder &&
                <BookingsNotifyButton bookingId={booking?.bookingId}/>
            }
            {
                canDoPayment === true &&
                <LoadingButton
                    loading={isSubmittingPayment}
                    variant="contained"
                    size={'large'}
                    onClick={handleProceedToPayment}
                >
                      <span>
                        {isPayingWithWallet && booking?.wallet?.canPay ?
                            t('bookings.addEdit.actions.confirmBooking')
                            :
                            t('global.actions.completePayment')}
                      </span>
                </LoadingButton>
            }
          </Stack>
        </DialogActions>

      </Dialog>


    </FormProvider>

    <DialogConfirmation
        isSubmitting={isSubmittingCancelBooking}
        variant={'error'}
        dialogTitle={t('bookings.recurring.detail.cancel.confirm.title')}
        dialogContent={booking?.bookingType?.name === 'reservation' ?
            t('reservation.cancelMessage') :
            t('booking.cancelMessage')}
        confirmButtonLabel={'Cancel Booking'}
        handleConfirm={handleCancelBooking}
        isOpen={isCancelDialogOpen}
        handleClose={() => {
          setIsCancelDialogOpen(false);
        }}
    />
  </>);
}

// {
//   !isLoading &&
//   <BookingCreatedByDetails
//       booking={booking}
//   />
// }

export default DialogBookingsReservationDetail;

