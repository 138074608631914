import {useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Skeleton, Stack, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import useUser from "../../hooks/access/useUser";
import useIsMobile from "../../hooks/ui/useIsMobile";
import { clubTournaments_GET } from "../../vendor/redux/actions/customers";
import PageCard from "../_Global/Page/PageCard";
import PageCardTitle from "../_Global/Page/PageCardTitle";
import useBreakPoint from "../../hooks/ui/useBreakpoint";
import { useDispatch } from "react-redux";
import { useTranslate } from "@tolgee/react";
import TournamentListingCard from "../Tournaments/TournamentListingCard";
import IconTournamentFilled from "../../assets/icons/IconTournamentFilled";

const ClubInfoNewTournament = () => {
  const dispatch = useDispatch();

  const {t} = useTranslate();

  const navigateTo = useNavigate();
  const {clubId,companyId} = useParams();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const {isMdDown} = useBreakPoint();

  const [tournaments, setTournaments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
 
  function fetchAndSetTournaments() {
    setLoading(true);


    dispatch(
      clubTournaments_GET(
        clubId,
        2,
        0,
        '',
        '',
        '',
        cbSuccess,
        cbFail,
      )
    );

    function cbSuccess(res) {
      console.log("clubTournaments_GET Success", res);
      setTournaments(res.data.data.rows)
      setTotalCount(res.data.data?.totalCount)
      setLoading(false);
    }

    function cbFail(e) {
      console.log("clubTournaments_GET Fail", e);
      setTournaments([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAndSetTournaments();
  }, []);

  return (
    <Box className={'item'}>
        <PageCard
        isBlurVariant={true}
        >
          <PageCardTitle
              title={t('myActivity.tournaments.header.upcoming.title')}
              route={`/club/${clubId}/tournaments`}
              titleMeta={
                !loading && tournaments?.length !== 0 && (
                <Button
                    onClick={() => {
                      navigateTo(`/club/${clubId}/tournaments`);
                    }}
                    variant={'text'}
                >
                  {t('global.buttons.viewAll')} ({totalCount})
                </Button>
                )
              }
          />

          <Box sx={{
            // height: '480px',
            // ...(isMdDown && {
            //   height: 'auto',
            //   minHeight:'200px'
            // })
          }}>
            {
                  !loading && tournaments?.length === 0 &&
                  <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{textAlign:'center'}} rowGap={1}>
                  <IconTournamentFilled />
                  <Typography  variant="subtitle2" color={theme.palette.grey[600]} >
                  {t('tournament.list.empty')}
                  </Typography>
                  </Stack> 
              }
            <Grid container spacing={1}>
            {
               !loading && tournaments?.map(tournament => (
                    <Grid item md={6} sm={12}>
                    <TournamentListingCard tournament={tournament} />
                    </Grid>
                ))
            }
            </Grid>
          </Box>

        </PageCard>
      </Box>
  );
};
export default ClubInfoNewTournament;
