import {Skeleton, Stack, Typography} from '@mui/material';

function DialogSectionNew({
                            title,
                            titleMeta,
                            loading,
                            disabled,
                            children,
                          }) {

  return (
      <Stack
          spacing={1}
          sx={{
            ...(disabled) && {
              pointerEvents:"none",
              opacity: disabled ? 0.4 : 1
            }
          }}
      >
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}

        >
          <Typography variant={'h6-bold'}>
            {!loading && title}
            {loading && <Skeleton width={'120px'}/>}
          </Typography>
          {!loading && titleMeta}
        </Stack>
        {children}
      </Stack>
  );
}

export default DialogSectionNew;
