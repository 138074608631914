function IconClockAfternoon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0625 2.25C10.1712 2.25 8.32241 2.81083 6.74986 3.86157C5.17732 4.91231 3.95167 6.40577 3.22791 8.15309C2.50414 9.90041 2.31477 11.8231 2.68375 13.6781C3.05272 15.533 3.96346 17.2369 5.3008 18.5742C6.63814 19.9115 8.34201 20.8223 10.197 21.1913C12.0519 21.5602 13.9746 21.3709 15.7219 20.6471C17.4692 19.9233 18.9627 18.6977 20.0134 17.1251C21.0642 15.5526 21.625 13.7038 21.625 11.8125C21.622 9.27728 20.6136 6.84675 18.8209 5.05408C17.0283 3.26141 14.5977 2.25298 12.0625 2.25ZM12.0625 20.25C10.3937 20.25 8.76242 19.7551 7.37488 18.828C5.98734 17.9009 4.90588 16.5831 4.26727 15.0414C3.62866 13.4996 3.46157 11.8031 3.78713 10.1664C4.11269 8.52971 4.91629 7.02629 6.09629 5.84629C7.2763 4.66628 8.77972 3.86269 10.4164 3.53712C12.0531 3.21156 13.7496 3.37865 15.2914 4.01727C16.8331 4.65588 18.1509 5.73734 19.078 7.12488C20.0052 8.51242 20.5 10.1437 20.5 11.8125C20.4975 14.0495 19.6078 16.1942 18.026 17.776C16.4442 19.3578 14.2995 20.2475 12.0625 20.25ZM17.875 11.8125C17.875 11.9617 17.8157 12.1048 17.7103 12.2102C17.6048 12.3157 17.4617 12.375 17.3125 12.375H13.4209L16.21 15.165C16.2653 15.2165 16.3096 15.2786 16.3403 15.3476C16.3711 15.4166 16.3876 15.4911 16.3889 15.5666C16.3903 15.6421 16.3764 15.7172 16.3481 15.7872C16.3198 15.8572 16.2777 15.9209 16.2243 15.9743C16.1709 16.0277 16.1072 16.0698 16.0372 16.0981C15.9672 16.1264 15.8921 16.1403 15.8166 16.1389C15.7411 16.1376 15.6666 16.1211 15.5976 16.0903C15.5286 16.0596 15.4665 16.0153 15.415 15.96L11.665 12.21C11.5864 12.1313 11.5329 12.0312 11.5113 11.9221C11.4896 11.8131 11.5008 11.7 11.5433 11.5973C11.5858 11.4946 11.6578 11.4068 11.7503 11.345C11.8427 11.2831 11.9513 11.2501 12.0625 11.25H17.3125C17.4617 11.25 17.6048 11.3093 17.7103 11.4148C17.8157 11.5202 17.875 11.6633 17.875 11.8125Z"
        fill='currentColor'
        fill-opacity="0.87"
      />
    </svg>
  );
}
export default IconClockAfternoon;
