import {Skeleton, Stack, Typography} from '@mui/material';
import {getLocalizedNum} from '../../../utils/localization';
import useLocale from '../../../hooks/localization/useLocale';
import {useTranslate} from '@tolgee/react';
import useDefaults from '../../../hooks/defaults/useDefaults';

export function RefundDetails({refundMethod, refundAmount, isLoading}) {

  const {locale} = useLocale();
  const {currencySymbol} = useDefaults();
  const {t} = useTranslate();

  return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        {isLoading && <Skeleton width={'200px'} height={'30px'}/>}
        {isLoading && <Skeleton width={'100px'} height={'30px'}/>}
        {
            !isLoading &&
            <Typography display={'flex'} alignItems={'center'} variant="h6">
              {t('financials.table.header.refundedAmount')}
              <Typography
                  variant="h6"
                  sx={{
                    textTransform: 'capitalize',
                    ml: 0.5,
                  }}
              >
                ({refundMethod})
              </Typography>
            </Typography>
        }
        {
            !isLoading &&
            <Typography color={'rgba(76, 175, 80, 1)'} variant="h6">
              {`${currencySymbol} ${getLocalizedNum(
                locale, refundAmount)}`}
            </Typography>}
      </Stack>
  );
}
