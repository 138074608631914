import {Controller, useFormContext} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function DateOfBirth({disabled = false}) {

  const {control} = useFormContext();
  const {t} = useTranslate();
  return (
      <Controller
          name={'dateOfBirth'}
          control={control}
          rules={
              !disabled && {
                required: {
                  value: true,
                  message: t('register.fields.dateOfBirth.error'),
                },
                validate: {
                  isAboveSeventeen: value => {
                    if (value.isAfter(dayjs().subtract(17, 'year'))) {
                      return t('dob.rangeError');
                    } else {
                      return true;
                    }
                  },
                },
              }
          }
          render={({field, fieldState}) => {
            return <DatePicker
                {...field}
                disabled={disabled}
                fullWidth
                disableFuture
                maxDate={dayjs().subtract(17, 'year')}
                label={`${t('register.fields.dateOfBirth.label')}*`}
                format="MMM DD, YYYY"
                slotProps={{
                  textField: {
                    error: !!fieldState.error,
                    helperText: fieldState?.error?.message,
                    fullWidth: true,
                  },
                }}
            />;
          }}
      />
  );

}

export default DateOfBirth;
