export function containsNumbers(inputString) {
  // Use a regular expression to check if the string contains any numbers (0-9).
  return /\d/.test(inputString);
}


export function containsNonLetters(inputString) {
  // Use a regular expression to check if the string contains non-letter characters.
 return /[^a-zA-Z\s]/.test(inputString);
}

export function validateOnlyLettersAndSpaces(input) {
  // This regex matches Unicode letters (including accented and special characters) and spaces.
  const regex = /^[\p{L} ]+$/u;
  return regex.test(input);
}
