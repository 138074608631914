import {Box, Stack} from '@mui/material';
import PageCard from '../_Global/Page/PageCard';
import PageCardTitle from '../_Global/Page/PageCardTitle';
import React from 'react';
import {useTranslate} from '@tolgee/react';

import listImg from '../../assets/openMatches/open-match-coming-soon-item.png';

function ClubInfoOpenMatchesComingSoon() {

  const {t} = useTranslate();

  return (
      <Box className={'item'}>
        <PageCard isBlurVariant={true}>
          <PageCardTitle
              title={t('bookings.list.tabs.openMatches')}/>
          <Stack
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                // minHeight: '430px',
                position: 'relative',
              }}
          >
            <Stack
                spacing={2}
                width={'100%'}
                sx={{filter: 'blur(6px)'}}
            >
              {
                Array(4).fill(0).map((_, i) => {
                  return (
                      <Box
                          component={'img'}
                          src={listImg}
                          sx={{
                            zIndex: 0,
                            width: '100%',
                            height: 'auto',
                          }}
                      />
                  );
                })
              }
            </Stack>
            <Box
                sx={{
                  position: 'absolute',
                  background: 'linear-gradient(90deg, #583ADC 0%, #DB3B76 100%)',
                  borderRadius: '30px',
                  color: '#fff',
                  fontSize: '19px',
                  textTransform: 'uppercase',
                  p: 1,
                  px: 3,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                }}
            >
              Coming Soon
            </Box>
          </Stack>
        </PageCard>
      </Box>
  );
}

export default ClubInfoOpenMatchesComingSoon;
