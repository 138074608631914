export function padLeft(input, totalLength, padChar = ' ') {
  const inputStr = String(input); // Convert the input to a string
  if (inputStr.length >= totalLength) {
    return inputStr; // Return the original input if it's already long enough
  }
  const padding = padChar.repeat(totalLength - inputStr.length); // Create the padding
  return padding + inputStr; // Concatenate the padding with the input
}

export function capitalizeFirstLetter(str) {
  if (!str || typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}
