import {Chip, Stack, Typography} from '@mui/material';
import useLocale from '../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../utils/localization';
import {getHumanizedDuration} from '../../utils/packages';
import {useTranslate} from '@tolgee/react';
import useDefaults from '../../hooks/defaults/useDefaults';

function PackageDurationDisplay({startDate, endDate, duration, price, paymentType, color='success' || 'error' }) {

  const {locale} = useLocale();
  const {t} = useTranslate();
  const {currencySymbol} = useDefaults();
  const isFree = paymentType ===  'free';
  let durationLabel;
  let priceLabel;

  if (startDate && endDate) {
    const startT = dayjs(startDate).locale(locale).format('ll');
    const endT = dayjs(endDate).locale(locale).format('ll');
    durationLabel = `${startT} - ${endT}`;
  }

  if (duration && +duration > 0 ) {
    durationLabel = `${getHumanizedDuration(duration, t)}`;
  }

  if (isFree){
    priceLabel = 'free'
  }else {
    priceLabel = `${currencySymbol} ${getLocalizedNum(locale,
        +price)}`
  }

  return (
      <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
      >
        <Typography textTransform={'capitalize'}>
          {durationLabel}
        </Typography>
        <Chip
            color={color}
            variant={'filled'}
            label={priceLabel}
        />
      </Stack>
  );
}

export default PackageDurationDisplay;
