import {Typography} from '@mui/material';
import React from 'react';

function InlineTextList({
                          items = [],
                          fontSize = '16px',
                          fontWeight = 600,
                          color='#0000008F',
                        }) {

  return (
      <Typography
          sx={{
            fontSize,
            fontWeight,
            color,
            'span': {
              display: 'inline',
              '&:not(:last-of-type):after': {
                content: `""`,
                display: 'inline-block',
                width: '4px',
                height: '4px',
                backgroundColor: color,
                borderRadius: '50%',
                verticalAlign: 'middle',
                marginLeft: '8px',
                marginRight: '8px',
              },
            },
          }}>
        {
          items?.map((item, index) => {
            return <span key={index}>{item}</span>;
          })
        }
      </Typography>
  );
}

export default InlineTextList;
