import {Chip, Tooltip} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import useLocale from '../../../hooks/localization/useLocale';
import {getLocalizedNum} from '../../../utils/localization';
import useDefaults from '../../../hooks/defaults/useDefaults';

function ChipPlayerPaymentStatus({
                                   paymentStatus,
                                   playerPrice,
                                   size = 'small' || 'medium',
                                   chipStyle = 'soft' || 'filled' || 'outlined',
                                   variant = 'player-status' || 'player-price',
                                 }) {

  const {t} = useTranslate();
  const {locale} = useLocale();
  const {currencySymbol} = useDefaults();

  let color;
  let label = '';
  let tooltipMsg = '';

  if (paymentStatus === 'paid') {
    color = 'success';
  }

  if (paymentStatus === 'unpaid') {
    color = 'error';
  }

  if (paymentStatus === 'refunded' || paymentStatus === 'refund-initiated') {
    color = 'default';
  }

  if (variant === 'player-status') {
    if (paymentStatus === 'paid') {
      label = t('global.chips.label.paid');
    }
    if (paymentStatus === 'unpaid') {
      label = t('global.types.status.unpaid');
    }
    if (paymentStatus === 'refunded') {
      label = t('global.chips.label.refunded');
    }
    if (paymentStatus === 'refund-initiated') {
      label = t('global.chips.label.refundInitiated');
    }
  }

  if (variant === 'player-price') {
    if (paymentStatus === 'paid') {
      label = `${currencySymbol} ${getLocalizedNum(locale,
          +playerPrice)}`;
      tooltipMsg = t('global.chips.label.paid');
    }
    if (paymentStatus === 'unpaid') {
      label = `${currencySymbol} ${getLocalizedNum(locale,
          +playerPrice)}`;
      tooltipMsg = t('global.types.status.unpaid');
    }
    if (paymentStatus === 'refunded') {
      label = t('global.chips.label.refunded');
    }
    if (paymentStatus === 'refund-initiated') {
      label = t('global.chips.label.refundInitiated');
    }

  }

  return (
      <Tooltip title={!!tooltipMsg ? tooltipMsg : ''}
               placement={'top'}
               arrow>
        <Chip
            variant={chipStyle}
            size={size}
            label={label}
            color={color}
        />
      </Tooltip>
  );
}

export default ChipPlayerPaymentStatus;


