import {Help} from '@mui/icons-material';
import {Button} from '@mui/material';
import React, {useState} from 'react';
import {useTranslate} from '@tolgee/react';
import UserProfileRatingGuideDialog from './UserProfileRatingGuideDialog';

function UserProfileRatingGuideButton({btnColor='primary'}) {

  const {t} = useTranslate();

  const [showRatingGuide, setShowRatingGuide] = useState(false);

  return (
      <>
        <Button
            size={'small'}
            startIcon={<Help/>}
            sx={{
              px: 1.5,
              position: 'relative',
              top: 2,
              "& .MuiButton-startIcon": {
                marginRight: "4px",
              },
            }}
            color={btnColor}
            onClick={()=>{
              setShowRatingGuide(true)
            }}
        >
          {t('userProfile.ratingGuide.button')}
        </Button>
        <UserProfileRatingGuideDialog
            open={showRatingGuide}
            setOpen={setShowRatingGuide}
        />
      </>

  );
}

export default UserProfileRatingGuideButton;
