import {LocationOn, PersonOutlineOutlined} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {getLocalizedNum} from '../../utils/localization';
import useLocale from '../../hooks/localization/useLocale';
import TrainingTimeSection from './TrainingTimeSection';
import {useNavigate} from 'react-router-dom';
import useDefaults from '../../hooks/defaults/useDefaults';
import useIsMobile from '../../hooks/ui/useIsMobile';

const TrainingListingCard = ({experience, training, isBackground = false}) => {

  const theme = useTheme();
  const {t} = useTranslate();
  const {locale} = useLocale();
  const isMobile = useIsMobile();
  const {currencySymbol} = useDefaults();
  const navigateTo = useNavigate();

  const title = training?.name;
  const trainerFirstName = training?.trainerFirstName ?? '';
  const trainerLastName = training?.trainerLastName ?? '';
  const price = training?.participantPrice;
  const slotsLeft = training?.maxParticipants - training?.participantCount;
  const isAvailable = training?.maxParticipants > training?.participantCount;


  function handleClickEventItem() {
    const queryParams = new URLSearchParams({
      trainingId: training?.id,
      type: 'active',
      name: training.name,
    }).toString();

    navigateTo(
        `/club/${training?.clubId}/trainings/training-detail?${queryParams}`);

  }

  return (
      <Card
          variant="outlined"
          sx={{borderRadius: 2, cursor: 'pointer'}}
          onClick={handleClickEventItem}
      >
        <Stack
            direction="column"
            justifyContent="space-between"
            className={isBackground ? 'bg-gradiant-color' : ''}
            columnGap={2}
            sx={{
              background: theme.palette.grey[200],

              padding: '20px 20px 12px 20px',
            }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack spacing={1}>
              <Typography
                  variant="h6-bold"
                  fontSize={{
                    xs: '18px',
                    md: '20px',
                  }}
                  lineHeight={1.2}
              >
                {title}
              </Typography>
              <Tooltip
                  title={'Trainer'}
                  placement="right"
                  arrow
              >
                {
                  experience === 'COMPANY' ?
                    <Stack direction="row" spacing={1} alignItems="center">
                      <LocationOn color={isBackground ? 'white' : 'disabled'}/>
                      <Typography variant="body2"
                                  textTransform={'capitalize'}>{training?.clubName}</Typography>
                    </Stack> : trainerFirstName ?
                        <Stack direction="row" spacing={1} alignItems="center">
                          <PersonOutlineOutlined
                              sx={{color: isBackground ? 'white' : 'disabled'}}/>
                          <Typography variant="body2" sx={{
                            color: isBackground ?
                                'white' :
                                'disabled',
                          }}
                                      textTransform={'capitalize'}>{`${trainerFirstName} ${trainerLastName}`}</Typography>
                        </Stack>
                        : ''
                }
              </Tooltip>
            </Stack>
            <Stack alignItems={'center'} justifyContent={'center'} spacing={1}>
              <Chip
                  size="small"
                  variant="contained"
                  color={isAvailable ? 'success' : 'error'}
                  sx={{
                    borderRadius: 1,
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    minHeight: '32px',
                  }}
                  label={isAvailable ?
                      `${currencySymbol} ${getLocalizedNum(locale, price)}` :
                      t('eventDetail.soldButton')}
              />
              {
                  isAvailable &&
                  <Box sx={{
                    textAlign: 'center',
                  }}>
                    <Typography variant="body1" whiteSpace={'nowrap'}>
                      {`${slotsLeft} ${t('training.slotLeft')}`}
                    </Typography>
                  </Box>
              }
            </Stack>

          </Stack>



        </Stack>
        <CardContent>
          <TrainingTimeSection
              rowGap={3}
              fw={400}
              startDate={training?.startDate}
              startTime={training?.startTime}
              endDate={training?.endDate}
              endTime={training?.endTime}
              recurrence={training?.recurrType}
          />
        </CardContent>
      </Card>
  );
};

export default TrainingListingCard;
