import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {useSnackbar} from 'notistack';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
  useSlotSearchV2Context,
} from '../../components/SlotSearchV2/ProviderSlotSearchV2';
import moment from 'moment-timezone';
import useExperienceContext
  from '../../hooks/clubExperience/useExperienceContext';
import useUser from '../../hooks/access/useUser';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import {
  getExtrasArrayInApiFormat,
  getUsersArrayInApiFormat,
} from '../../utils/bookings';
import {useDispatch} from 'react-redux';
import DialogBookingsAddV2Content from './DialogBookingAddV2Content';
import {LoadingButton} from '@mui/lab';
import {
  customersBookingCheckout_POST,
  customersBookingsCreate_POST,
} from '../../vendor/redux/actions/bookings';
import {bookingEvents} from '../../utils/analyticsEvents';
import {ERR_NETWORK_ERROR} from '../../constants/errors';
import useIsMobile from '../../hooks/ui/useIsMobile';
import {TransitionSlide} from '../../vendor/mui/transitions';
import useDefaults from '../../hooks/defaults/useDefaults';
import useLocale from '../../hooks/localization/useLocale';
import { getLocalizedNum } from '../../utils/localization';
import { roundToTwoDecimal } from '../../utils/math';

const DialogBookingsAddV2 = ({
                               open,
                               root,
                               onExit = () => {
                               },
                             }) => {

  const dispatch = useDispatch();
  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const isSubmitting = searchParams.get('isSubmitting');

  const navigateTo = useNavigate();
  const location = useLocation();
  const {currencySymbol} = useDefaults();
  const {locale} = useLocale();
  let locationState = location?.state;

  const user = useUser();
  const userObj = user?.userObj;
  const userId = userObj?.id;
  const isAuthenticated = useIsAuthenticated();

  const isMobile = useIsMobile();

  const {
    selectedSlot,
    selectedCourt,
    setSelectedCourt,
  } = useSlotSearchV2Context();

  const [isDialogOpen, setIsDialogOpen] = useState(open);
  const [isCourtNotAvailable, setIsCourtNotAvailable] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [priceData, setPriceData] = useState(null);
  const selectedUserData = priceData?.users?.find(usr => usr?.userId === userId);
  const price =  priceData?.wallet?.isPartial ?
                  +selectedUserData?.price - +priceData?.wallet?.amount :
                  selectedUserData?.price;

  const isCourtSelected = Object.keys(selectedCourt)?.length > 0;

  const slotCourts = selectedSlot.slots;

  const {clubDetails} = useExperienceContext();
  const clubTimezone = clubDetails?.addresses[0].timezone;

  const clubBackground = clubDetails?.backgroundImage ?
      clubDetails?.backgroundImage :
      '/assets/club-bg-default.jpg';

  const {
    clubId,
    courtId,
    courtName,
    courtSize,
    date,
  } = selectedCourt;

  const startDate = moment.tz(date, clubTimezone).format('YYYY-MM-DD');

  // override location state, at time of create as we don't have any locationState initally.
  locationState = locationState ??
      {...selectedCourt, courts: slotCourts, startDate};

  let defaultPlayers = [];

  if (isAuthenticated) {
    const {id, email, cellNumber, firstName, lastName, imageUrl} =
        user?.userObj;

    defaultPlayers.push({
      id,
      firstName,
      lastName,
      imageUrl,
      email,
      cellNumber,
      isOrganizer: true,
      isExistingCustomer: true,
      isGuest: false,
    });
  }

  let defaultValues = useMemo(() => {
    return {
      bookingType:
          locationState?.bookingType ??
          JSON.stringify({
            bookingTypeId: '1',
            name: 'reservation',
          }),
      court: locationState?.court ?? {
        clubId: clubId,
        id: courtId,
        name: courtName,
        size: courtSize,
      },
      startDate: locationState?.startDate,
      startTime: locationState?.startTime,
      endTime: locationState?.endTime,
      paymentType: locationState?.paymentType ?? 'single',
      extras: locationState?.extras ?? [],
      players: defaultPlayers,
    };
  }, [selectedCourt]);

  const formMethods = useForm({
    defaultValues: defaultValues,
  });

  const {watch, reset: resetForm, handleSubmit} = formMethods;

  const watched = watch();
  const isPayingWithWallet = watched?.wallet;
  const isConfirm = isPayingWithWallet && priceData?.wallet?.canPay &&
      !priceData?.wallet?.isPartial;

  function handleFormSubmit() {
    handleSubmit(handleFormSubmitOnValid, handleFromSubmitOnError)();
  }

  function handleFormSubmitOnValid(data) {

    setIsSubmittingForm(true);

    setSearchParams({ // to check form submission state, update this logic later
      isSubmitting: true, // also responsible to avoid socket call for court check after submit
    });

    const body = {
      courtBooking: {
        ...(isPayingWithWallet && {wallet: true}),
        courtId: parseInt(data.court.id),
        paymentType: data.paymentType,
        date: data.startDate,
        startTime: data.startTime,
        endTime: data.endTime,
        title: 'No Title',
      },
      extras: getExtrasArrayInApiFormat(data.extras),
      user: getUsersArrayInApiFormat(data.players, clubId),
    };

    console.log('customersBookingsCreate Body', body);

    return dispatch(customersBookingsCreate_POST(body, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersBookingsCreate_POST Success', res);
      const {data: {data: {data}}} = res;

      const isBookingConfirmed = data.isBookingConfirmed;
      const paidVia = data.paidVia;
      const booker = data.userArr.find((user) => user.owner);
      let bookerToken = booker.token;

      bookingEvents['bookingSuccess']();

      if (isBookingConfirmed) {
        let origin;
        if (paidVia === 'Bank') {
          origin = 'paymentGateway';
        } else {
          origin = 'walletPayment';
        }
        //Booking paid with wallet or 0 price booking due to discount
        enqueueSnackbar(t('global.alerts.payment.success'),
            {variant: 'success'});
        if (clubId) {
          navigateTo(
              `/club/${clubId}/payments/status/${bookerToken}?origin=${origin}`);
        } else {
          navigateTo(
              `/payments/status?token=${bookerToken}&origin=${origin}`);
        }
        setIsSubmittingForm(false);
      } else {
        //unpaid go to mollie
        const bookingId = data.booking.id;
        handlePaymentCheckout(bookingId, bookerToken, isAuthenticated,
            isBookingConfirmed);
      }

    }

    function cbFail(e) {
      console.log('customersBookingsCreate_POST Fail', e);
      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err), {variant: 'error'});
      bookingEvents['bookingFailed']();
      //keep existing values when resetting form state
      resetForm(null, {
        keepDefaultValues: true,
        keepValues: true,
      });
      setIsSubmittingForm(false);
      handleCloseDialog();
    }
  }

  function handlePaymentCheckout(
      bookingId, userBookingToken, IS_AUTHENTICATED, isBookingConfirmed) {
    setIsSubmittingForm(true);

    let redirectUrl;
    let domain = process.env.REACT_APP_URL;

    if (clubId) {
      redirectUrl = `${domain}/club/${clubId}/payments/status/${userBookingToken}?origin=paymentGateway`;
    } else if (!IS_AUTHENTICATED) {
      redirectUrl = `${domain}/payments/status/${userBookingToken}?origin=paymentGateway`;
    } else {
      redirectUrl = `${domain}/payments/status?token=${userBookingToken}&origin=paymentGateway`;
    }

    return dispatch(
        customersBookingCheckout_POST(bookingId, redirectUrl,
            (isPayingWithWallet && isBookingConfirmed) || false, 'reservation',
            cbSuccess,
            cbFail));

    function cbSuccess(res) {
      console.log('customersBookingCheckout_POST Success', res);
      const {data: {data: {checkout_url}}} = res;
      window.location.href = checkout_url;
    }

    function cbFail(e) {
      console.log('customersBookingCheckout_POST Fail', e);
      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err), {variant: 'error'});
      handleCloseDialog();
      setIsSubmittingForm(false);
    }
  }

  function handleFromSubmitOnError(errors) {
    enqueueSnackbar(t('fields.fillError'), {variant: 'error'});
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
    setSelectedCourt({});
  }

  function handleDialogAfterExit() {
    navigateTo(root);
    onExit();
  }

  useEffect(() => {
    if (locationState?.selectedCourt && !isSubmitting) {
      setSelectedCourt(locationState?.selectedCourt);
    }
  }, [locationState?.selectedCourt]);

  const BookingAddV2DialogActions = () => {
    return (
        <Stack
            width={'100%'}
            direction={'row'}
            justifyContent={'space-between'}
        >
          <Button
              variant="outlined"
              size={'large'}
              onClick={handleCloseDialog}
              sx={{
                ...(!isMobile && {
                  color: '#fff',
                  borderColor: '#fff',
                }),
              }}
          >
            {t('bookingAdd.buttonClose')}
          </Button>
          <LoadingButton
              variant="contained"
              size={'large'}
              disabled={isSubmittingForm || isCourtNotAvailable ||
                  !isCourtSelected}
              loading={isSubmittingForm}
              onClick={handleFormSubmit}
          >
            {isConfirm
            ? `${t('bookings.addEdit.actions.confirmBooking')}`
            : `${t('global.buttons.actions.proceedToPayment')} (${currencySymbol}${getLocalizedNum(locale, roundToTwoDecimal( price))})`
              }
          </LoadingButton>
        </Stack>
    );
  };

  return (
      <FormProvider {...formMethods}>
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            fullScreen={isMobile}
            fullWidth
            maxWidth={isMobile ? false : 'xl'}
            TransitionComponent={TransitionSlide}
            TransitionProps={{
              onExited: handleDialogAfterExit,
            }}
            scroll={isMobile ? 'paper' : 'body'}
            slotProps={{
              backdrop: {
                sx: {
                  backdropFilter: 'blur(15px)',
                },
              },
            }}
            PaperProps={{
              sx: {
                background: `url(${clubBackground})`,
                // px:0,
                backgroundSize: 'cover',
                pt: 4,
                boxShadow: 'none',
                '.MuiDialogContent-root': {
                  overflow: 'auto',
                },
                ...(isMobile && {
                  borderRadius: '0 !important',
                }),
              },
            }}
            sx={{
              paddingBottom: 0,
            }}
        >
          <DialogContent
              dividers={false}
              sx={{
                ...(isMobile && {
                  px: 2,
                }),
              }}
          >
            <DialogBookingsAddV2Content
                isCourtSelected={isCourtSelected}
                locationState={locationState}
                selectedCourt={selectedCourt}
                startDate={locationState?.startDate ?? startDate}
                setIsDialogOpen={setIsDialogOpen}
                isCourtNotAvailable={isCourtNotAvailable}
                setIsCourtNotAvailable={setIsCourtNotAvailable}
                priceData={priceData}
                setPriceData={setPriceData}
            />
          </DialogContent>
          <DialogActions
              sx={{
                ...(isMobile && {
                  backgroundColor: '#fff',
                  borderTopRightRadius: '16px !important',
                  borderTopLeftRadius: '16px !important',
                  zIndex: 9999,
                  boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
                  // '-webkit-box-shadow': '-webkit-box-shadow',
                }),
              }}
          >
            <BookingAddV2DialogActions/>
          </DialogActions>
        </Dialog>
      </FormProvider>
  );
};
export default DialogBookingsAddV2;
