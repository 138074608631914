import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import LayoutClubPage from './LayoutClubPage';
import {Suspense, useEffect} from 'react';
import SiteFooter from '../../components/_Global/Site/SiteFooter';
import useNavItems from './Nav/useNavItems';
import useExperienceContext
  from '../../hooks/clubExperience/useExperienceContext';
import LayoutCLubHeaderV3 from './LayoutCLubHeaderNew/LayoutCLubHeaderV3';

function LayoutClubNew({showNav = true, logoNavigate = true}) {

  const navItems = useNavItems();
  const {clubDetails} = useExperienceContext();

  //Set background image
  useEffect(() => {

    let bg;

    bg = clubDetails?.backgroundImage ?
        clubDetails?.backgroundImage :
        '/assets/club-bg-default.jpg';

    let style = document.createElement('style');

    style.innerHTML = `
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: url('${bg}');
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
    }
  `;

    document.head.appendChild(style);

  }, []);

  return (
      <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
      >
        <LayoutCLubHeaderV3 navItems={navItems} showNav={showNav} logoNavigate={logoNavigate} />
        <Box
            component={'main'}
            sx={{
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
        >
          <Suspense fallback={<h1></h1>}>
            <LayoutClubPage>
              <Outlet/>
            </LayoutClubPage>
            <SiteFooter container={'fixed'}/>
          </Suspense>
        </Box>

      </Box>
  );
}

export default LayoutClubNew;
