import {Box} from '@mui/material';
import ContainerFixed from '../../components/_Global/Containers/ContainerFixed';
import {useLayoutContext} from '../../providers/ProviderLayout';

function LayoutClubPage({children}) {

  const {hasFixedCTA} = useLayoutContext();

  return (
      <Box
          sx={{
            pb: 3,
            ...(hasFixedCTA && {
              paddingBottom: '240px',
            }),
          }}>
        <ContainerFixed>
          {children}
        </ContainerFixed>
      </Box>
  );
}

export default LayoutClubPage;
