import {alpha, darken} from '@mui/material';
import {grey} from '@mui/material/colors';

const GREY = {
  0: '#FFFFFF',
  50: '#F9FAFB',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export const PALETTE = {
  ...COMMON,
  primary: {
    main: '#2196F3',
    dark: '#1874D0',
    light: '#79D0FB',
  },
  secondary: {
    main: '#193291',
    dark: '#0C1B68',
    light: '#D0DCF9',
  },
  error: {
    main: '#FF6066',
    dark: '#931E42',
    light: '#FFCDBF',
  },
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#FFFFFF',
    default: '#EEF0F6',
    neutral: "#EEF0F6",
  },
  action: {
    ...COMMON.action,
    active: GREY[600],
    disabledBackground: grey[100],
  },
  icon: {
    primary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.3)',
  },
};
