import {useEffect, useMemo, useState} from 'react';
import {Alert, AlertTitle, Box, Button, Skeleton,Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import BookingAddEditBilling
  from '../../components/Bookings/Details/BookingAddEditBilling';
import DialogSection from '../../components/_Global/Dialogs/DialogSection';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import {
  AccountBalanceWallet,
  LocationOn,
  PeopleAltOutlined,
  ReceiptLongOutlined,
  SportsBaseballOutlined,
} from '@mui/icons-material';
import PaymentIcon from '@mui/icons-material/Payment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookingsCourtDetails
  from '../../components/Bookings/Details/BookingsCourtDetails';
import {
  customersClubsExtrasList_GET,
} from '../../vendor/redux/actions/clubs-extras';
import BookingsClubDetails
  from '../../components/Bookings/Details/BookingsClubDetails';
import {
  customersBookingCheckout_POST,
  customersBookingsAvailableCourts_POST,
  customersBookingsCreate_POST,
} from '../../vendor/redux/actions/bookings';
import {LoadingButton} from '@mui/lab';
import BookingsDateTimeDetails
  from '../../components/Bookings/Details/BookingsDateTimeDetails';
import {
  getCourtBookingLength,
  getExtrasArrayInApiFormat,
  getUsersArrayInApiFormat,
  setFormDefaultStates,
} from '../../utils/bookings';
import PlayersSelect from '../../components/Bookings/Inputs/PlayersSelect';
import moment from 'moment-timezone';
import {
  PaymentTypeSelect,
} from '../../components/Bookings/Inputs/PaymentTypeSelect';
import ExtrasSelect from '../../components/Bookings/Inputs/ExtrasSelect';
import genUid from 'light-uid';
import WalletSelect from '../../components/_Global/Wallet/WalletSelect';
import useSocket from '../../hooks/sockets/useSocket';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import useUser from '../../hooks/access/useUser';
import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import usePolicies from '../../hooks/policies/usePolicies';
import useWalletSelf from '../../hooks/wallets/useWalletSelf';
import {ERR_NETWORK_ERROR} from '../../constants/errors';
import { bookingEvents } from '../../utils/analyticsEvents';
import WalletAlerts from '../../components/Bookings/WalletAlerts';
import usePersistPageData from '../../hooks/access/usePersistPageData';
import useDefaults from '../../hooks/defaults/useDefaults';
import useLocale from '../../hooks/localization/useLocale';
import { getLocalizedNum } from '../../utils/localization';

const socketUUID = genUid();

function DialogBookingsAdd({
                             open,
                             root,
                             onExit = () => {
  },
                           }) {

  const location = useLocation();
  const locationState = location?.state;

  const {
    clubId,
    clubName,
    clubCity,
    clubStreet,
    clubHouseNumber,
    clubPostalCode,
    courtId,
    courtName,
    courtSize,
    courtSport,
    courtType,
    courtFeature,
    startDate,
    startTime,
    endTime,
    // discountApplied,
  } = locationState || {};

  const {socket} = useSocket();

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const theme = useTheme();
  const {currencySymbol} = useDefaults()
  const { locale } = useLocale();

  const {clubId: id} = useParams();
  const isAuthenticated = useIsAuthenticated();
  const user = useUser();
  const policies = usePolicies();
  let defaultPlayers = [];

  if (isAuthenticated) {
    const {
      id,
      email,
      cellNumber,
      firstName,
      lastName,
      imageUrl,
    } = user?.userObj;

    defaultPlayers.push(
        {
          id,
          firstName,
          lastName,
          imageUrl,
          email,
          cellNumber,
          isOrganizer: true,
          isExistingCustomer: true,
          isGuest: false,
        },
    );
  }

  // location state used to set(persist) default values in case
  // if we move away from add booking screen
  let defaultValues = {
    bookingType: locationState?.bookingType ?? JSON.stringify({
      bookingTypeId: '1', name: 'reservation',
    }),
    court: locationState?.court ?? {
      clubId: parseInt(clubId),
      id: parseInt(courtId),
      name: courtName,
      size: courtSize,
    },
    startDate: locationState?.startDate ?? startDate,
    startTime: locationState?.startTime ?? startTime,
    endTime: locationState?.endTime ?? endTime,
    paymentType: locationState?.paymentType ?? 'single',
    extras: locationState?.extras ??  [],
    players: defaultPlayers,
  };

  const formMethods = useForm({
    defaultValues: defaultValues,
  });

  const {
    formState,
    watch,
    reset: resetForm,
    setValue,
    getValues,
    handleSubmit,
  } = formMethods;

  const {isSubmitting: isSubmittingForm} = formState;

  const watched = watch();
  const watchedPaymentType = watched?.paymentType;
  const watchedPlayers = watched?.players || [];
  const watchedExtras = watched?.extras || [];

  const players = useMemo(() => {
    return watchedPlayers?.map((player) => {
      return {
        userId: player.id,
        email: player.email,
        owner: player.isOrganizer,
      };
    });
  }, [watchedPlayers]);
  const {walletSelf,fetchWalletSelf} = useWalletSelf(id);

  let walletData;

  if (walletSelf !== 'loading') {
    if (walletSelf) {
      walletData = walletSelf;
    }
  }

  const [isDialogOpen, setIsDialogOpen] = useState(open);
  const [isLoadingExtras, setIsLoadingExtras] = useState(true);
  const [isSettingFormStates, setIsSettingFormStates] = useState(false);
  const [extras, setExtras] = useState([]);
  const [priceData, setPriceData] = useState(null);
  const [isCourtNotAvailable, setIsCourtNotAvailable] = useState(false);
  const [isCourtNoPrice, setIsCourtNoPrice] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingSocketPrice, setIsLoadingSocketPrice] = useState(false);
  const [booker,setBooker] = useState({});

  let isLoading = isLoadingExtras || isSettingFormStates || !policies || !priceData || walletSelf ===
      'loading';

  // const bookerDiscountApplied = priceData?.users?.find((user)=> user.owner)?.discountApplied;
  const bookerDiscountApplied = priceData?.users?.find((user)=> user.owner)?.hasApplicablePackage;
  const isPayingWithWallet = watched?.wallet;
  const isConfirm = isPayingWithWallet && priceData?.wallet?.canPay && !priceData?.wallet?.isPartial;
  const keyToPersistState = 'add-booking-form';

  console.log("price data", priceData);

  function handleFormSubmit() {
    handleSubmit(handleFormSubmitOnValid, handleFromSubmitOnError)();
  }

  function handleFormSubmitOnValid(data) {

    const body = {
      courtBooking: {
        ...(isPayingWithWallet && {wallet: true}),
        courtId: data.court.id,
        paymentType: data.paymentType,
        date: data.startDate,
        startTime: data.startTime,
        endTime: data.endTime,
        title: 'No Title',
      },
      extras: getExtrasArrayInApiFormat(data.extras),
      user: getUsersArrayInApiFormat(data.players, clubId),
    };

    console.log('customersBookingsCreate Body', body);

    return dispatch(customersBookingsCreate_POST(body, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersBookingsCreate_POST Success', res);
      const {data: {data: {data}}} = res;

      const isBookingConfirmed = data.isBookingConfirmed;
      const paidVia = data.paidVia;
      const booker = data.userArr.find((user) => user.owner);
      let bookerToken = booker.token;

      bookingEvents['bookingSuccess']();

      if (isBookingConfirmed) {
        let origin;
        if (paidVia === 'Bank') {
          origin = 'paymentGateway';
        } else {
          origin = 'walletPayment';
        }
        //Booking paid with wallet or 0 price booking due to discount
        enqueueSnackbar(t('global.alerts.payment.success'),
            {variant: 'success'});
        if (id) {
          navigateTo(
              `/club/${id}/payments/status/${bookerToken}?origin=${origin}`);
        } else {
          navigateTo(
              `/payments/status?token=${bookerToken}&origin=${origin}`);
        }
      } else {
        //unpaid go to mollie
        const bookingId = data.booking.id;
        return handlePaymentCheckout(bookingId, bookerToken, isAuthenticated, isBookingConfirmed);
      }

    }

    function cbFail(e) {
      console.log('customersBookingsCreate_POST Fail', e);
      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err), {variant: 'error'});
      bookingEvents['bookingFailed']();
      //keep existing values when resetting form state
      resetForm(null, {
        keepDefaultValues: true,
        keepValues: true,
      });
      setIsSubmitting(false);
      handleCloseDialog();
    }
  }

  function handlePaymentCheckout(
      bookingId, userBookingToken, IS_AUTHENTICATED, isBookingConfirmed) {
    setIsSubmitting(true);
    let redirectUrl;
    let domain = process.env.REACT_APP_URL;

    if (id) {
      redirectUrl = `${domain}/club/${id}/payments/status/${userBookingToken}?origin=paymentGateway`;
    } else if (!IS_AUTHENTICATED) {
      redirectUrl = `${domain}/payments/status/${userBookingToken}?origin=paymentGateway`;
    } else {
      redirectUrl = `${domain}/payments/status?token=${userBookingToken}&origin=paymentGateway`;
    }

    return dispatch(
        customersBookingCheckout_POST(bookingId, redirectUrl,
            (isPayingWithWallet && isBookingConfirmed) || false, 'reservation', cbSuccess,
            cbFail));

    function cbSuccess(res) {
      console.log('customersBookingCheckout_POST Success', res);
      const {data: {data: {checkout_url}}} = res;
      window.location.href = checkout_url;
    }

    function cbFail(e) {
      console.log('customersBookingCheckout_POST Fail', e);
      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err), {variant: 'error'});
      handleCloseDialog();
      setIsSubmitting(false);
    }
  }

  function handleFromSubmitOnError(errors) {
    enqueueSnackbar(t('fields.fillError'), {variant: 'error'});
  }

  function fetchExtras() {

    dispatch(customersClubsExtrasList_GET(clubId, courtSport, cbSuccess, cbFail));

    function cbSuccess({data: {data}}) {
      console.log('customersClubsExtrasList_GET Success', data);
      setExtras(data);
      setIsLoadingExtras(false);
    }

    function cbFail(e) {
      console.log('customersClubsExtrasList_GET fail', e);
    }

  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleDialogAfterExit() {
    navigateTo(root, {state: {...locationState}});
    onExit();
  }

  function courtAvailableCheck() {
    const body = {
      date: watched.startDate,
      startTime: watched.startTime,
      endTime: watched.endTime,
      clubId: parseInt(clubId),
    };
    console.log('bookingsAvailableCourts_POST Request Body', body);
    dispatch(customersBookingsAvailableCourts_POST(body, cbSuccess, cbFail));

    function cbSuccess({data: {data}}) {
      console.log('bookingsAvailableCourts_POST Success', data);
      if (data.length === 0) {
        console.log('No available courts, block add booking');
        setIsCourtNotAvailable(true);
      }
      if (data.length > 0) {
        const selectedCourt = formMethods.getValues('court');
        const courtExists = data.find((court) => {
          return +court.id === +selectedCourt.id;
        });
        if (!courtExists) {
          console.log(
              'selected court does not exist in available courts, block add booking');
          setIsCourtNotAvailable(true);
        }
      }
    }

    function cbFail(e) {
      console.log('bookingsAvailableCourts_POST fail', e);
      // setIsDisabled(true);
    }

  }

  function emitSocketCalculatePrice() {
    setIsLoadingSocketPrice(true);
    const body = {
      version: 2,
      type: 'courtBooking',
      courtBooking: {
        ...(isPayingWithWallet && {wallet: true}),
        date: getValues('startDate'),
        startTime: getValues('startTime'),
        endTime: getValues('endTime'),
        courtId: getValues('court')?.id,
        paymentType: getValues('paymentType'),
      },
      user: players,
      extras: watchedExtras.map((extra) => {
        return {
          extrasId: extra.id,
          quantity: extra.count,
        };
      }),
    };
    console.log('socket calculate_price emit body', body);
    socket.emit('calculate_price', socketUUID, body);
  }

  function handleSocketNewPrice(id, data) {
    if (id === socketUUID) {
      if (data.status === 'error') {
        console.log('Socket new_price error', data);
        enqueueSnackbar(t(data?.message || ERR_NETWORK_ERROR),{variant:"error"})
        handleCloseDialog();
      } else {
        console.log('Socket new_price data received', data);
        setPriceData(data);
        const organizer = watchedPlayers?.find(x=>x.isOrganizer);

        if(organizer){

          if (data) {
            const socketUsers = data?.users;
            const socketUser = socketUsers?.find(
                user => user.email === organizer.email);
            organizer.price = socketUser?.price;
          }

          setBooker({...organizer})
        }

      }
      setIsLoadingSocketPrice(false);
    }
  }

  //Disable wallet if split Payment
  useEffect(() => {
    setValue('wallet', false);
  }, [watchedPaymentType]);

  // Court Available Check
  useEffect(() => {
    if (clubId) {
      courtAvailableCheck();
    }
  }, [clubId]);

  //Fetch Extras at mount
  useEffect(() => {
    fetchExtras();
  }, []);

  // Sockets Emit Price Change
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      emitSocketCalculatePrice();
    }, 150);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    players,
    watchedExtras,
    watchedPaymentType,
    isPayingWithWallet,
  ]);

  // Sockets Listen for price change
  useEffect(() => {
    socket.on('new_price', handleSocketNewPrice);
    //Cleanup
    return () => {
      socket.off('new_price');
    };
  }, []);

  return (
      <FormProvider {...formMethods} >
        <DialogBase
            isLoading={isLoading}
            isOpen={isDialogOpen}
            onClose={handleCloseDialog}
            onExited={handleDialogAfterExit}
            title={t('bookingAdd.heading')}
            titleLabelSlot={'1/2'}
            dividers={!isLoading}
            contentSlot={<>
              {
                  (isCourtNotAvailable || isCourtNoPrice) && !isLoading &&
                  <Alert severity={'error'} sx={{mt: 2}}>
                    <AlertTitle>
                      {t('bookings.error.noaccess.title')}
                    </AlertTitle>
                    {t('bookings.error.noaccess.description')}
                  </Alert>
              }
              <Box>
                <DialogSection
                    label={t('bookingAdd.time')}
                    icon={AccessTimeIcon}
                    isLoading={isLoading}
                >
                  <BookingsDateTimeDetails
                      isLoading={isLoading}
                      date={moment(startDate, 'YYYY-MM-DD').format('ddd ll')}
                      timeStart={moment(startTime, 'HH:mm').format('LT')}
                      timeEnd={moment(endTime, 'HH:mm').format('LT')}
                  />
                </DialogSection>
                <DialogSection
                    label={'Club'}
                    icon={LocationOn}
                    isLoading={isLoading}
                >
                  <BookingsClubDetails
                      isLoading={isLoading}
                      clubName={clubName}
                      clubStreet={clubStreet}
                      houseNumber={clubHouseNumber}
                      clubPostalCode={clubPostalCode}
                      clubCity={clubCity}
                  />
                </DialogSection>

                <DialogSection
                    label={t('bookingAdd.court')}
                    icon={SurroundSoundIcon}
                    isLoading={isLoading}
                >
                  <BookingsCourtDetails
                      courtSize={courtSize}
                      courtName={courtName}
                      courtSport={courtSport}
                      courtType={courtType}
                      courtFeature={courtFeature}
                      isLoading={isLoading}
                  />
                </DialogSection>
                <DialogSection
                    label={t('bookingAdd.extra')}
                    icon={SportsBaseballOutlined}
                    isLoading={isLoading}
                    isDisabled={isCourtNotAvailable || isCourtNoPrice}
                >
                  <ExtrasSelect
                      isLoading={isLoading}
                      availableExtras={extras}
                  />
                </DialogSection>
                <DialogSection
                    label={t('bookingAdd.payment')}
                    icon={PaymentIcon}
                    isLoading={isLoading}
                    isDisabled={isCourtNotAvailable || isCourtNoPrice}
                >
                  <PaymentTypeSelect
                      discountApplied={bookerDiscountApplied}
                      disableSplitOption={false}
                      isLoading={isLoading}
                  />
                </DialogSection>
                {
                    isAuthenticated &&
                    <DialogSection
                        label={t('bookings.add.section.wallet')}
                        subLabel={policies?.wallet && <Link
                                style={{cursor:'pointer', margin: '0 0 0 auto', color:theme.palette.primary.main}}
                              color={theme.palette.secondary.main}
                              target='_blank'
                              onClick={()=> window.open(`${process.env.REACT_APP_URL}/club/${clubId}/wallet/topup?cId=${clubId}`, '_blank')}
                              >
                              {t('wallet.topup')}
                        </Link>}
                        icon={AccountBalanceWallet}
                        isLoading={isLoading}
                        isDisabled={isCourtNotAvailable || isCourtNoPrice}
                    >
                      <WalletSelect
                          isLoading={isLoading}
                          walletData={{
                            available: priceData?.wallet?.available,
                            canPay: priceData?.wallet?.canPay,
                            amount: +priceData?.wallet?.amount || 0,
                          }}
                          onChange={()=>bookingEvents['useWalletFromAdd']()}

                      />
                      {
                      !isLoading &&
                      <WalletAlerts
                      canPay={priceData?.wallet?.canPay}
                      clubId={clubId}
                      onSuccess={()=>emitSocketCalculatePrice()}
                      onSuccessParam={id}
                      walletId={walletData?.id}
                      players={players}
                      watchedExtras={watchedExtras}
                      />}
                    </DialogSection>
                }
                <DialogSection
                    label={t('bookingAdd.players')}
                    icon={PeopleAltOutlined}
                    isLoading={isLoading}
                    isDisabled={isCourtNotAvailable || isCourtNoPrice}
                >
                  <PlayersSelect
                      locationState={locationState}
                      id={id}
                      isLoading={isLoading}
                      isEnabled={!isCourtNotAvailable || !isCourtNoPrice}
                      allowChangeBooker={!isAuthenticated}
                      disableRemoveBooker={!isAuthenticated}
                      priceTotal={priceData?.totalPrice || 0}
                      socketPriceData={priceData}
                      isLoadingSocketPrice={isLoadingSocketPrice}
                      navigateTo={()=> navigateTo(`/club/${clubId}/add-booking`, {state: {...locationState,extras:watched?.extras,...watched}})}
                      reloadWindow={() => window.location.reload()}
                      singlePlayerErrorMessage={t('global.components.playerSelect.error.singlePayment')}
                      discountApplied={bookerDiscountApplied}
                      clubId={clubId}
                      isPayingWithWallet={isPayingWithWallet}
                      handleCloseBookingDialog={handleCloseDialog}
                  />
                </DialogSection>
                <DialogSection
                    isLoading={isLoading}
                    label={t('bookingAdd.billings')}
                    icon={ReceiptLongOutlined}
                    isLastChild={true}
                    isDisabled={isCourtNotAvailable || isCourtNoPrice}
                >
                  <BookingAddEditBilling
                      isLoading={isLoading}
                      priceData={priceData}
                      walletPayment={isPayingWithWallet}
                      bookerPayment={booker?.price}
                      playerCount={watchedPlayers.length}
                      paymentType={watchedPaymentType}
                      courtBookingLength={getCourtBookingLength(startTime,
                          endTime)}
                  />
                </DialogSection>

              </Box>
              {
                  isLoading &&
                  <Skeleton width={'100%'} height={'2px'}/>
              }
            </>
            }

            actionsSlot={
            <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
              <Button
                color='inherit'
                  variant="outlined"
                  size={'large'}
                  onClick={handleCloseDialog}
              >
                {t('bookingAdd.buttonClose')}
              </Button>
              <LoadingButton
                  variant="contained"
                  size={'large'}
                  disabled={isSubmittingForm || isSubmitting ||
                      isCourtNotAvailable ||
                      isCourtNoPrice}
                  loading={isSubmittingForm || isSubmitting}
                  onClick={handleFormSubmit}
              >
                <span>
                {isConfirm 
            ? `${t('bookings.addEdit.actions.confirmBooking')} (${currencySymbol}${getLocalizedNum(locale, priceData?.totalPrice)})`
            : `${t('global.buttons.actions.proceedToPayment')} (${currencySymbol}${getLocalizedNum(locale, priceData?.totalPrice)})`
}
                </span>
              </LoadingButton>
            </Stack>}
        >
        </DialogBase>
      </FormProvider>
  );

}

export default DialogBookingsAdd;


