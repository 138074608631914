import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  useTheme,
} from '@mui/material';
import IconSportPadel from '../../../assets/icons/Sports/IconSportPadel';
import IconSportTennis from '../../../assets/icons/Sports/IconSportTennis';
import IconSportSquash from '../../../assets/icons/Sports/IconSportSquash';
import {useRef, useState} from 'react';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';

function SlotSearchV2HeaderSportSelectDesktop({
                                                minWidth = '276px',
                                                sports,
                                                selectedSport,
                                                handleSelectSport,
                                              }) {

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const open = Boolean(anchorEl);

  let Icon;

  if (selectedSport?.toLowerCase() === 'padel') {
    Icon = IconSportPadel;
  }

  if (selectedSport?.toLowerCase() === 'tennis') {
    Icon = IconSportTennis;
  }

  if (selectedSport?.toLowerCase() === 'squash') {
    Icon = IconSportSquash;
  }

  function handleChangeSport(sport) {
    handleSelectSport(sport);
    handleClosePopover();
  }

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  if (sports?.length <= 1 || !selectedSport) {
    return;
  }

  return (
      <Box
          sx={{
            minWidth: minWidth,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
      >
        <Box
            sx={{
              p: .5,
              border: `1px solid rgba(0, 0, 0, 0.23)`,
              borderRadius: '140px',
            }}
        >
          <Button
              ref={buttonRef}
              variant="contained"
              onClick={handleOpenPopover}
              sx={{
                height: '42px',
                paddingLeft: '24px',
              }}
              startIcon={<Icon/>}
              endIcon={open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
          >
            {selectedSport}
          </Button>
        </Box>

        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            slotProps={{
              paper: {
                sx: {
                  width: buttonRef.current ?
                      buttonRef.current.offsetWidth :
                      'auto',
                  mt: .5,
                },
              },
            }}
        >
          <List>
            {
              sports?.map((sport) => {
                    return (
                        <ListItem key={sport} disablePadding>
                          <ListItemButton
                              onClick={() => handleChangeSport(sport.sport)}>
                            <ListItemText primary={sport.sport}/>
                          </ListItemButton>
                        </ListItem>
                    );
                  },
              )}
          </List>
        </Popover>
      </Box>
  );

}

export default SlotSearchV2HeaderSportSelectDesktop;
