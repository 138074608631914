import {TransitionFade} from '../../vendor/mui/transitions';
import DialogBase from '../_Global/Dialogs/DialogBase';
import {useParams} from 'react-router-dom';
import {useTranslate} from '@tolgee/react';
import moment from 'moment-timezone';
import SlotSearchV2 from '../SlotSearchV2/SlotSearchV2';
import {Box} from '@mui/material';
import { useSelector } from 'react-redux';

const DialogSlots = props => {

  const {open, setOpen, openMatchMinCreateTime, timezone, isDesktop} = props;

  const {t} = useTranslate();

  const storeData = useSelector((state) => state.openMatches);
  const listing = storeData?.listing;
  const filters=listing?.filters;
  const date = filters?.data;
  const sport = filters?.sport;

  const selectedDate = moment.tz(timezone).
      add(+openMatchMinCreateTime, 'hours');

  const handleClose = () => {
    setOpen(false);
  };

  const getDialogContent = () => {

    return (
        <Box
            sx={{
              pt: 2,
              pb:4,
            }}
        >
          <SlotSearchV2
              view={'openMatch'}
              defaultDate={moment(selectedDate)}
              defaultMinDate={selectedDate}
              defaultSport={isDesktop ? '' :sport?.charAt(0).toUpperCase() + sport.slice(1)}
          />
        </Box>

    );

  };

  return (
      <DialogBase
          maxWidth="lg"
          scroll={'body'}
          isOpen={open}
          onClose={handleClose}
          onExited={handleClose}
          title={t('openMatches.create.slotDialogTitle')}
          transitionComponent={TransitionFade}
          contentSlot={getDialogContent()}
          dialogStyles={{
            '.MuiDialogContent-root':{
              '.item .pageCard':{
                paddingTop:0
              }
            }
          }}
      />
  );

};
export default DialogSlots;
