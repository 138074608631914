import {Grid} from '@mui/material';
import {courtAvailableCheck, fetchExtras} from '../../utils/bookings';
import AddBookingV2CourtSelect
  from '../../components/Bookings/AddBookingV2/AddBookingV2CourtSelect';
import AddBookingV2BookingDetails
  from '../../components/Bookings/AddBookingV2/AddBookingV2BookingDetails';
import {useDispatch} from 'react-redux';
import {useEffect, useRef, useState} from 'react';

const DialogBookingsAddV2Content = ({
                                      setIsDialogOpen,
                                      isCourtNotAvailable,
                                      setIsCourtNotAvailable,
                                      startDate,
                                      priceData,
                                      setPriceData,
                                      selectedCourt,
                                      locationState,
                                      isCourtSelected,
                                    }) => {

  const dispatch = useDispatch();

  const [isLoadingExtras, setIsLoadingExtras] = useState(false);
  const [extras, setExtras] = useState([]);

  const bookingSectionRef = useRef(null);

  const {clubId, courtSport, startTime, endTime, date} = selectedCourt || {};

  useEffect(() => {
    if (isCourtSelected) {
      if (bookingSectionRef.current) {
        bookingSectionRef.current.scrollIntoView(
            {behavior: 'smooth', block: 'nearest'});
      }
      dispatch(
          fetchExtras({clubId, courtSport, setExtras, setIsLoadingExtras}),
      );
    }
  }, [selectedCourt]);

  useEffect(() => {
    if (clubId && isCourtSelected) {
      dispatch(
          courtAvailableCheck({
            startDate,
            startTime,
            endTime,
            clubId,
            setIsCourtNotAvailable,
            selectedCourt,
          }),
      );
    }
  }, [clubId, selectedCourt]);

  return (
      <Grid container spacing={3}>
        <AddBookingV2CourtSelect
            isCourtSelected={isCourtSelected}
        />
        <AddBookingV2BookingDetails
            ref={bookingSectionRef}
            locationState={locationState}
            clubId={clubId}
            courtSport={courtSport}
            selectedCourt={selectedCourt}
            startDate={startDate}
            startTime={startTime}
            endTime={endTime}
            isCourtNotAvailable={isCourtNotAvailable}
            extras={extras}
            isLoadingExtras={isLoadingExtras}
            isCourtSelected={isCourtSelected}
            setIsOpenDialog={setIsDialogOpen}
            priceData={priceData}
            setPriceData={setPriceData}
        />
      </Grid>
  );
};
export default DialogBookingsAddV2Content;
