import {Box, Stack, Typography} from '@mui/material';
import ProfileImageViewer
  from '../../../components/_Global/Profile/ProfileImageViewer';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import useExperienceContext
  from '../../../hooks/clubExperience/useExperienceContext';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import ContainerFixed
  from '../../../components/_Global/Containers/ContainerFixed';
import SiteOnboardingButtons
  from '../../../components/_Global/Site/SiteOnboardingButtons';
import SiteUserProfileClub
  from '../../../components/_Global/Site/SiteUserProfileClub';
import {LayoutClubNavHorizontal} from '../Nav/LayoutClubNavHorizontal';
import {useMemo} from 'react';
import useIsMobile from '../../../hooks/ui/useIsMobile';
import SiteMobileNav from '../../../components/_Global/Site/SiteMobileNav';
import useWebViewMobile from '../../../hooks/access/useWebViewMobile';

function LayoutCompanyHeader({
                               defaultPath,
                               experience = 'CLUB',
                               navItems,
                             }) {

  const {clubId} = useParams();
  const isAuthenticated = useIsAuthenticated();
  const isMobile = useIsMobile();
  const isWebViewMobile = useWebViewMobile();
  const homePath = defaultPath ?? `/club/${clubId}`;

  return (
      <ContainerFixed>
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: '24px',
              position: 'relative',
              zIndex: 9,
              ...(isMobile && {
                justifyContent: 'center',
              }),
            }}
        >
          {/*Left*/}
          {
              !isMobile &&
              <Box
                  sx={{
                    width: '200px',
                  }}
              >
              </Box>
          }

          {/*Center*/}
          <Box
              sx={{
                borderRadius: '92px',
                height: '78px',
                p: isMobile ? .4 : .5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `linear-gradient(
              to right,
              #714BE2 0%,
              #EF8EFF 33%,
              #714BE2 66%,
              #83DAFF 100%)`,
                ...(isMobile && {
                  width: '100%',
                  height: '58px',
                }),
              }}
          >
            <Stack
                direction="row"
                alignItems="center"
                columnGap={2}
                sx={{
                  borderRadius: '92px',
                  backgroundColor: '#fff',
                  px: 3,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '330px',
                  ...(isMobile && {
                    minWidth: '100%',
                  }),
                }}
            >
              <Logo
                  homeUrl={homePath}
                  experience={experience}
                  disabled={isWebViewMobile}
              />
              {
                  !isMobile &&
                  <LayoutClubNavHorizontal navItems={navItems}
                                           experience={experience}/>
              }
              {
                  isMobile && !isWebViewMobile &&
                  <Box
                      sx={{
                        ml: 'auto',
                      }}
                  >
                    <SiteMobileNav
                        experience={experience}
                        navItems={navItems}
                    />
                  </Box>
              }
            </Stack>
          </Box>

          {/*Right*/}
          {
              !isMobile &&
              <Box
                  sx={{
                    width: '200px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
              >
                {
                    isAuthenticated &&
                    <SiteUserProfileClub
                        size={'sm'}
                        experience={experience}
                    />
                }
                {
                    !isAuthenticated && !isWebViewMobile &&
                    <SiteOnboardingButtons
                        size={'large'}
                        showDivider={false}
                        variantRegister={'outlined'}
                        variantSignIn={'contained'}
                        disableRegister={true}
                        signInInverted={true}
                        colorSignin={'secondary'}
                    />
                }
              </Box>
          }


        </Box>
      </ContainerFixed>

  );
}

function Logo({experience, homeUrl, disabled}) {

  const isCompanyExperience = experience == 'COMPANY';

  const navigateTo = useNavigate();
  const {clubId} = useParams();

  const {clubDetails, companyDetails} = useExperienceContext();
  const details = isCompanyExperience ? companyDetails : clubDetails;

  return (
      <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            if (disabled)
              return;
            navigateTo(homeUrl);
          }}
      >
        <ProfileImageViewer
            size={'sm'}
            imgPath={details?.imageUrl}
            placeholderStyle={'shield-icon'}
        />
        <Typography
            variant={'h5-bold'}
            lineHeight={1.1}
            whiteSpace={'nowrap'}
            mb={.5}
            sx={{
              fontSize: {
                md: '24px',
                xs: '18px',
              },
            }}
        >
          {details?.name}
        </Typography>
      </Stack>
  );

}

export default LayoutCompanyHeader;
