import { colors, Stack, styled } from "@mui/material";

export const ListingContainer=styled(Stack)(({theme})=>{

    return{

        rowGap:'2rem',
        '.genderFilter':{
            '.MuiFormControl-root':{
                '.MuiFormLabel-root':{
                    transform: 'translate(14px, 12px) scale(1)',
                },
            },
            '.css-tpjxk9-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':{
                transform: 'translate(14px, -9px) scale(0.75)'
            }
        }
    }
})

export const ListingBodyContainer=styled(Stack)(({theme})=>{

    return{

        '.otherGenderWarning':{
            color:theme.palette.primary.main
        }
    }
})

export const MatchCardContainer=styled(Stack)(({theme,isMobile})=>{

    return{

        background:theme.palette.grey[50],
        cursor:'pointer',
        minHeight:'192px',
        marginBottom:'1rem',
        borderRadius:'12px',
        border:`1px solid ${theme.palette.divider}`,
        overflow:'hidden',

        '.header':{
            background: theme.palette.grey[200],
            minHeight:'56px',
            padding: "20px 20px 20px 20px",
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            flexWrap:'wrap',

            '.time':{
                flexDirection:'row',
                columnGap:'10px',

                '.MuiTypography-root':{
                    fontSize:'20px',
                    fontWeight:600
                }

            },
            '.price':{
                fontSize:'20px',
                fontWeight:600
            },

            '.details':{
                flex:'1 0 40%',
                flexDirection:'row',
                justifyContent:isMobile?'flex-start':'center',
                flexWrap:'wrap',

                '& .matchMisc':{

                    flex:isMobile?'1 1 auto':'1 0 130px',

                    '.MuiAvatar-root':{
                        height:'24px',
                        width:'24px',
                        border:'none !important',
                        marginRight:isMobile ? '0px' : '4px',
                        overflow:'visible',
                        background:'transparent',
                        color:theme.palette.grey[500]
                    },

                    'img': {
                        width: isMobile ? '16px' : '22px',
                        height: isMobile ? '16px' : '22px'
                    },

                    'svg':{
                        color:'#707070 !important',
                        width: isMobile ? '16px' : '22px',
                        height: isMobile ? '16px' : '22px'
                    }

                },

                '& .matchType':{
                    flex:isMobile?'1 1 auto':'1 0 115px'
                }

            }
        },

        '.body':{

            flex:1,
            justifyContent:'center',
            padding:'18px 14px',

            '.addedPlayer':{
                flexDirection:isMobile?'column':'row',
                justifyContent:'center',
                columnGap:'1rem'
            },
            '.nameLevel':{
                alignItems:isMobile?'center':'flex-start'
            },
            '.addPlayerView':{
                flexDirection:isMobile?'column':'row',
                columnGap:'1rem',
                alignItems:'center',
                justifyContent:'center'
            }
        }

    }

})
