import React, {useEffect} from 'react';
import {Box, Grid, Stack} from '@mui/material';
import {useSlotSearchV2Context} from '../../SlotSearchV2/ProviderSlotSearchV2';
import {
  SlotSearchV2CourtSelectDialogCourtsList,
} from '../../SlotSearchV2/Slots/CourtSelectDialog/SlotSearchV2CourtSelectDialogCourtsList';
import PageCard from '../../_Global/Page/PageCard';
import {useTranslate} from '@tolgee/react';
import {useLocation} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import moment from 'moment-timezone';
import useExperienceContext
  from '../../../hooks/clubExperience/useExperienceContext';
import useIsMobile from '../../../hooks/ui/useIsMobile';
import AddBookingCardTitle from './AddBookingCardTitle';
import Scrollbar from '../../_Global/ScrollBar/ScrollBar';

const AddBookingV2CourtSelect = () => {

  const {
    selectedSlot,
    selectedCourt,
  } = useSlotSearchV2Context();

  const {t} = useTranslate();
  const location = useLocation();
  const locationState = location.state;
  const isMobile = useIsMobile();
  const slotCourts = selectedSlot.slots;
  const courts = slotCourts ?? locationState?.courts;

  const {clubDetails} = useExperienceContext();
  const clubTimezone = clubDetails?.addresses[0].timezone;

  const {setValue} = useFormContext();

  useEffect(() => {

    if (Object.keys(selectedCourt)?.length) {

      const {
        clubId,
        courtId,
        courtName,
        courtSize,
        startTime,
        endTime,
        date,
      } = selectedCourt;

      const startDate = moment.tz(date, clubTimezone).format('YYYY-MM-DD');

      setValue('court', {
        clubId: clubId,
        id: courtId,
        name: courtName,
        size: courtSize,
      });
      setValue('startDate', startDate);
      setValue('startTime', startTime);
      setValue('endTime', endTime);
    }
  }, [selectedCourt]);

  return (
      <Grid
          item
          lg={4}
          xs={12}
          sx={{zIndex: 1}}
      >

        <PageCard
            sx={{
              padding: 0,
              borderRadius: '16px',
              overflow: 'hidden',
            }}
        >
          <Stack>
            <AddBookingCardTitle
                title={t('search.courtSelect.title')}
                padding={'24px'}
                mb={0}
                step={1}
            />
            <Box
                sx={{
                  ...(!isMobile && {
                    height: `calc(100vh - 300px)`
                  })
            }}>
              <Scrollbar autoHide={false}>
                <SlotSearchV2CourtSelectDialogCourtsList
                    courts={courts}
                />
              </Scrollbar>
            </Box>
          </Stack>
        </PageCard>

      </Grid>
  );
};
export default AddBookingV2CourtSelect;
