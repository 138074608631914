import {Box, useTheme} from '@mui/material';
import {
  LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
  LAYOUT_MAIN__HEADER__TOP__HEIGHT,
  LAYOUT_MAIN__PAGE___PADDING_TOP,
  LAYOUT_MAIN__PAGE_HEADER___HEIGHT,
  LAYOUT_MAIN__PAGE_HEADER___MARGIN_BOTTOM,
} from '../../../constants/ui';

function PageCard({
                    width = '100%',
                    height = '100%',
                    maxWidth = '100%',
                    centeredLoader = false,
                    children,
                    isBorder = false,
                    direction = 'row',
                    marginTop = '0px',
                    sx = {},
                    isBlurVariant = false,
                  }) {

  const theme = useTheme();

  /*todo cleanup loader implementation */
  const centerAlignStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: `calc(100vh - (${LAYOUT_MAIN__HEADER__TOP__HEIGHT} + ${LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT} + ${LAYOUT_MAIN__PAGE___PADDING_TOP} + ${LAYOUT_MAIN__PAGE_HEADER___HEIGHT} + ${LAYOUT_MAIN__PAGE_HEADER___MARGIN_BOTTOM} + 50px))`,
  };

  return (
      <Box
      className='pageCard'
          sx={{
            height,
            flexDirection: direction,
            maxWidth,
            width,
            borderRadius: '16px',
            padding: 'clamp(1.00rem, calc(-0.14rem + 5.71vw), 2.00rem)',
            background: '#fff',
            border: isBorder ? `1px solid ${theme.palette.divider}` : '0',
            marginTop,
            ...(isBlurVariant ? {
              background: 'rgba(255,255,255,0.75)',
              backdropFilter: 'blur(45px)',
              WebkitBackdropFilter: 'blur(45px)',
            } : {}),
            ...sx,
          }}
          style={!!centeredLoader ? centerAlignStyles : {}}
      >
        {children}
      </Box>
  );
}

export default PageCard;
