import {Controller, useFormContext} from 'react-hook-form';
import {useEffect} from 'react';
import {
  Alert,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {bookingEvents} from '../../../utils/analyticsEvents';
import RadioCustom from '../../_Global/Radio/RadioCustom';
import useIsMobile from '../../../hooks/ui/useIsMobile';

export function PaymentTypeSelect({
                                    isLoading = false,
                                    isDisabled = false,
                                    discountApplied,
                                    disableSplitOption = false,
                                  }) {

  const {t} = useTranslate();
  const {control, trigger, formState, watch, setValue} = useFormContext();
  const watched = watch();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger('players');
    }
  }, [watched.paymentType]);

  useEffect(() => {
    if (watched?.paymentType === 'single') {
      bookingEvents['paymentTypeSingle']();
    } else if (watched?.paymentType === 'split') {
      bookingEvents['paymentTypeSplit']();
    }
  }, [watched?.paymentType]);

  return (
      <>
        {
            isLoading &&
            <Skeleton sx={{
              width: '200px',
              height: '20px',
            }}/>
        }
        {
            !isLoading &&
            <Stack spacing={2}>
              <Controller
                  name={'paymentType'}
                  control={control}
                  defaultValue={'single'}
                  render={
                    ({field}) => {
                      return (
                          <Stack
                              direction={isMobile ? 'column' : 'row'}
                              spacing={isMobile ? 1 : 2}
                              sx={{pt:1}}
                          >
                            <RadioCustom
                                label={t(
                                    'global.components.paymentTypeRadio.single')}
                                description={t(
                                    'global.components.paymentTypeRadio.single.description')}
                                value={'single'}
                                selectedValue={field.value}
                                onChange={(value) => {
                                  field.onChange(value);
                                }}
                            />
                            <RadioCustom
                                label={t(
                                    'global.components.paymentTypeRadio.split')}
                                description={t(
                                    'global.components.paymentTypeRadio.split.description')}
                                value={'split'}
                                selectedValue={field.value}
                                onChange={(value) => {
                                  field.onChange(value);
                                }}
                            />
                          </Stack>
                      );
                    }}
              />
              {discountApplied && watched.paymentType === 'single' &&
                  <Alert severity="info" >
                    {t('global.component.payment.discount.info')}

                  </Alert>}
              {discountApplied && watched.paymentType === 'split' &&
                  <Alert severity="success">
                    {t('global.component.payment.discount.success')}
                  </Alert>}
            </Stack>
        }
      </>
  );
}
