import {
  alpha,
  Chip,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {Circle} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';
import {bookingEvents} from '../../../../utils/analyticsEvents';
import {getLocalizedNum} from '../../../../utils/localization';
import {useSlotSearchV2Context} from '../../ProviderSlotSearchV2';
import useLocale from '../../../../hooks/localization/useLocale';
import useDefaults from '../../../../hooks/defaults/useDefaults';
import {useLocation} from 'react-router-dom';

export function SlotSearchV2CourtSelectDialogCourtsListItem({court, isLast}) {

  const theme = useTheme();
  const {locale} = useLocale();
  const {currencySymbol} = useDefaults();
  const {t} = useTranslate();
  const location = useLocation();
  const locationState = location?.state;

  const {
    selectedCourt: courtSelected,
    setSelectedCourt,
    setWillNavigateToCreateBooking,
  } = useSlotSearchV2Context();

  const selectedCourt = locationState?.selectedCourt ?? courtSelected; // incase of new Booking pop-up

  const isSelected = selectedCourt?.courtId == court?.courtId;

  const {
    courtName,
    courtSport,
    courtType,
    courtFeature,
    courtSize,
    price,
    originalPrice,
    discountApplied,
  } = court;

  function handleClickSlot() {
    setSelectedCourt(court);
    setWillNavigateToCreateBooking(true);
    bookingEvents['selectCourtForBooking']();
  }

  return (

      <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            width: '100%',
            cursor: 'pointer',
            px: 3,
            py: 3,
            background: isSelected ?
                alpha(theme.palette.success.main, .15) :
                '',
            ':hover': {
              background: isSelected ?
                  alpha(theme.palette.success.main, .25) :
                  theme.palette.grey[100],
            },
          }}
          onClick={handleClickSlot}
      >
        <Stack>
          <Typography>
            {courtName}
          </Typography>
          <Stack
              direction={'row'}
              columnGap={'4px'}
              alignItems={'center'}
              divider={<Circle sx={{width: '4px'}} color={'action'}/>}
              flexWrap={'wrap'}
          >
            <Typography variant={'body2'}>
              {courtSport}
            </Typography>
            {
                courtSize &&
                <Typography variant={'body2'}>{courtSize}</Typography>
            }
            {
                courtType &&
                <Typography variant={'body2'}>{courtType}</Typography>
            }
            {
                courtFeature &&
                <Typography variant={'body2'}>{courtFeature}</Typography>
            }
          </Stack>
        </Stack>

        <Stack alignItems={'center'} spacing={'4px'}>
          {
              discountApplied &&
              <Tooltip
                  arrow
                  placement={'top'}
                  title={t('global.tooltips.packageDiscount')}
              >
                <Typography
                    variant={'body2'}
                    sx={{
                      textDecoration: 'line-through',
                    }}
                >
                  {`${currencySymbol} ${originalPrice}`}
                </Typography>
              </Tooltip>
          }
          <Chip
              label={`${currencySymbol} ${getLocalizedNum(locale,
                  +price)}`}
              sx={{
                backgroundColor: theme.palette.success.main,
                color: '#fff',
              }}
          />
        </Stack>

      </Stack>

  );
}
