import {alpha, Radio, Stack, Typography, useTheme} from '@mui/material';

function RadioCustom({
                       label,
                       description,
                       value = '',
                       selectedValue,
                       onChange = (value) => {
                       },
                     }) {

  const theme = useTheme();

  const selected = selectedValue === value;

  return (
      <Stack
          direction="row"
          spacing={1}
          onClick={() => {
            onChange(value);
          }}
          sx={{
            flex: 1,
            cursor: 'pointer',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.divider}`,
            py: 2,
            px: 2,
            ...(selected && {
              borderColor: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, .1),
            }),
          }}
      >
        <Radio
            size={'medium'}
            value={value}
            checked={selected}
            sx={{
              p: 0,
              width: '24px',
              height: '24px',
            }}
        />
        <Stack>
          <Typography>
            {label}
          </Typography>
          <Typography variant="caption" color={'text.secondary'}>
            {description}
          </Typography>
        </Stack>
      </Stack>
  );
}

export default RadioCustom;
