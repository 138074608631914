import {OnboardingContext} from '../../providers/ProviderOnboarding';
import {useContext} from 'react';

function useOnboardingContext() {
  const {
    isOpenLoginDialog,
    setIsOpenLoginDialog,
    loginDialogDescription, 
    setLoginDialogDescription,
    loginSuccessFn,
    loginSuccessFnParams,
    setLoginSuccessFnParams,
    onLoginDialogCloseParams,
    setOnLoginDialogCloseParams,
    onLoginDialogCloseFn,

    isOpenForgotDialog,
    setIsOpenForgotDialog,
    isOpenRegisterDialog,
    setIsOpenRegisterDialog,
    registerSuccessFn,
    registerSuccessFnParams,
    setRegisterSuccessFnParams,
    onRegisterDialogCloseFn,
    onRegisterDialogCloseParams,
    setOnRegisterDialogCloseParams,
    willShowForgotPassword,
    setWillShowForgotPassword,
    willShowLoginDialog,
    setWillShowLoginDialog,
    willShowRegisterDialog,
    setWillShowRegisterDialog,
    preFillEmail,
    setPreFillEmail,
    isOpenUserRatingDialog,
    setIsOpenUserRatingDialog,
    ratingDialogDecription, 
    setRatingDialogDescription,
    setRatingSuccessFn,
    onRatingSuccess,
    openLoginDialogWithAction
  } = useContext(OnboardingContext);

  return {
    isOpenLoginDialog,
    setIsOpenLoginDialog,
    loginDialogDescription, 
    setLoginDialogDescription,
    loginSuccessFn,
    loginSuccessFnParams,
    setLoginSuccessFnParams,
    onLoginDialogCloseParams,
    setOnLoginDialogCloseParams,
    onLoginDialogCloseFn,
    isOpenForgotDialog,
    setIsOpenForgotDialog,
    isOpenRegisterDialog,
    setIsOpenRegisterDialog,
    registerSuccessFn,
    registerSuccessFnParams,
    setRegisterSuccessFnParams,
    onRegisterDialogCloseFn,
    onRegisterDialogCloseParams,
    setOnRegisterDialogCloseParams,
    willShowForgotPassword,
    setWillShowForgotPassword,
    willShowLoginDialog,
    setWillShowLoginDialog,
    willShowRegisterDialog,
    setWillShowRegisterDialog,
    preFillEmail,
    setPreFillEmail,
    isOpenUserRatingDialog,
    setIsOpenUserRatingDialog,
    ratingDialogDecription, 
    setRatingDialogDescription,
    setRatingSuccessFn,
    onRatingSuccess,
    openLoginDialogWithAction
  };

}

export default useOnboardingContext;