import { Record } from "immutable";

const defaultValues = {

  collapseBilling:null,
  // create page
    matchRange:{
        marks:[],
        minRange:0,
        maxRange:9,
        playerLevel:null,
        value:[],
        prevValue:[],
        minDistance:3,
        isChangedByUser:false
    },
    teamSize:null,
    currentLoggedInUser:{
      isRegistered:false,
      canRegister:false,
      canAddPartner:true
    },
    viewOtherGenderErrAlert:false,
    suggestedPlayers:{
      loading:false,
      list:[],
      hasNoBuddies:false
    },
    extras:{
      loading:false,
      list:[]
    },
    submitData:{
      loading:false,
      response:{}
    },
    data:{
      loading:false,
      walletData:[],
      priceData:[]
    },


    // detail page
    singleDetail:{
      loading:false,
      data:-1
    },

    // LISTING

    listing:{
      data:{},
      loading:false,
      totalPage:0,
      hasMore:true,

      filters:{
        sport:'',
        date:null,
        gender:[],
        clubIds:[],
        page:0,
        limit:10
      }
    },

    invalidRating:false

};

// const State = new Record(defaultValues);
// const initialState = new State();

function openMatches(state = defaultValues, action) {

  switch (action.type) {
    case 'OPEN_MATCH_CREATE_SET_MATCH_RANGE':
      return {
        ...state,
        matchRange: {...state.matchRange,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_TEAM_SIZE':
      return {
        ...state,
        teamSize: action.payload,
      };
    case 'OPEN_MATCH_CREATE_SET_CURRENT_USER':
      return {
        ...state,
        currentLoggedInUser: {...state.currentLoggedInUser,...action.payload},
      };
    case 'OPEN_MATCH_SET_VIEW_OTHER_GENDER_ERR_ALERT':
      return {
        ...state,
        viewOtherGenderErrAlert: action.payload,
      };
    case 'OPEN_MATCH_CREATE_SET_SUGGESTED_PLAYERS':
      return {
        ...state,
        suggestedPlayers: {...state.suggestedPlayers,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_EXTRAS':
      return {
        ...state,
        extras: {...state.extras,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_SUBMIT_DATA':
      return {
        ...state,
        submitData: {...state.submitData,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_DATA':
      return {
        ...state,
        data: {...state.data,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_SINGLE_DETAIL':
      return {
        ...state,
        singleDetail: {...state.singleDetail,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_LISTING_DATA':
      return {
        ...state,
        listing: {...state.listing,...action.payload},
      };
    case 'OPEN_MATCH_CREATE_SET_COLLAPSE_BILLING':
      return {
        ...state,
        collapseBilling:action.payload
      };
    case 'OPEN_MATCH_CREATE_SET_USER_INVALID_RATING':
      return {
        ...state,
        invalidRating:action.payload
      };
    case 'OPEN_MATCH_CREATE_REFRESH_ALL_STATES':
      return {
        ...defaultValues
      };
    default:
        return state;
  }
}

export default openMatches
