import {useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import ExtrasSelect from '../../components/Bookings/Inputs/ExtrasSelect';

export const StyledAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({

  background: 'transparent !important',
  marginTop: '1rem',
  paddingBottom: '1rem',
  // borderBottom:`1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  borderRadius: '0 !important',

  '::before': {
    display: 'none',
  },

  '.MuiAccordionSummary-root': {
    padding: 0,
    minHeight: 'auto',

  },
  '.MuiAccordionSummary-content': {
    margin: 0,

    '.MuiSvgIcon-root[data-testid="SportsBaseballOutlinedIcon"]': {
      color: theme.palette.grey[500],
      marginRight: '10px',
    },
  },

  '.MuiAccordionDetails-root': {
    paddingRight: 0,
    paddingLeft: 0,
  },

}));

const AccordionExtras = ({label, loading, extras}) => {

  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) {
    return (
        <Stack
            width={'100%'}
            direction={'row'}
            columnGap={1}
        >
          <Stack flex={'1 0 70%'}>
            <Skeleton width={'120px'}/>
            <Skeleton width={'260px'} height={'22px'}/>
          </Stack>
        </Stack>
    );
  }
  return (
      <StyledAccordion

          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{padding: 0, margin: 0}}
      >
        <AccordionSummary id={`panel-header`} aria-controls={`panel-content`}>
          <Stack
              direction={'row'}
              justifyContent={'space-between'}
              width="100%"
              alignItems="center"
          >
            <Typography variant={'h6-bold'}>{label}</Typography>
            {expanded === 'panel1' ? <ExpandLess/> : <ExpandMore/>}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{
          // margin:0,
          // padding:0
        }}>
          {loading && (
              <Skeleton width={'100%'} height={'200px'} sx={{mr: 'auto'}}/>
          )}
          <ExtrasSelect isLoading={loading} availableExtras={extras}/>
        </AccordionDetails>
      </StyledAccordion>
  );
};
export default AccordionExtras;
