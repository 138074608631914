import {Controller, useFormContext} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {matchIsValidTel, MuiTelInput} from 'mui-tel-input';
import useExperienceContext
  from '../../../hooks/clubExperience/useExperienceContext';
import {countriesString} from '../../../utils/ui';

function Phone() {
  const {control, formState: {errors}} = useFormContext();
  const {t} = useTranslate();

  const {clubDetails} = useExperienceContext();
  const clubCountry = countriesString[clubDetails?.addresses?.at(0)?.country];

  return (
      <Controller
          name={'phone'}
          control={control}
          rules={{
            validate: (value) => {
              if (value) {
                return matchIsValidTel(value);
              } else {
                return true;
              }
            },
          }}
          render={({field, fieldState}) => {

            const {phone: phoneErrors} = errors;
            let errorMessage;

            if (phoneErrors) {
              if (phoneErrors.type === 'exists') {
                errorMessage = t('register.fields.phone.error.exists');
              }
              if (phoneErrors.type === 'validate') {
                errorMessage = t('register.fields.phone.error');
              }
            }
            return <MuiTelInput
                {...field}
                fullWidth
                defaultCountry={clubCountry}
                error={!!fieldState.invalid}
                helperText={errorMessage}
            />;
          }}
      />
  );

}

export default Phone;
