import {Skeleton, Stack, Typography, useMediaQuery} from '@mui/material';
import {AccessTimeOutlined, CalendarToday} from '@mui/icons-material';

function BookingsDateTimeDetails({
                                   date = '',
                                   timeStart = '',
                                   timeEnd = '',
                                   isLoading,
                                   showIcons = true,
                                 }) {

  const mobileCollapse = useMediaQuery(`(max-width: 334px)`);

  return <>
    {
        isLoading && <Skeleton width={'256px'} height={'24px'}/>
    }
    {
        !isLoading &&
        <Stack
            mr={1}
            direction={!mobileCollapse ? 'row' : 'column'}
            alignItems={!mobileCollapse ? 'center' : 'flex-start'}
            columnGap={!mobileCollapse ? 3 : 0}
            rowGap={1}
            flexWrap={'wrap'}
        >

          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {showIcons && <CalendarToday fontSize={'medium'} color={'action'}/>}
            <Typography
                variant={'body1'}
                sx={{userSelect: 'none'}}
                fontSize={'18px'}
                fontWeight={500}
            >
              {date}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {showIcons &&
                <AccessTimeOutlined fontSize={'medium'} color={'action'}/>}
            <Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
              <Typography
                  variant={'body1'}
                  sx={{userSelect: 'none'}}
                  fontSize={'18px'}
                  fontWeight={500}
              >
                {timeStart}
              </Typography>
              <span>-</span>
              <Typography
                  variant={'body1'}
                  sx={{userSelect: 'none'}}
                  fontSize={'18px'}
                  fontWeight={500}
              >
                {timeEnd}
              </Typography>
            </Stack>
          </Stack>

        </Stack>

    }
  </>;
}

export default BookingsDateTimeDetails;
