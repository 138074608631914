import {useEffect, useState} from 'react';
import {
  AddPlayerIconBtn,
  AvatarIconContainer,
  ClippedText,
  PlayerCardContainer,
} from '../../vendor/mui/styled-components/OpenMatches';
import ProfileImageViewer from '../_Global/Profile/ProfileImageViewer';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {Add, Close} from '@mui/icons-material';
import DialogAddPlayer from './DialogAddPlayer';
import {
  addPlayerToState,
  checkAvailibity,
  getLoggedInPlayerRating,
  removePlayerFromState,
} from '../../utils/openMatches';
import {getInitials} from '../../utils/ui';
import useIsMobile from '../../hooks/ui/useIsMobile';
import {useSnackbar} from 'notistack';
import {useTranslate} from '@tolgee/react';
import ChipPlayerPaymentStatus from '../_Global/Chips/ChipPlayerPaymentStatus';
import {useDispatch, useSelector} from 'react-redux';
import {useFormContext} from 'react-hook-form';
import useOnboardingContext from '../../hooks/onboarding/useOnboardingContext';
import PlayerRatingChip from './PlayerRatingChip';
import DialogBase from '../_Global/Dialogs/DialogBase';
import ProfileImageDisplay from '../Activity/ProfileDisplay/ProfileImageDisplay';
import { ProfileDisplayContainer } from '../../vendor/mui/styled-components/Activity';
import MiscList from '../Activity/ProfileDisplay/MiscList';
import { getOpenMatchProfileData } from '../../vendor/redux/actions/openMatches';
import { store } from '../../vendor/redux/configure-store';

const PlayerCard = props => {

  const {player, index, team, players, setValue, paymentStatusPage} = props;

  const {
    setIsOpenUserRatingDialog,
    setRatingDialogDescription,
    setRatingSuccessFn,
  } = useOnboardingContext();
  
  const {setIsOpenLoginDialog} = useOnboardingContext();
  const {watch} = useFormContext();
  const watched = watch();
  const {clubId} = useParams();
  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useIsMobile();
  const location = useLocation();
  const url = location?.pathname;
  const isCreate = url?.includes('create');

const [isProfileOpen, setIsProfileOpen] = useState(false);
const [selectedPlayer, setSelectedPlayer] = useState(null);

  const matchGender = watched?.gender;
  const sport = watched?.sport;
  const matchRating = [watched?.minRating ?? 0, watched?.maxRating];

  const storeData = useSelector((state) => state.openMatches);
  const details = storeData.singleDetail;
  const matchDetails = details?.data;

  const currentUser = storeData?.currentLoggedInUser;
  const newJoinee = !matchDetails?.participants?.find(
      x => x?.user?.email == currentUser?.email);

  const isRegistered = currentUser?.isRegistered;
  const isSameTeam = currentUser?.team == player?.team;
  const btnText = isRegistered ? (
      isSameTeam && currentUser?.paymentStatus != 'paid' ?
          t('openMatches.page.player.addPartner') :
          t('openMatches.page.player.slotAvailable')
  ) : t('openMatches.page.player.joinHere');
  const canAddPartner = isRegistered && isSameTeam;

  const showRemoveBtn = isCreate
      ? true
      : !!currentUser?.isRegistered &&
      newJoinee && !player?.isAlreadyRegistered &&
      (player?.email == currentUser?.email || // to remove himself
          player?.addedBy == currentUser?.id); // to remove players added by current

  const [isDisabled, setIsDisabled] = useState(false);
  const [addPlayer, setAddPlayer] = useState(false);

  const handleCardClick = () => {
    if (!player?.email) {
      // If no player info, trigger add player logic
      btnClickHandler();
    } else {
      // If player info exists, show profile dialog
      setSelectedPlayer(player);
      setIsProfileOpen(true);
    }
  };

  const btnClickHandler = () => {

    const isEmpty = !player?.isAdded;

    if (isDisabled)
      return;

    if (!isEmpty)
      return;

    if (paymentStatusPage)
      return;

    if (!currentUser?.email) {

      setIsOpenLoginDialog(true);
      return;
    }
    if ((isCreate && isSameTeam) || (newJoinee && isRegistered && isSameTeam)) {
      setAddPlayer(true);
      return;
    }

    if (newJoinee && !isRegistered) {
      console.log('working')

      const {
        email,
        firstName,
        gender,
        id,
        imageUrl,
        lastName,
        rating,
      } = currentUser;

      // gender and rating check before joining

      addPlayerToList({
        email,
        firstName,
        gender,
        id,
        imageUrl,
        lastName,
        rating,
        isAdded: true,
        team,
      }, 'joining');
    }

    if (currentUser?.addedBy != null)
      return;

  };
const checkUserRating=()=>{
      if(store?.getState().openMatches.invalidRating){ // Retain this state to ensure we get the updated state after login
         invalidState();
         return false
      }else{
        return true
      }
  }

const invalidState=()=>{

  setRatingDialogDescription(t('player.profile.addRating.toCreateMatch.description'))
  setIsOpenUserRatingDialog(true);
  setRatingSuccessFn({
    successFn:()=>{
      setRatingDialogDescription('');
          getLoggedInPlayerRating(clubId,null,sport,checkUserRating);
  }});

}
  const addPlayerToList = (player, newUserJoining) => {

    const isRatingAvailable = checkUserRating();
    if(!isRatingAvailable) return;
    console.log('isRatingAvailable',isRatingAvailable)

    const validToAdd = checkAvailibity({
      matchGender,
      matchRating,
      playerToAdd: player,
      players,
      enqueueSnackbar,
      t,
      newUserJoining,
    });
    if (!validToAdd)
      return;

    addPlayerToState({
      t,
      setValue,
      index,
      team,
      player: {
        ...player,
        draft: true,
      },
      prevState: players,
      setIsOpen: setAddPlayer,
      enqueueSnackbar,
      newUserJoining,
    });

  };

  const CloseBtn = () => {

    const isRemovingSelf = newJoinee && player?.email == currentUser?.email;

    return (
        <Avatar
            className="closeBtn"
            onClick={() => removePlayerFromState({
              setValue,
              index,
              team,
              player,
              prevState: players,
              isRemovingSelf,
              currentUser,
              matchParticipants: matchDetails?.participants,
            })}
        >
          <Close color="error"/>
        </Avatar>
    );
  };

  useEffect(() => {

    if (matchDetails?.status == 'cancelled') {
      setIsDisabled(true);
      return;
    }

    if (isCreate) {
      if (isSameTeam) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (isRegistered) {

      // logge-in user is invited
      if (currentUser?.addedBy != null) {
        setIsDisabled(true);
        return;
      }
      // logged-in user has paid
      if (currentUser?.paymentStatus == 'paid') {
        setIsDisabled(true);
      } else if (isSameTeam) {
        // logged-in user is unpaid and slot is of same team
        setIsDisabled(false);
      } else {
        // logged-in user is unpaid and slot is of other team
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
    }
  }, [player, currentUser]);

  return (
      <>
        <PlayerCardContainer
            isMobile={isMobile}
            isDisabled={isCreate ?  player?.isAdded || isDisabled : (isRegistered ? (currentUser?.paymentStatus != 'paid') : true ) }
            onClick={handleCardClick}
        >
          <PaymentStatusChip
              isCreate={isCreate}
              player={player}
              players={players}
              currentUser={currentUser}
          />

          {/* remove icon show incase of and if user is new joinee and has added any player
          and it'll show on players that were added by currentLoggedInUser */}

          {showRemoveBtn && !!player?.isAdded && !player?.isBooker && (
              <CloseBtn/>
          )}

          <AvatarIcon
              t={t}
              isCreate={isCreate}
              player={player}
              canAddPartner={canAddPartner}
              btnText={btnText}
              isDisabled={isDisabled}
          />
        </PlayerCardContainer>

        <DialogAddPlayer
            isOpen={addPlayer}
            team={team}
            currentUser={currentUser}
            sport={sport}
            players={players}
            setIsOpen={setAddPlayer}
            setPlayer={addPlayerToList}
        />
         <DialogPlayerProfile
        isOpen={isProfileOpen}
        matchDetails={matchDetails}
        onClose={() => setIsProfileOpen(false)}
        player={selectedPlayer}
        />

      </>
  );

};

const DialogPlayerProfile = ({ isOpen, onClose, player , matchDetails }) => {
  const {t} = useTranslate()
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [searchParams ] = useSearchParams();
  // const openMatchId = searchParams.get('id')
  const [playerData , setPlayerData] = useState()
  const [opendialog , setOpenDialog] = useState(false)
  const [isloading  , setIsLoading] = useState(false)
  const playerId = player?.id;
  const openMatchId = matchDetails?.id

  const {enqueueSnackbar} = useSnackbar()

  function fetchPlayerData (openMatchId , playerId) {
    getOpenMatchProfileData( openMatchId , playerId, cbSuccess, cbFailure )
    function cbSuccess(res){
    // / setPlayerData(res?.data?.data?.);
       setOpenDialog(true)
       setPlayerData(res?.data?.data)
      console.log('@syccess player data ' , res)
    }
    function cbFailure(){
      // enqueueSnackbar('You cant view user profile' , {variant:'error'})

    }

  }
useEffect(() => {
 if(isOpen){
   fetchPlayerData(openMatchId , playerId);
 }
},[isOpen])


  if (!player) return null;
  return (
    <>
    {playerData && opendialog &&



    <DialogBase
    maxWidth={'sm'}
    isOpen={isOpen}
    onClose={() => {
      onClose(onClose);
    }}
    title={t('openMatch.ratingGuide.dialog.profile.header')}
    contentSlot={
     <Stack>


     <ProfileDisplayContainer isMobile={isMobile}>
     {/* <Avatar src={player?.imageUrl} alt={player?.firstName} sx={{ width: 100, height: 100, margin: 'auto' }} /> */}
     <ProfileImageDisplay player={playerData}  isOpenMatch={true} />
    <MiscList player={playerData?.userRatings} isOpenMatch={true} />
    <Stack direction={isMobile?'column' :'row'} justifyContent={'space-between'} alignItems={'start'} flexWrap={'wrap'} mt={-3}>
     <Typography variant="subtitle" fontWeight={600} color={'rgba(0, 0, 0, 0.56)'}>
     {t('openMatch.ratingGuide.dialog.profile.email')}
     </Typography>
     <Typography>
        {player.email}
     </Typography>
     </Stack>

     <Divider sx={{mt:-3}}/>

     <Stack direction={isMobile?'column' :'row'} justifyContent={'space-between'} alignItems={'start'} flexWrap={'wrap'} mt={-3}>
     <Typography variant="subtitle" fontWeight={600} color={'rgba(0, 0, 0, 0.56)'}>
     {t('openMatch.ratingGuide.dialog.profile.phone')}
     </Typography>
     <Typography>
        {playerData?.cellNumber}
     </Typography>
     </Stack>

     <Divider sx={{mt:-3}}/>
     </ProfileDisplayContainer>
     </Stack>
    }
    actionsSlot={
      <Button
          sx={{mr: 'auto'}}
          variant="outlined"
          onClick={() => onClose(false)}
      >
        {t('global.buttons.actions.close')}
      </Button>
    }
/>
}
    {/* <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Player Profile
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 10, top: 10 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Avatar src={player?.imageUrl} alt={player?.firstName} sx={{ width: 100, height: 100, margin: 'auto' }} />
        <Typography variant="h6" align="center">{player?.firstName} {player?.lastName}</Typography>
        <Typography variant="body1" align="center">Email: {player?.email}</Typography>
        <Typography variant="body1" align="center">Rating: {player?.rating}</Typography>
        <Typography variant="body1" align="center">Gender: {player?.gender}</Typography>
      </DialogContent>
    </Dialog> */}
    </>
  );
};

const PaymentStatusChip = ({player, players, isCreate, currentUser}) => {


  const paymentStatus = player?.paymentStatus;

  // const isAssociated = player?.email === currentUser?.email ||
  //     player?.addedBy ===
  //     currentUser?.id;

  const currentUsersPlayer = players?.find(
      (p) => p.email === currentUser?.email);

  const showStatus = (() => {

    if (currentUsersPlayer?.draft) {
      if (player?.draft) return true;
    }else{
      if (currentUsersPlayer) {
        return true;
      }
    }

  })();

  return (
      <Box className="paymentStatus">
        {
            (
                !isCreate &&
                paymentStatus === 'unpaid' &&
                showStatus
            ) &&
            <ChipPlayerPaymentStatus
                chipStyle={'filled'}
                variant={'player-status'}
                size={'small'}
                playerPrice={player.price}
                paymentStatus={paymentStatus}
            />
        }
      </Box>
  );

};

export const AvatarIcon = ({
                             player,
                             isCreate,
                             canAddPartner,
                             btnText,
                             isDisabled,
                             t,
                             teamSize,
                             isMobile,
                           }) => {

  // alignment styles for this card are also in parent where AvatarIcon is being used

  return (
      <AvatarIconContainer
          className="avatarContainer"
          teamSize={teamSize}
          isMobile={isMobile}
          gender={player?.gender}
      >
        {
          player?.isAdded ?
              <AddedPlayerDetail
                  player={player}
                  isMobile={isMobile}
                  t={t}
              />
              :
              <AddPlayerView
                  isMobile={isMobile}
                  isCreate={isCreate}
                  player={player}
                  btnText={btnText}
                  isDisabled={isDisabled}
              />
        }
      </AvatarIconContainer>
  );

};

const AddedPlayerDetail = ({player, t, isMobile}) => {

  const name = isMobile ?
      player?.firstName :
      `${player?.firstName} ${player?.lastName}`;

  function stringAvatar(name) {
    return {
      children: getInitials(name),
    };
  }

  return (
      <Stack
          className={`addedPlayer ${player?.gender}`}
          alignItems={'center'}
          rowGap={1}
      >
        <ProfileImageViewer
            size={isMobile ? 'sm' : 'md'}
            imgPath={player?.imageUrl}
            placeholderStyle={'initials'}
            text={name}
            stringAvatar={stringAvatar}
        />
        <Stack
            className="nameLevel"
            alignItems={'center'}
            rowGap={1}
        >
          <Tooltip
              title={name?.length > 14 ? name : ''}
              placement={'top'}
              arrow
          >
            <ClippedText
                textAlign={'center'}
                isMobile={isMobile}
            >
              {name?.length > 14 ? `${name?.slice(0, 11)}...` : name}
            </ClippedText>
          </Tooltip>
          <PlayerRatingChip
              rating={player?.rating}
          />
        </Stack>
      </Stack>
  );

};

const AddPlayerView = ({player, isDisabled, isMobile, btnText}) => {

  const theme = useTheme();
  return (
      <Stack
          className="addPlayerView"
          alignItems={'center'}
          rowGap={1}
      >
        <AddPlayerIconBtn
            disabled={isDisabled}
            color={!isDisabled ? 'primary' : theme.palette.action.disabled}
            canAddPartner={!isDisabled}
            width={isMobile ? '40px' : null}
            height={isMobile ? '40px' : null}
        >
          {
              !isDisabled &&
              <Add fontSize={isMobile ? 'small' : 'large'}/>
          }
        </AddPlayerIconBtn>
        <Typography
            className="addPlayerBtnText"
            variant={isMobile ? 'body2' : 'body1'}
            fontSize={isMobile ? '13px' : 'initial'}
            color={!isDisabled ? 'primary' : theme.palette.action.disabled}
            textAlign={'center'}
        >
          {btnText}
        </Typography>
      </Stack>
  );
};
export default PlayerCard;
