import { Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { getInitials } from "../../../utils/ui";
import ProfileImageViewer from "../../_Global/Profile/ProfileImageViewer";
import { ClippedText } from "../../../vendor/mui/styled-components/OpenMatches";
import { T, useTranslate } from "@tolgee/react";

const ProfileImageDisplay = (props , isOpenMatch = false) => {
  const { player, loading } = props;

  const { t } = useTranslate();

  const name = `${player?.firstName} ${player?.lastName}`;

  function stringAvatar(name) {
    return {
      children: getInitials(name),
    };
  }

  return (
    <Stack className="profileImageView" alignItems={"center"} rowGap={1}>
      <ProfileImageViewer
        size={"xl"}
        imgPath={player?.imageUrl}
        placeholderStyle={"initials"}
        text={name}
        loading={loading}
        stringAvatar={stringAvatar}
      />
      {
        !!loading &&
        <Skeleton height='50px' width='100%' />
      }
      {!loading && (
        <Stack alignItems={"center"}>
          <Tooltip
            title={name?.length > 14 ? name : ""}
            placement={"top"}
            arrow
          >
            <ClippedText variant="h6-bold" textAlign={"center"}>
              {name}
            </ClippedText>
          </Tooltip>
          { !isOpenMatch && 
          <Typography
            className="publicProfile"
            variant="body1"
            fontStyle={"italic"}
          >
            {`(${
              !!player?.publicProfile
                ? t("myActivity.profile.publicProfile.publicText")
                : t("myActivity.profile.publicProfile.notPublicText")
            })`}

          </Typography>
        }
        </Stack>
      )}
    </Stack>
  );
};

export default ProfileImageDisplay;
