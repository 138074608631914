import {Box, Divider, Grid, Link, Stack, Typography} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {externalUrl} from '../../utils/url';
import { Circle, FiberManualRecord } from '@mui/icons-material';

function ClubInfoNewClubLinks({links , socialMedia}) {
  const {t} = useTranslate();
  console.log('@hassan mehtab' , socialMedia)
  const {facebook, instagram, twitter, website} = socialMedia || {};
  const dotStyle = {
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: 'rgba(168, 168, 168, 1)',
  };
 

  if (links?.length || website || instagram || twitter || facebook) {
    return (
        <Grid item xs={12}>
          <Box>
            <Typography variant={'h6-bold'} mb={2}>
              {t('clubInfo.clubLinks.title')}
            </Typography>
            <Stack
                direction={'row'}
                columnGap={1}
                rowGap={2}
                alignItems={'center'}
                flexWrap={'wrap'}
                divider={<Typography sx={dotStyle}></Typography>}
            >
              {
                links?.map((link, i) => (
      
                    <LinkItem link={link}/>
                    
                ))
              }
                    {
                        website && <LinkItem link={{link:externalUrl(website) , title:'website'}} />
                    }
                    {
                        facebook && <LinkItem link={{link:externalUrl(facebook) , title:'facebook'}} />
                    }
                    {
                        instagram && <LinkItem link={{link:externalUrl(instagram) , title:'instagram'}} />
                    }
                    {
                        twitter && <LinkItem link={{link:externalUrl(website) , title:'twitter'}} />

                    }
            </Stack>
          </Box>
        </Grid>
    );
  }

}

function LinkItem({link}) {
 
  return (
      <Link
          href={externalUrl(link.link)}
          underline="always"
          target={'_blank'}
          textTransform={'capitalize'}
      >
        {link.title}
      </Link>
  );
}

export default ClubInfoNewClubLinks;
