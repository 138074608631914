import { Alert, AlertTitle, Button, IconButton, Link } from "@mui/material"
import { useTranslate } from "@tolgee/react"
import useSocket from "../../hooks/sockets/useSocket";
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";

const WalletAlerts = props => {

  const {canPay, clubId, onSuccess, walletId, players, watchedExtras} = props;
  const {t} = useTranslate();
  const {socket} = useSocket();
  const [walletTopup, setWalletTopup] = useState(false);

    const width = window?.screen?.width ?? 500; // Adjust width as needed
    const height = window?.screen?.height * 0.75 ?? 500;

  useEffect(() => {
    // console.log('socketCall working ');
    socket.on(`wallet${walletId}`, (data) => {
      console.log(`Socket ScheduleClub: data:`, data);

      if (data?.type !== 'wallet_deduction') {
        setWalletTopup(true);
      }
      onSuccess();
    });

    return () => {
      socket.off(`wallet${walletId}`);
    };
  }, [walletId, players, watchedExtras]);

  return (
      <>
        {
            !canPay &&
            <Alert
                severity="warning"
                sx={{alignItems: 'center', marginY: '1rem'}}
            >
              <AlertTitle sx={{margin: 0, fontWeight: '500 !important'}}>{t(
                  'global.wallet.insufficient.alert')}</AlertTitle>
            </Alert>
        }

        {
            walletTopup &&
            <Alert
                severity="success"
                action={
                  <IconButton
                      color={'success'}
                      onClick={() => setWalletTopup(false)}
                  >
                    <Close/>
                  </IconButton>
                }
            >
              <AlertTitle sx={{margin: 0, fontWeight: '500 !important'}}>{t(
                  'wallet.topup.added')}</AlertTitle>
            </Alert>
        }
      </>
  );

};

export default WalletAlerts;
