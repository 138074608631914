function IconAllActivities({fill}) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6045 11.099L19.5887 7.10799L18.9432 6.46242L15.6045 9.80106L13.9205 8.13972L13.2749 8.79208L15.6045 11.099ZM2.25 22.2353V21.0534H15.25V22.2353H2.25ZM16.4351 13.9625C14.9647 13.9625 13.7102 13.4443 12.6716 12.4079C11.6329 11.3716 11.1136 10.1182 11.1136 8.6476C11.1136 7.17722 11.6319 5.92272 12.6683 4.8841C13.7046 3.84548 14.958 3.32617 16.4286 3.32617C17.8989 3.32617 19.1534 3.8444 20.1921 4.88085C21.2307 5.91711 21.75 7.17053 21.75 8.6411C21.75 10.1115 21.2318 11.366 20.1953 12.4046C19.1591 13.4432 17.9056 13.9625 16.4351 13.9625ZM2.25 12.7807V11.5989H8.60464C8.68185 11.8201 8.76743 12.0254 8.86139 12.2149C8.95534 12.4042 9.0622 12.5928 9.18195 12.7807H2.25ZM2.25 17.508V16.3262H13.2001C13.5167 16.4656 13.8477 16.5873 14.1932 16.6911C14.5386 16.7949 14.8909 16.871 15.25 16.9194V17.508H2.25Z"
        fill={fill ?? "#2196F3"}
      />
    </svg>
  );
}
export default IconAllActivities;
