import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import useExperienceContext
  from '../../hooks/clubExperience/useExperienceContext';
import DialogWalletAdd from '../../dialogs/Wallets/DialogWalletAdd';
import {useDispatch} from 'react-redux';
import {customerUpcomingBookings} from '../../vendor/redux/actions/customers';
import moment from 'moment-timezone';
import useUser from '../../hooks/access/useUser';
import DialogPackagePurchase
  from '../../dialogs/Packages/DialogPackagePurchase';
import ClubInfoNew from '../../components/ClubInfoNew/ClubInfoNew';
import useOnboardingContext from '../../hooks/onboarding/useOnboardingContext';

function PageClubLandingPageNew() {

  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const {clubId} = useParams();
  const {clubDetails, clubTimings} = useExperienceContext();
  const [searchParams] = useSearchParams();

  const login = searchParams.get("login");
  const clubTimezone = clubDetails?.addresses[0].timezone;
  const user = useUser();
  const userId = user?.userObj?.id;

  const {setIsOpenLoginDialog} = useOnboardingContext()

  const [bookings, setBookings] = useState([]);
  const [totalBookings, setTotalBookings] = useState(0);
  let domain = process.env.REACT_APP_URL;
  let walletOfferPurchaseRedirectUrl = `${domain}/club/${clubId}/landingPage/offer/payment-status`;

  const getUpcomingBooking = () => {
    const currTime = moment.tz(moment(), clubTimezone).format('HH:mm');
    const cbSuccess = res => {
      console.log(res);
      setBookings(res?.data?.data?.rows);
      setTotalBookings(res?.data?.data?.total);
    };
    const cbFail = res => {
      console.log(res);
    };
    const body = {
      clubId,
      currTime,
      userId,

    };
    dispatch(customerUpcomingBookings(body, cbSuccess, cbFail));
  };

  useEffect(() => {
    if (userId && clubId) {
      getUpcomingBooking();
    }
  }, [clubId, userId]);

  useEffect(() => {
    if (!userId) {
      setBookings([]);
    }
  }, [userId]);

  useEffect(()=>{
    if(!user && login == 'true') {
      setIsOpenLoginDialog(true)
    }
  },[])
  return (
      <>
        <Box>
          <ClubInfoNew
              clubId={clubId}
              clubDetails={clubDetails}
              clubTimings={clubTimings}
              bookings={bookings}
              totalBookings={totalBookings}
              getUpcomingBooking={getUpcomingBooking}
          />
        </Box>
        <Routes>
          <Route
              path={'/wallet-offer/add'}
              element={<DialogWalletAdd
                  open={true}
                  onPurchaseOfferRedirectUrl={walletOfferPurchaseRedirectUrl}
                  onAfterExitAnimation={() => {
                    navigateTo(`/club/${clubId}`);
                  }}
              />}
          />
          <Route
              path="/packages/:pkgId/add"
              element={<DialogPackagePurchase
                  isOpen={true}
                  onAfterExitAnimation={() => {
                    navigateTo(`/club/${clubId}`);
                  }}
              />}
          />
          <Route
              path="/packages/:pkgId/detail"
              element={<DialogPackagePurchase
                  isOpen={true}
                  onAfterExitAnimation={() => {
                    navigateTo(`/club/${clubId}`);
                  }}
              />}
          />
        </Routes>
      </>
  );
}

export default PageClubLandingPageNew;
