import {
  Popover,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import useLocale from '../../../hooks/localization/useLocale';
import moment from 'moment-timezone';
import {
  DateCalendar,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers';
import React, {useMemo, useState} from 'react';
import {filtersEvents} from '../../../utils/analyticsEvents';
import {
  CalendarTodayOutlined,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {APP_DEFAULT_TIMEZONE} from '../../../constants/appDefaults';

export function SlotSearchV2HeaderDateSelect({
                                               minDate = null,
                                               maxDate = null,
                                               dateFormat = 'll',
                                               selectedDate,
                                               handleSelectDate,
                                               isTablet,
                                               clubTimezone = APP_DEFAULT_TIMEZONE,
                                               height = '',
                                             }) {

  const theme = useTheme();

  const {locale} = useLocale();

  const commonProps = {
    timezone: clubTimezone,
    maxDate: maxDate,
    minDate: minDate,
    dateFormat: dateFormat,
    value: moment.tz(selectedDate, clubTimezone),
    disablePast: true,
    onChange: (date) => {
      const dateTz = moment.tz(date, clubTimezone);
      handleSelectDate(dateTz);
    },
  };

  const mobileComponentProps = {
    sx: {
      width: '100%',
      height: '52px',
      fontSize: '139px !important',
      '.MuiInputBase-root.MuiOutlinedInput-root': {
        height: '48px',
        'fieldset': {
          borderColor: theme.palette.divider,
          fontSize: '8px !important',
        },
        borderRadius: '0px',
      },
    },
    slots: {
      field: ({value, sx, onClick}) => {
        return (
            <Stack
                onClick={onClick}
                sx={{
                  ...sx,
                  border: `1px solid rgba(0, 0, 0, 0.23)`,
                  cursor: 'pointer',
                }}
                alignItems="center"
                justifyContent="center"
            >
              <Stack direction="row" spacing={1}>
                <Typography fontSize={'14px'}>
                  {
                    moment.tz(value, clubTimezone)
                        .locale(locale)
                        .format(dateFormat)
                  }
                </Typography>
                <CalendarTodayOutlined fontSize={'small'} color={'action'}/>
              </Stack>

            </Stack>
        );

      },
    },
  };

  return (
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
        <Stack direction={'row'} width={isTablet ? '100%' : null}>
          <PrevNextDay type={'prev'} height={height} {...commonProps} />
          {
              !isTablet &&
              <DesktopDatePicker
                  {...commonProps}
              />
          }
          {
              isTablet &&
              <MobileDatePicker
                  {...commonProps}
                  {...mobileComponentProps}
                  orientation={'vertical'}
              />
          }
          <PrevNextDay type={'next'} height={height} {...commonProps} />
        </Stack>
      </LocalizationProvider>
  );

}

function PrevNextDay({
                       type = 'next',
                       timezone,
                       maxDate,
                       minDate,
                       value,
                       onChange,
                       height,
                     }) {

  const isDisabled = useMemo(() => {
    if (type === 'prev') {
      return moment(value).isSame(moment(), 'day');
    }
    if (type === 'next') {
      return moment(value).isSame(maxDate, 'day');
    }
  }, [minDate, maxDate, value]);

  function handleClick() {

    let newDate;

    if (type === 'prev') {
      newDate = moment.tz(value, timezone).subtract(1, 'days');
    }
    if (type === 'next') {
      newDate = moment.tz(value, timezone).add(1, 'days');
    }

    onChange(newDate);

  }

  return <ToggleButton
      value={''}
      onClick={handleClick}
      disabled={isDisabled}
      sx={{
        height,
        border: `1px solid ${'rgba(0, 0, 0, 0.23)'}`,
        borderRadius: '0px !important',
        '&.Mui-disabled': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        ...(type === 'prev') && {
          borderRight: 'none !important',
          borderTopLeftRadius: '50px !important',
          borderBottomLeftRadius: '50px !important',
        },
        ...(type === 'next') && {
          borderLeft: 'none !important',
          borderTopRightRadius: '50px !important',
          borderBottomRightRadius: '50px !important',
        },
      }}
  >
    {
        type === 'prev' &&
        <ChevronLeft/>
    }
    {
        type === 'next' &&
        <ChevronRight/>
    }
  </ToggleButton>;

}

function DesktopDatePicker({
                             value,
                             maxDate,
                             minDate,
                             disablePast,
                             timezone,
                             dateFormat,
                             onChange,
                           }) {

  const theme = useTheme();
  const {locale} = useLocale();
  moment.locale(locale);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const IS_OPEN_POPOVER = Boolean(popoverAnchorEl);

  let textFieldValue = moment.tz(value, timezone)
      .locale(locale)
      .format(dateFormat);

  function handleOpenPopover(event) {
    setPopoverAnchorEl(event.currentTarget);
  }

  function handleClosePopOver() {
    setPopoverAnchorEl(null);
  }

  function handleChangeDate(date) {
    onChange(date);
    handleClosePopOver();
    filtersEvents['dateChange']();
  }

  return (
      <>
        <Stack
            columnGap={.5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              px: 1.2,
              border: `1px solid ${!IS_OPEN_POPOVER ?
                  'rgba(0, 0, 0, 0.23)' :
                  theme.palette.primary.main}`,
              cursor: 'pointer',
            }}
            onClick={handleOpenPopover}
        >
          <Typography minWidth={'103px'} fontSize={'14px'}>
            {textFieldValue}
          </Typography>
          <CalendarTodayOutlined fontSize={'small'} color={'action'} sx={{
            position: 'relative',
            top: -1,
          }}/>
        </Stack>
        <Popover
            open={IS_OPEN_POPOVER}
            anchorEl={popoverAnchorEl}
            onClose={handleClosePopOver}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
        >
          <DateCalendar
              disablePast={disablePast}
              onChange={handleChangeDate}
              maxDate={maxDate}
              minDate={minDate}
              value={value}
          />
        </Popover>
      </>
  );
}

export default SlotSearchV2HeaderDateSelect;
