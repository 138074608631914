import {
  alpha,
  Box,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useSlotSearchV2Context} from '../ProviderSlotSearchV2';
import useLocale from '../../../hooks/localization/useLocale';
import {bookingEvents} from '../../../utils/analyticsEvents';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {useTranslate} from '@tolgee/react';
import Empty from '../../_Global/Empty/Empty';
import SlotSearchV2CourtSelectDialog
  from './CourtSelectDialog/SlotSearchV2CourtSelectDialog';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import DialogBookingsAdd from '../../../dialogs/Booking/DialogBookingsAdd';
import DialogBookingsAddV2 from '../../../dialogs/Booking/DialogBookingsAddV2';
import useOnboardingContext
  from '../../../hooks/onboarding/useOnboardingContext';
import useUser from '../../../hooks/access/useUser';
import IconLocationDrop from '../../../assets/icons/IconLocationDrop';

import bgDubai from '../../../assets/cities/bgDubai.jpg';
import bgSharjah from '../../../assets/cities/bgSharjah.jpg';
import bgAbuDhabi from '../../../assets/cities/bgAbuDhabi.jpg';
import bgKhorFakkan from '../../../assets/cities/bgKhorFakkan.jpg';
import useIsMobile from '../../../hooks/ui/useIsMobile';

function SlotSearchV2Slots({experience}) {

  const theme = useTheme();
  const isMobile = useIsMobile();
  const isCompanyView = experience === 'COMPANY';
  const {clubId, companyId} = useParams();

  const {
    slots,
    isLoadingSlots,
    setWillNavigateToCreateBooking,
    setSelectedCourt,
    setSelectedSlot,
    setIsOpenCourtSelect,
    willNavigateToCreateBooking,
    view
  } = useSlotSearchV2Context();

  const showOldBookingView = view === 'openMatch' || isCompanyView;

  const BookingAddComponent = (showOldBookingView? DialogBookingsAdd: DialogBookingsAddV2);
  const addBookingRoute = isCompanyView? `/company/${companyId}` : `/club/${clubId}`

  function handleAfterExitAddBookingDialog() {
    setWillNavigateToCreateBooking(false);
    setSelectedCourt({});
    setSelectedSlot({});
  }

  useEffect(() => {
    if (willNavigateToCreateBooking) {
      setIsOpenCourtSelect(false);
    }
  }, [willNavigateToCreateBooking]);

  const ClubBox = ({
                     clubId,
                     clubName,
                     street,
                     city,
                     postalCode,
                     imageUrl,
                     children,
                   }) => {


    let bg;

    switch (clubId) {
      case '216847':
        bg = bgAbuDhabi;
        break;
      case '216846':
        bg = bgDubai;
        break;
      case '216849':
        bg = bgKhorFakkan;
        break;
      case '216848':
        bg = bgSharjah;
        break;
      case '216850':
        bg = bgDubai;
        break;
      default:
        bg = null;
    }

    return (
        <Box
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '12px',
              backgroundColor: alpha('#fff', .3),
            }}
        >
          <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent={isMobile ? '' : 'space-between'}
              alignItems={isMobile ? '' : 'center'}
              spacing={1}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                height: '175px',
                py: 2,
                px: 3,
                borderTopRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderLeft: `8px solid ${theme.palette.primary.main}`,
                backgroundColor: 'rgba(255,255,255,1)',
              }}
          >

            {
                // bg &&
                <Box
                    component={bg ? 'img' : 'div'}
                    src={bg}
                    sx={{
                      position: 'absolute',
                      zIndex: 0,
                      right: 0,
                      width: isMobile ? '50%' : '80%',
                      height: '100%',
                      top: 0,
                      bottom: 0,
                      maskImage: 'linear-gradient(to right, transparent 20%, black 80%)',
                      objectFit: 'cover',
                      ...(!bg && {
                        backgroundColor: theme.palette.primary.main,
                      })
                    }}
                />
            }

            <Stack
                onClick={() =>
                    window.open(
                        `/club/${clubId}`,
                        '_blank',
                        'noopener,noreferrer',
                    )
                }
                sx={{cursor: 'pointer', position: 'relative', zIndex: 2}}
                direction={'row'}
                alignItems={'center'}
                spacing={1}
            >
              {/*<ProfileImageViewer*/}
              {/*    size={'sm'}*/}
              {/*    imgPath={imageUrl}*/}
              {/*    placeholderStyle={'shield-icon'}*/}
              {/*/>*/}
              <Typography variant="h6-bold" sx={{
                fontSize: '30px',
                fontWeight: '800',
                textTransform: 'uppercase',
              }}>
                {clubName}
              </Typography>
            </Stack>

            <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                alignSelf={'flex-start'}
                sx={{
                  position: 'relative',
                  zIndex: 1,
                  backgroundColor: alpha(theme.palette.grey[100],.85),
                  px: 2,
                  py: 1,
                  borderRadius: '32px',
                  border: `1px solid ${theme.palette.divider}`,
                  // whiteSpace: 'nowrap',
                }}
            >

              <IconLocationDrop/>
              <Typography lineHeight={'1'}>
                {`${postalCode ? postalCode + ', ' : ''}${city || ''}`}
              </Typography>
            </Stack>


          </Stack>
          {children}
        </Box>
    );
  };

  return (
      <>
        {
            !isLoadingSlots && slots?.length === 0 &&
            <EmptyState/>
        }
        {
            isLoadingSlots && !willNavigateToCreateBooking &&
            <LoadingState/>
        }
        {
          !isLoadingSlots && slots?.length > 0 &&
            <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  pt: 1,
                }}
            >
              {
                isCompanyView ?
                    <Stack
                        width={'100%'}
                        spacing={5}
                    >
                      {
                        slots?.map((clubData,index) => {

                          return (
                              <ClubBox
                                  key={index}
                                  clubId={clubData?.clubId}
                                  clubName={clubData?.clubName}
                                  imageUrl={clubData?.imageUrl}
                                  street={clubData?.street}
                                  postalCode={clubData?.postalCode}
                                  city={clubData?.city}
                              >
                                <Stack
                                    direction={'row'}
                                    flexWrap={'wrap'}
                                    sx={{
                                      p: 2,
                                    }}
                                >
                                  {
                                    clubData?.slots?.slots?.map((slot, index) => (
                                        <SlotsItem
                                            key={index}
                                            slot={slot}
                                            showOldBookingView={showOldBookingView}
                                        />
                                    ))
                                  }
                                </Stack>
                              </ClubBox>
                          );
                        })
                      }
                    </Stack>
                    :
                    slots?.map((slot, index) => {
                      return <SlotsItem
                          key={index}
                          slot={slot}
                          isCompanyView={isCompanyView}
                          showOldBookingView={showOldBookingView}
                      />;
                    })
              }
            </Box>
        }

        {showOldBookingView && <SlotSearchV2CourtSelectDialog/>}
        <Routes>
          <Route
              path={'add-booking'}
              element={<BookingAddComponent
                  onExit={handleAfterExitAddBookingDialog}
                  open={true}
                  variant={'add'}
                  root={addBookingRoute}
                  fetchAndSetBookings={() => {
                  }}
              />}
          />

        </Routes>
      </>
  );
}

export default SlotSearchV2Slots;

function SlotsItem({slot, showOldBookingView}) {

  const navigateTo = useNavigate();
  const {
    openLoginDialogWithAction,
  } = useOnboardingContext();
  const USER = useUser();
  const theme = useTheme();
  const {locale} = useLocale();
  const {
    selectedSlot,
    setSelectedSlot,
    setIsOpenCourtSelect,
    isTablet,
  } = useSlotSearchV2Context();
  const isActive = selectedSlot?.slot === slot?.slot;

  const activeStyles = {
    backgroundColor: alpha(theme.palette.primary.main, .1),
    color: theme.palette.primary.dark,
  };

  return (

      <Box
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            padding: '9px 20px',
            width: !isTablet ? 'calc(25% - 16px)' : 'calc(33.33% - 16px)',
            margin: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '6px',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            transition: 'background-color .1s ease-in-out',
            ...(isActive) && activeStyles,
            '&:hover': activeStyles,
          }}
          onClick={() => {
            const handleSlotSelection = () => {
              const updatedSlot = {
                ...slot,
                slots: slot?.slots?.map(x => ({...x, allowCourtUpdate: true})),
              };

              const proceedWithBooking = () => {
                setSelectedSlot(updatedSlot);
                showOldBookingView ?
                    setIsOpenCourtSelect(true) :
                    navigateTo('add-booking');
                bookingEvents['clickOnTimeslot']();
              };

              return USER ?
                  proceedWithBooking() :
                  openLoginDialogWithAction(proceedWithBooking);
            };

            handleSlotSelection();
          }
          }
      >
        {dayjs(slot.slot, 'HH:mm').locale(locale).format('LT')}
      </Box>

  );
}

function LoadingState() {

  const {isTablet} = useSlotSearchV2Context();

  return (
      <Box
          sx={{
            position: 'relative',
            // padding: '0 23px',
          }}
      >
        <LinearProgress
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
            }}
        />
        <Grid container spacing={2} mt={1} pb={4}>
          {
            Array(12).fill(0).map((el, index) => {
              return (
                  <Grid key={index} item xs={!isTablet ? 3 : 4}>
                    <Skeleton
                        width={'100%'}
                        height={'39px'}
                        sx={{
                          transform: 'scale(1,1)',
                        }}
                    />
                  </Grid>
              );
            })
          }
        </Grid>
      </Box>
  );
}

function EmptyState() {

  const {t} = useTranslate();

  return (
      <Box
          sx={{
            width: '100%',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 8,
          }}
      >
        <Empty
            imageStyle={'simple'}
            description={
              <Stack textAlign={'center'} maxWidth={'400px'}>
                <Typography variant={'body1'}>
                  {t('schedule.mobile.availableSlots.noSlots.title')}
                </Typography>
                <Typography variant={'body2'}>
                  {t('schedule.mobile.availableSlots.noSlots.description')}
                </Typography>
              </Stack>
            }
        />
      </Box>
  );
}
