import '@fontsource/public-sans/300.css';
import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/700.css';
import '@fontsource/public-sans/900.css';
import './sass/palla.scss';
import 'simplebar-react/dist/simplebar.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider as ProviderRedux} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {persistor, store} from './vendor/redux/configure-store';
import CssBaseline from '@mui/material/CssBaseline';
import ProviderNotistack from './providers/ProviderNotistack';
import {BrowserRouter} from 'react-router-dom';
import ProviderMUILocalization from './providers/ProviderMUILocalization';
import ProviderSentry from './providers/ProviderSentry';
import ProviderTolgee from './providers/ProviderTolgee';
import setupAxios from './vendor/axios/setupAxios';
import ProviderLocale from './providers/ProviderLocale';
import setupDayjs from './vendor/dayjs/setupDayJS';
import {ProviderSocketIO} from './providers/ProviderSocketIO';
import setupMUIPro from './vendor/mui/setupMUIPro';
import ProviderTheme from './providers/ProviderTheme';
import ProviderOnboarding from './providers/ProviderOnboarding';
import {init} from './init/init';
import ProviderLayout from './providers/ProviderLayout';

init();
setupAxios();
setupDayjs();
setupMUIPro();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ProviderSentry>
      <ProviderRedux store={store}>
        <PersistGate persistor={persistor}>
          <ProviderLocale>
            <ProviderTolgee>
              <ProviderTheme>
                <ProviderMUILocalization>
                  <ProviderNotistack>
                    <ProviderSocketIO>
                      <CssBaseline/>
                      <BrowserRouter>
                        <ProviderOnboarding>
                          <ProviderLayout>
                            <App/>
                          </ProviderLayout>
                        </ProviderOnboarding>
                      </BrowserRouter>
                    </ProviderSocketIO>
                  </ProviderNotistack>
                </ProviderMUILocalization>
              </ProviderTheme>
            </ProviderTolgee>
          </ProviderLocale>
        </PersistGate>
      </ProviderRedux>
    </ProviderSentry>,
);

