import {Alert, Box, Divider, Grid, Stack} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ClubInfoNewTiming from './ClubInfoNewTiming';
import ClubInfoNewAmenities from './ClubInfoNewAmenities';
import ClubInfoNewAbout from './ClubInfoNewAbout';
import ClubInfoNewEvents from './ClubInfoNewEvents';
import ClubInfoNewOffers from './ClubInfoNewOffers';
import usePolicies from '../../hooks/policies/usePolicies';
import ClubInfoNewClubLinks from './ClubInfoNewClubLinks';
import PackageSlider from '../Packages/Slider/PackageSlider';
import ClubInfoOpenMatchesList from '../OpenMatches/ClubInfoOpenMatchesList';
import PageCard from '../_Global/Page/PageCard';
import UpcomingBookingsList from '../Bookings/UpcomingBookingsList';
import useUser from '../../hooks/access/useUser';
import SlotSearchV2 from '../SlotSearchV2/SlotSearchV2';
import ClubInfoNewTrainings from './ClubInfoNewTrainings';
import useBreakpoint from '../../hooks/ui/useBreakpoint';
import useIsMobile from '../../hooks/ui/useIsMobile';
import ClubInfoNewTournament from './ClubInfoNewTournament';
import useHasAccess from '../../hooks/access/useHasAccess';
import {useTranslate} from '@tolgee/react';
import {MODULE_OPEN_MATCHES} from '../../utils/access';
import moment from 'moment-timezone';
import {APP_DEFAULT_TIMEZONE} from '../../constants/appDefaults';
import ClubInfoOpenMatchesComingSoon
  from '../OpenMatches/ClubInfoOpenMatchesComingSoon';
import {customersPackagesList_GET} from '../../vendor/redux/actions/packages';
import {useDispatch} from 'react-redux';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import {clubOffers_GET} from '../../vendor/redux/actions/customers';

function ClubInfoNew({
                       clubId,
                       clubDetails,
                       clubTimings,
                       type,
                       bookings,
                       totalBookings,
                       getUpcomingBooking,
                     }) {

  const {t} = useTranslate();
  const user = useUser();
  const userId = user?.userObj?.id;
  const isMobile = useIsMobile();
  const policies = usePolicies();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  const clubWalletsEnabled = policies?.wallet;
  const clubTimezone = clubDetails?.addresses[0].timezone ??
      APP_DEFAULT_TIMEZONE;

  const canAccessTournament = useHasAccess('tournaments');
  const canAccessTrainings = useHasAccess('trainings');
  const canAccessOpenMatch = useHasAccess('open_matches');
  const {isMdDown} = useBreakpoint();

  const [clubPackages, setClubPackages] = useState();
  const [offers, setOffers] = useState();

  const OPEN_MATCHES_ACCESS = useHasAccess(MODULE_OPEN_MATCHES);

  const slotSearchMinDate = (() => {
    if (clubId === '216868') {
      return moment.tz('2025-03-10', clubTimezone);
    }
    if (clubId === '216867') {
      return moment.tz('2025-03-25', clubTimezone);
    }
  })();

  const launchNotice = (() => {

    const today = moment.tz(clubTimezone).startOf('day');

    if (clubId === '216868') {
      const comparisonDate = moment.tz('2025-03-10', clubTimezone)
          .startOf('day');
      if (today.isBefore(comparisonDate)) {
        return t('notices.padelNext.bookingsStartMessage');
      }
    }

    if (clubId === '216867') {
      const comparisonDate = moment.tz('2025-03-25', clubTimezone)
          .startOf('day');
      if (today.isBefore(comparisonDate)) {
        return t('notices.hal22.bookingsStartMessage');
      }
    }

  })();

  function fetchClubPackages() {

    // setIsLoadingClubPackages(true);
    dispatch(customersPackagesList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersPackagesList_GET Success', res);
      setClubPackages(res?.data?.data?.rows || []);
      // setIsLoadingClubPackages(false);
    }

    function cbFail(e) {
      console.log('customersPackagesList_GET Fail', e);
      setClubPackages([]);
      // setIsLoadingClubPackages(false);
    }
  }

  function fetchOffers() {

    // setIsLoading(true);
    dispatch(clubOffers_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubOffers_GET Success', res);
      setOffers(res.data.data.rows || []);
      // setIsLoading(false);
    }

    function cbFail(e) {
      console.log('clubOffers_GET Fail', e);
      // setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchOffers();
  }, []);

  useEffect(() => {
    fetchClubPackages();
  }, [isAuthenticated]);

  return (
      <Box
          sx={{
            display: 'flex',
            columnGap: '24px',
            rowGap: '24px',
            flexWrap: 'wrap',

            '.item': {
              flex: 1,
              minWidth: 'calc(50% - 12px)',
              ...(isMdDown && {
                minWidth: '100%',
              }),

              '&.full-width': {
                minWidth: '100%',
              },

            },
          }}
      >

        {
            userId && bookings?.length > 0 &&
            <Box className={'item full-width'}>
              <PageCard
                  isBlurVariant={true}
                  sx={{
                    mb: 3,
                  }}
              >
                <UpcomingBookingsList
                    bookings={bookings}
                    totalBookings={totalBookings}
                    clubId={clubId}
                    getUpcomingBooking={getUpcomingBooking}/>
              </PageCard>
            </Box>
        }

        <SlotSearchV2
            // defaultDate={slotSearchMinDate}
            defaultMinDate={slotSearchMinDate}
            alertSlot={
                launchNotice &&
                <Alert severity={'info'}>
                  {launchNotice}
                </Alert>
            }
        />
        {
            canAccessOpenMatch &&
            <ClubInfoOpenMatchesList alwaysMobileView={true}/>
        }
        {
            !canAccessOpenMatch &&
            <ClubInfoOpenMatchesComingSoon/>
        }

        <ClubInfoNewEvents clubId={clubId} type={type} policies={policies}/>
        <ClubInfoNewTrainings/>

        {
            canAccessTournament && <ClubInfoNewTournament/>
        }
        {(clubPackages?.length > 0 || offers?.length > 0) &&
            <Box className={'item full-width'}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={clubWalletsEnabled ? 6 : 12}>
                  <PageCard
                      isBlurVariant={true}
                  >
                    <PackageSlider/>
                  </PageCard>
                </Grid>
                {
                    clubWalletsEnabled &&
                    <Grid item xs={12} md={6}>
                      <PageCard
                          isBlurVariant={true}
                      >
                        <ClubInfoNewOffers
                            clubId={clubId}
                            clubDetails={clubDetails}
                            type={type}
                        />
                      </PageCard>
                    </Grid>
                }
              </Grid>

            </Box>
        }

        <Box className={'item full-width'}>
          <PageCard
              isBlurVariant={true}
          >
            <Grid container spacing={isMobile ? 0 : 4}>
              <Grid item xs={12} md={8.5}>
                <Stack spacing={3}>

                  <ClubInfoNewAbout clubDetails={clubDetails}/>
                  <ClubInfoNewClubLinks links={clubDetails?.links}
                                        socialMedia={clubDetails}/>
                  <ClubInfoNewAmenities clubId={clubId} direction={'row'}
                                        isIcon={true}/>
                </Stack>
              </Grid>
              {!isMobile &&
                  <Divider orientation="vertical" flexItem sx={{mt: 4}}/>}
              <Grid item xs={12} md={2.9}>
                <Stack spacing={0} mt={isMobile ? 5 : 0}
                       alignItems={isMobile ? 'start' : 'end'}>
                  <ClubInfoNewTiming clubTimings={clubTimings}/>
                  {/* <ClubInfoNewLocation clubId={clubId} height={'254px'}/> */}
                </Stack>
              </Grid>
            </Grid>
          </PageCard>
        </Box>

      </Box>
  );

}

export default ClubInfoNew;


