import {Button} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useTranslate} from '@tolgee/react';
import {useDispatch} from 'react-redux';
import ChipBookingStatus
  from '../../components/_Global/Chips/ChipBookingStatus';
import {useSnackbar} from 'notistack';
import DialogConfirmation
  from '../../components/_Global/Dialogs/DialogConfirmation';
import useCanDoActions from '../../hooks/bookings/useCanDoActions';
import useBookingsRecurringSingleDetails
  from '../../hooks/bookings/useBookingsRecurringSingleDetails';
import BookingsRecurringSingleDetails
  from '../../components/Bookings/Details/BookingsRecurringSingleDetails';
import useBookingRecurring from '../../hooks/bookings/useBookingRecurring';
import {
  customersBookingsCancel_POST,
} from '../../vendor/redux/actions/bookings';
import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import useBreakpoint from '../../hooks/ui/useBreakpoint';
import {getOrdinal} from '../../utils/localization';
import useLocale from '../../hooks/localization/useLocale';

function DialogBookingsRecurringSingleDetail({
                                               navigateAfterExited = true,
                                               root,
                                               type = 'default' ||
                                               'customer-profile' ||
                                               'player-available-slots' ||
                                               'player-bookings-table',
                                               isOpen,
                                               onCancel = () => {
                                               },
                                               onClose = () => {
                                               },
                                               data,
                                             }) {

  const {state: locationState} = useLocation();
  const {isSmDown} = useBreakpoint();

  let bookingSingleId;
  let recurringBookingId;

  if (!data) {
    bookingSingleId = locationState?.bookingId;
    recurringBookingId = locationState?.recurrBookingId;
  } else {
    bookingSingleId = data.bookingId;
    recurringBookingId = data.recurrBookingId;
  }

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const {locale} = useLocale();

  const [isDialogOpen, setIsDialogOpen] = useState(isOpen);

  const [recurringBooking, fetchRecurringBooking] = useBookingRecurring(
      recurringBookingId,bookingSingleId);

  const [singleDetails] = useBookingsRecurringSingleDetails(recurringBooking,
      recurringBookingId, bookingSingleId);

  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const [isSubmittingCancelBooking, setIsSubmittingCancelBooking] = useState(
      false);

  const [canDoCancel] = useCanDoActions(
      singleDetails?.clubId,
      singleDetails?.bookingStatus,
      singleDetails?.bookingDate,
      singleDetails?.bookingStartTime,
      'Europe/Amsterdam', //todo update with value from api
      singleDetails?.players,
      singleDetails?.paymentType,
  );

  const isLoading = !singleDetails ;

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleCancelBooking() {

    setIsSubmittingCancelBooking(true);

    dispatch(customersBookingsCancel_POST(bookingSingleId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('bookingsCancel_POST Success', res);
      setIsSubmittingCancelBooking(false);
      fetchRecurringBooking();
      onCancel();
      setShowCancelDialog(false);
      enqueueSnackbar(t('bookings.recurring.single.detail.cancel.success'),
          {variant: 'success'});
    }

    function cbFail(e) {
      console.log('bookingsCancel_POST Fail', e);
      setIsSubmittingCancelBooking(false);
      const error = e.response.data.data;
      enqueueSnackbar(error, {variant: 'error'});
    }
  }

  function onDialogAfterExited() {
    if (navigateAfterExited) {
      navigateTo(root, {state: {...locationState}});
    } else {
      onClose();
    }
  }

  return (
      <>
        <DialogBase
            isOpen={isDialogOpen}
            isLoading={isLoading}
            onClose={handleCloseDialog}
            onExited={onDialogAfterExited}
            title={t('bookingAdd.detailHeading')}
            description={`ID ${bookingSingleId}`}
            titleLabelSlot={<ChipBookingStatus
                size={isSmDown ? 'small' : 'medium'}
                variant={'booking-status'}
                bookingStatus={singleDetails?.bookingStatus}
                bookingType={'recurrence'}
                bookingPrice={singleDetails?.price}
            />}
            contentSlot={
              <BookingsRecurringSingleDetails
                  isLoading={isLoading}
                  type={type}
                  details={singleDetails}
              />}
            actionsSlot={
              <>
                {
                    !isLoading &&
                    <Button
                        sx={{mr: 'auto'}}
                        variant={'outlined'}
                        color='inherit'
                        size={'large'}
                        onClick={handleCloseDialog}
                        disabled={isLoading}
                    >
                      {t('global.buttons.actions.close')}
                    </Button>
                }
                {
                    !isLoading && canDoCancel === true &&
                    <Button
                        variant={'outlined'}
                        color={'error'}
                        size={'large'}
                        sx={{ml: 'auto'}}
                        onClick={() => {
                          setShowCancelDialog(true);
                        }}
                    >
                      {t('global.buttons.actions.cancel')}
                    </Button>
                }
              </>}
        />
        {
            singleDetails &&
            <DialogConfirmation
                variant={'error'}
                isSubmitting={isSubmittingCancelBooking}
                isOpen={showCancelDialog}
                dialogTitle={t(
                    'bookings.recurring.single.detail.confirmCancel.title')}
                dialogContent={
                    t('bookings.recurring.single.detail.confirmCancel.p1') +
                    ` ${singleDetails?.bookingNumber ?
                        getOrdinal(singleDetails?.bookingNumber, locale) :
                        ''} ` +
                    t('bookings.recurring.single.detail.confirmCancel.p2') +
                    ` ${singleDetails.totalBookings} ` +
                    t('bookings.recurring.single.detail.confirmCancel.p3') +
                    ` "${singleDetails.title || 'No title'}"`
                }
                handleConfirm={handleCancelBooking}
                confirmButtonLabel={t(
                    'bookings.recurring.single.detail.confirmCancel.button')}
                handleClose={() => {
                  if (!isSubmittingCancelBooking) {
                    setShowCancelDialog(false);
                  }
                }}
            />}
      </>);
}

export default DialogBookingsRecurringSingleDetail;
