import {Divider, Stack} from '@mui/material';
import {
  SlotSearchV2CourtSelectDialogCourtsListItem,
} from './SlotSearchV2CourtSelectDialogCourtsListItem';
import EnableAlternateCourtInput
  from '../../../OpenMatches/EnableAlternateCourtInput';
import {useSlotSearchV2Context} from '../../ProviderSlotSearchV2';

export function SlotSearchV2CourtSelectDialogCourtsList({courts}) {

  const {view} = useSlotSearchV2Context();

  const isOpenMatchView = view === 'openMatch';

  const sortedCourts = Array.isArray(courts) ? [...courts].sort((a, b) => {
    if (a.discountApplied === b.discountApplied) {
      return a.courtName.localeCompare(b.courtName);
    }
    return a.discountApplied ? -1 : 1;
  }) : [];

  return (
      <Stack
          position={'relative'}
          divider={<Divider/>}
      >
        {
          sortedCourts?.map((court, index) => {
            return <SlotSearchV2CourtSelectDialogCourtsListItem
                key={index}
                court={court}
                isLast={index === sortedCourts?.length - 1}
            />;
          })
        }
        {/* {
            isOpenMatchView &&
            <EnableAlternateCourtInput/>
        } */}
      </Stack>
  );
}
